<template>
  <div class="date-picker">
    <date-picker
      type="datetime"
      :confirm="true"
      :clearable="false"
      :editable="false"
      :confirm-text="confirmText"
      :placeholder="placeholder"
      :value="value"
      :disabled-date="disableBeforeToday"
      @change="handleChange"
      @open="handleOpen"
      @close="handleClose"
      @blur="handleBlur"
    />
  </div>
</template>

<script>
import addDays from 'date-fns/addDays';
import DatePicker from 'vue2-datepicker';
export default {
  components: { DatePicker },
  data() {
    return {
      openStatus: false,
    };
  },
  props: {
    confirmText: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: Date,
      default: [],
    },
  },

  methods: {
    handleChange(value) {
      this.$emit('change', value);
    },
    disableBeforeToday(date) {
      const yesterdayDate = addDays(new Date(), -1);
      return date < yesterdayDate;
    },
    handleOpen(evt) {
      this.openStatus = true;
      this.$emit('open', this.openStatus);
        this.$nextTick(() => {
          const calendar = document.querySelector('.mx-datepicker-main.mx-datepicker-popup');
          const parentElementFirst = document.getElementById('form-date');
          const parentElementSecond = document.querySelector('.column.content-box');
          if (calendar && parentElementFirst) {
            if (calendar.parentElement !== parentElementFirst) {
              // If it's not already in the DOM, insert it
              parentElementFirst.insertBefore(calendar, parentElementFirst.firstChild);
              calendar.style.position = 'fixed';
            }
          }
          else if (calendar && parentElementSecond) {
            if (calendar.parentElement !== parentElementSecond) {
              // If it's not already in the DOM, insert it
              parentElementSecond.insertBefore(calendar, parentElementSecond.firstChild);
              calendar.style.position = 'fixed';
            }
          }
        });
    },
    handleClose() {
      this.openStatus = false;
      this.$emit('close', this.openStatus);
    },
    handleBlur(event) {
      const datePicker = this.$refs.datePicker;
      const calendar = this.$refs.calendar;
      // Check if the clicked element is inside the date-picker but not in the calendar
      if (datePicker && !datePicker.contains(event.target) && !(calendar && calendar.contains(event.target))) {
        this.openStatus = false;
        this.$emit('close', this.openStatus);
      }
    },
  },
};
</script>
<style lang="scss">

.mx-datepicker-popup {
  z-index: 99999;
  position: fixed !important;
  /*top: 216px !important;
  left: 388px !important;*/
}

</style>
