import { FEATURE_FLAGS } from '../../../../featureFlags';
import { frontendURL } from '../../../../helper/URLHelper';
import store from '../../../../store/index'; /* to have access to our Vuex data store */

const primaryMenuItems = accountId => [
    {
        icon: 'arrow-trending-lines',
        key: 'dashboard',
        label: 'DASHBOARD',
        toState: frontendURL(`accounts/${accountId}/reports/overview`),
        toStateName: 'home',
        roles: ['administrator'],
    },
  {
    icon: 'chat',
    key: 'conversations',
    label: 'CONVERSATIONS',
    toState: frontendURL(`accounts/${accountId}/dashboard`),
    toStateName: 'home',
    roles: ['administrator', 'agent'],
  },
  {
    icon: 'book-contacts',
    key: 'contacts',
    label: 'CONTACTS',
    featureFlag: FEATURE_FLAGS.CRM,
    toState: frontendURL(`accounts/${accountId}/contacts`),
    toStateName: 'contacts_dashboard',
    roles: ['administrator', 'agent'],
  },
  {
    icon: 'reports-nav',
    key: 'reports',
    label: 'REPORTS',
    featureFlag: FEATURE_FLAGS.REPORTS,
    toState: frontendURL(`accounts/${accountId}/reports/conversation`),
    toStateName: 'settings_account_reports',
    roles: ['administrator'],
  },
  {
    icon: 'megaphone',
    key: 'campaigns',
    label: 'CAMPAIGNS',
    featureFlag: FEATURE_FLAGS.CAMPAIGNS,
    toState: frontendURL(`accounts/${accountId}/campaigns`),
    toStateName: 'settings_account_campaigns',
    roles: ['administrator'],
  },
/*  {
    icon: 'library',
    key: 'helpcenter',
    label: 'HELP_CENTER.TITLE',
    featureFlag: 'help_center',
    toState: frontendURL(`accounts/${accountId}/portals`),
    toStateName: 'list_all_portals',
    roles: ['administrator'],
  },*/
  {
    icon: 'settings',
    key: 'settings',
    label: 'SETTINGS',
    toState: frontendURL(`accounts/${accountId}/settings/general`), /* to display account settings list at first */
    toStateName: 'settings_home',
    roles: ['administrator'],
  },
  {
    icon: 'settings',
    key: 'settings',
    label: 'SETTINGS',
    toState: (store.getters["accounts/isFeatureEnabledonAccount"](accountId, FEATURE_FLAGS.MACROS) === true)
              ? frontendURL(`accounts/${accountId}/settings/macros`)
              : frontendURL(`accounts/${accountId}/settings/canned-response/list`),
    toStateName: 'settings_home',
    roles: ['agent'],
    show: (store.getters["accounts/isFeatureEnabledonAccount"](accountId, FEATURE_FLAGS.MACROS) === true)
          ? true
          : (store.getters["accounts/isFeatureEnabledonAccount"](accountId, FEATURE_FLAGS.CANNED_RESPONSES) === true),
  },
];

export default primaryMenuItems;
