<template>
  <tr>
    <td>{{ macro.name }}</td>
    <td>
      <div class="avatar-container">
        <thumbnail :username="macro.created_by.name" size="24px" />
        <span>{{ macro.created_by.name }}</span>
      </div>
    </td>
    <td>
      <div class="avatar-container">
        <thumbnail :username="macro.updated_by.name" size="24px" />
        <span>{{ macro.updated_by.name }}</span>
      </div>
    </td>
    <td>{{ visibilityLabel }}</td>
    <td class="button-wrapper">
      <router-link :to="addAccountScoping(`settings/macros/${macro.id}/edit`)">
        <svg v-if="hasPermission" v-tooltip.top="$t('MACROS.EDIT.TOOLTIP')"
              width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.61666 16.2666C4.10832 16.2666 3.63332 16.0916 3.29166 15.7666C2.85832 15.3583 2.64999 14.7416 2.72499 14.075L3.03332 11.375C3.09166 10.8666 3.39999 10.1916 3.75832 9.82496L10.6 2.58329C12.3083 0.774959 14.0917 0.72496 15.9 2.43329C17.7083 4.14163 17.7583 5.92496 16.05 7.73329L9.20832 14.975C8.85832 15.35 8.20832 15.7 7.69999 15.7833L5.01666 16.2416C4.87499 16.25 4.74999 16.2666 4.61666 16.2666ZM13.275 2.42496C12.6333 2.42496 12.075 2.82496 11.5083 3.42496L4.66666 10.675C4.49999 10.85 4.30832 11.2666 4.27499 11.5083L3.96666 14.2083C3.93332 14.4833 3.99999 14.7083 4.14999 14.85C4.29999 14.9916 4.52499 15.0416 4.79999 15L7.48332 14.5416C7.72499 14.5 8.12499 14.2833 8.29166 14.1083L15.1333 6.86663C16.1667 5.76663 16.5417 4.74996 15.0333 3.33329C14.3667 2.69163 13.7917 2.42496 13.275 2.42496Z" fill="#656877"/>
          <path d="M14.4502 9.12504C14.4335 9.12504 14.4085 9.12504 14.3919 9.12504C11.7919 8.8667 9.7002 6.8917 9.3002 4.30837C9.2502 3.9667 9.48353 3.65004 9.8252 3.5917C10.1669 3.5417 10.4835 3.77504 10.5419 4.1167C10.8585 6.13337 12.4919 7.68337 14.5252 7.88337C14.8669 7.9167 15.1169 8.22504 15.0835 8.5667C15.0419 8.88337 14.7669 9.12504 14.4502 9.12504Z" fill="#656877"/>
          <path d="M17.5 18.958H2.5C2.15833 18.958 1.875 18.6747 1.875 18.333C1.875 17.9913 2.15833 17.708 2.5 17.708H17.5C17.8417 17.708 18.125 17.9913 18.125 18.333C18.125 18.6747 17.8417 18.958 17.5 18.958Z" fill="#656877"/>
        </svg>
        <svg v-else v-tooltip.top="$t('MACROS.VIEW.TOOLTIP')"
               width="30" height="30" viewBox="6 6 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.9999 23.6083C18.0083 23.6083 16.3916 21.9916 16.3916 19.9999C16.3916 18.0083 18.0083 16.3916 19.9999 16.3916C21.9916 16.3916 23.6083 18.0083 23.6083 19.9999C23.6083 21.9916 21.9916 23.6083 19.9999 23.6083ZM19.9999 17.6416C18.6999 17.6416 17.6416 18.6999 17.6416 19.9999C17.6416 21.2999 18.6999 22.3583 19.9999 22.3583C21.2999 22.3583 22.3583 21.2999 22.3583 19.9999C22.3583 18.6999 21.2999 17.6416 19.9999 17.6416Z" fill="#656877"/>
              <path d="M19.9999 27.5167C16.8666 27.5167 13.9082 25.6834 11.8749 22.5001C10.9916 21.1251 10.9916 18.8834 11.8749 17.5001C13.9166 14.3167 16.8749 12.4834 19.9999 12.4834C23.1249 12.4834 26.0832 14.3167 28.1166 17.5001C28.9999 18.8751 28.9999 21.1167 28.1166 22.5001C26.0832 25.6834 23.1249 27.5167 19.9999 27.5167ZM19.9999 13.7334C17.3082 13.7334 14.7332 15.3501 12.9332 18.1751C12.3082 19.1501 12.3082 20.8501 12.9332 21.8251C14.7332 24.6501 17.3082 26.2667 19.9999 26.2667C22.6916 26.2667 25.2666 24.6501 27.0666 21.8251C27.6916 20.8501 27.6916 19.1501 27.0666 18.1751C25.2666 15.3501 22.6916 13.7334 19.9999 13.7334Z" fill="#656877"/>
        </svg>

      </router-link>
      <svg
          v-if="hasPermission"
          class="ml-3"
          v-tooltip.top="$t('MACROS.DELETE.TOOLTIP')"
          @click="$emit('delete')"
          width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.5001 5.60839C17.4834 5.60839 17.4584 5.60839 17.4334 5.60839C13.0251 5.16673 8.62505 5.00006 4.26672 5.44173L2.56672 5.60839C2.21672 5.64173 1.90839 5.39173 1.87505 5.04173C1.84172 4.69173 2.09172 4.39173 2.43339 4.35839L4.13338 4.19173C8.56672 3.74173 13.0584 3.91673 17.5584 4.35839C17.9001 4.39173 18.1501 4.70006 18.1167 5.04173C18.0917 5.36673 17.8167 5.60839 17.5001 5.60839Z" fill="#656877"/>
          <path d="M7.08314 4.76699C7.0498 4.76699 7.01647 4.76699 6.9748 4.75866C6.64147 4.70033 6.40814 4.37533 6.46647 4.04199L6.6498 2.95033C6.78314 2.15033 6.96647 1.04199 8.90814 1.04199H11.0915C13.0415 1.04199 13.2248 2.19199 13.3498 2.95866L13.5331 4.04199C13.5915 4.38366 13.3581 4.70866 13.0248 4.75866C12.6831 4.81699 12.3581 4.58366 12.3081 4.25033L12.1248 3.16699C12.0081 2.44199 11.9831 2.30033 11.0998 2.30033H8.91647C8.03314 2.30033 8.01647 2.41699 7.89147 3.15866L7.6998 4.24199C7.6498 4.55033 7.38314 4.76699 7.08314 4.76699Z" fill="#656877"/>
          <path d="M12.675 18.9586H7.325C4.41666 18.9586 4.3 17.3503 4.20833 16.0503L3.66666 7.65864C3.64166 7.31697 3.90833 7.01697 4.25 6.99197C4.6 6.97531 4.89166 7.23364 4.91666 7.57531L5.45833 15.967C5.55 17.2336 5.58333 17.7086 7.325 17.7086H12.675C14.425 17.7086 14.4583 17.2336 14.5417 15.967L15.0833 7.57531C15.1083 7.23364 15.4083 6.97531 15.75 6.99197C16.0917 7.01697 16.3583 7.30864 16.3333 7.65864L15.7917 16.0503C15.7 17.3503 15.5833 18.9586 12.675 18.9586Z" fill="#656877"/>
          <path d="M11.3834 14.375H8.6084C8.26673 14.375 7.9834 14.0917 7.9834 13.75C7.9834 13.4083 8.26673 13.125 8.6084 13.125H11.3834C11.7251 13.125 12.0084 13.4083 12.0084 13.75C12.0084 14.0917 11.7251 14.375 11.3834 14.375Z" fill="#656877"/>
          <path d="M12.0832 11.042H7.9165C7.57484 11.042 7.2915 10.7587 7.2915 10.417C7.2915 10.0753 7.57484 9.79199 7.9165 9.79199H12.0832C12.4248 9.79199 12.7082 10.0753 12.7082 10.417C12.7082 10.7587 12.4248 11.042 12.0832 11.042Z" fill="#656877"/>
      </svg>
    </td>
  </tr>
</template>

<script>
import Thumbnail from 'dashboard/components/widgets/Thumbnail';
import accountMixin from 'dashboard/mixins/account.js';
import {mapGetters} from "vuex";
export default {
  components: {
    Thumbnail,
  },
  mixins: [accountMixin],
  data() {
     return {
         hasPermission: false,
     }
  },
  props: {
    macro: {
      type: Object,
      required: true,
    },
  },
  mounted() {
      this.manageMacroPermission();
  },
  computed: {
     ...mapGetters({
              currentUser: 'getCurrentUser',
     }),
    visibilityLabel() {
      return this.macro.visibility === 'global'
        ? this.$t('MACROS.EDITOR.VISIBILITY.GLOBAL.LABEL')
        : this.$t('MACROS.EDITOR.VISIBILITY.PERSONAL.LABEL');
    },
  },
  methods: {
      manageMacroPermission() {
        if (this.currentUser.role == 'agent' && this.macro.created_by.role === 'administrator') {
            this.hasPermission = false;
        }
        else {
            this.hasPermission = true;
        }
      }
  }
};
</script>

<style scoped lang="scss">
.avatar-container {
  display: flex;
  align-items: center;

  span {
    margin-left: var(--space-small);
    margin-right: var(--space-small);
  }
}
</style>
