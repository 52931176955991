<template>
  <div
    class="conv-header d-flex justify-space-between"
    style="padding-right: 3rem !important;"
  >
    <div class="d-flex">
      <back-button v-if="showBackButton" :back-url="backButtonUrl" />
      <div class="breadcrumbs p-2">
        <h2 class="display-10">Conversations</h2>
        <div aria-label="Breadcrumb" class="breadcrumb-fl">
          <ul>
            <li>
              <span class="font-inter display-ul" ref="#">Conversations</span>
            </li>
            <li>
              <span class="font-inter display-ul" ref="#"
                >All conversation</span
              >
            </li>
            <li>
              <span class="bread_active display-ul font-inter" ref="#"
                >Conversation {{ currentChat.id }}</span
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <agent-details @toggle-menu="toggleOptions" />
    <options-menu
      :show="showOptionsMenu"
      @toggle-accounts="toggleAccountModal"
      @show-support-chat-window="toggleSupportChatWindow"
      @key-shortcut-modal="$emit('key-shortcut-modal')"
      @close="toggleOptions"
    />
  </div>
</template>
<script>
import { hasPressedAltAndOKey } from 'shared/helpers/KeyboardHelpers';
import { mapGetters } from 'vuex';
import agentMixin from '../../../mixins/agentMixin.js';
import BackButton from '../BackButton';
import differenceInHours from 'date-fns/differenceInHours';
import eventListenerMixins from 'shared/mixins/eventListenerMixins';
import inboxMixin from 'shared/mixins/inboxMixin';
import InboxName from '../InboxName';
import MoreActions from './MoreActions';
import Thumbnail from '../Thumbnail';
import wootConstants from 'dashboard/constants/globals';
import { conversationListPageURL } from 'dashboard/helper/URLHelper';
import AgentDetails from '../../layout/sidebarComponents/AgentDetails';
import OptionsMenu from '../../layout/sidebarComponents/OptionsMenu';
export default {
  components: {
    OptionsMenu,
    AgentDetails,
    BackButton,
    InboxName,
    MoreActions,
    Thumbnail,
  },
  mixins: [inboxMixin, agentMixin, eventListenerMixins],
  data() {
    return {
      showCreateModal: false,
      showImportModal: false,
      showOptionsMenu: false,
    };
  },
  props: {
    chat: {
      type: Object,
      default: () => {},
    },
    isContactPanelOpen: {
      type: Boolean,
      default: false,
    },
    showBackButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxAssignableAgents/getUIFlags',
      currentChat: 'getSelectedChat',
    }),
    chatMetadata() {
      return this.chat.meta;
    },
    backButtonUrl() {
      const {
        params: { accountId, inbox_id: inboxId, label, teamId },
        name,
      } = this.$route;
      return conversationListPageURL({
        accountId,
        inboxId,
        label,
        teamId,
        conversationType: name === 'conversation_mentions' ? 'mention' : '',
      });
    },
    isHMACVerified() {
      if (!this.isAWebWidgetInbox) {
        return true;
      }
      return this.chatMetadata.hmac_verified;
    },
    currentContact() {
      return this.$store.getters['contacts/getContact'](
        this.chat.meta.sender.id
      );
    },
    isSnoozed() {
      return this.currentChat.status === wootConstants.STATUS_TYPE.SNOOZED;
    },
    snoozedDisplayText() {
      const { snoozed_until: snoozedUntil } = this.currentChat;
      if (snoozedUntil) {
        // When the snooze is applied, it schedules the unsnooze event to next day/week 9AM.
        // By that logic if the time difference is less than or equal to 24 + 9 hours we can consider it tomorrow.
        const MAX_TIME_DIFFERENCE = 33;
        const isSnoozedUntilTomorrow =
          differenceInHours(new Date(snoozedUntil), new Date()) <=
          MAX_TIME_DIFFERENCE;
        return this.$t(
          isSnoozedUntilTomorrow
            ? 'CONVERSATION.HEADER.SNOOZED_UNTIL_TOMORROW'
            : 'CONVERSATION.HEADER.SNOOZED_UNTIL_NEXT_WEEK'
        );
      }
      return this.$t('CONVERSATION.HEADER.SNOOZED_UNTIL_NEXT_REPLY');
    },
    contactPanelToggleText() {
      return `${
        this.isContactPanelOpen
          ? this.$t('CONVERSATION.HEADER.CLOSE')
          : this.$t('CONVERSATION.HEADER.OPEN')
      } ${this.$t('CONVERSATION.HEADER.DETAILS')}`;
    },
    inbox() {
      const { inbox_id: inboxId } = this.chat;
      return this.$store.getters['inboxes/getInbox'](inboxId);
    },
  },

  methods: {
    handleKeyEvents(e) {
      if (hasPressedAltAndOKey(e)) {
        this.$emit('contact-panel-toggle');
      }
    },
      toggleOptions() {
          this.showOptionsMenu = !this.showOptionsMenu;
      },
      toggleAccountModal() {
          this.$emit('toggle-accounts');
      },
      toggleSupportChatWindow() {
          window.$convergence.toggle();
      },

  },
};
</script>

<style lang="scss" scoped>
.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.conv-header {
  flex: 0 0 var(--space-jumbo);
}

.option__desc {
  display: flex;
  align-items: center;
}

.option__desc {
  &::v-deep .status-badge {
    margin-right: var(--space-small);
    min-width: 0;
    flex-shrink: 0;
  }
}

.user--name {
  display: inline-block;
  font-size: var(--font-size-medium);
  line-height: 1.3;
  margin: 0;
  text-transform: capitalize;
  width: 100%;
}

.conversation--header--actions {
  align-items: center;
  display: flex;
  font-size: var(--font-size-mini);

  .user--profile__button {
    padding: 0;
  }

  .snoozed--display-text {
    font-weight: var(--font-weight-medium);
    color: var(--y-600);
  }
}

.hmac-warning__icon {
  color: var(--y-600);
}
</style>
