<template>
  <div class="columns profile--settings">
    <div>
      <tabs :tabs="tabs" :currentTab="currentTab" :wrapper-class="'default-tabs'" :tab-class="'default-tabs__item'"
        :tab-active-class="'default-tabs__item_active'" :line-class="'default-tabs__active-line'"
        @onClick="handleClick" />
      <div class="content" :style="{ backgroundImage: `url(${tabBackgroundImage})` }">
        <div v-if="currentTab === 'tab1'">
          <form @submit.prevent="updateUser('profile')">
            <div class="">
              <div class="small-10 columns">
                <div style="margin-bottom: 15px" class="d-flex">
                  <div class="small-3 columns">
                    <label class="right inline profile_labels mt-3">
                      Profile photo :
                    </label>
                  </div>
                  <div v-if="avatarUrl" class="avatar-delete-btn del_btn">
                    <svg @click="deleteAvatar" class="cursor-pointer" width="34" height="34" viewBox="0 0 34 34"
                      fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="1" y="1" width="32" height="32" rx="16" fill="white" stroke="#DDDDDD" />
                      <path
                        d="M23.0004 13.4867C22.9871 13.4867 22.9671 13.4867 22.9471 13.4867C19.4204 13.1334 15.9004 13 12.4138 13.3534L11.0538 13.4867C10.7738 13.5134 10.5271 13.3134 10.5004 13.0334C10.4738 12.7534 10.6738 12.5134 10.9471 12.4867L12.3071 12.3534C15.8538 11.9934 19.4471 12.1334 23.0471 12.4867C23.3204 12.5134 23.5204 12.76 23.4938 13.0334C23.4738 13.2934 23.2538 13.4867 23.0004 13.4867Z"
                        fill="#656877" />
                      <path
                        d="M14.6675 12.8134C14.6408 12.8134 14.6142 12.8134 14.5808 12.8067C14.3142 12.76 14.1275 12.5 14.1742 12.2334L14.3208 11.36C14.4275 10.72 14.5742 9.83337 16.1275 9.83337H17.8742C19.4342 9.83337 19.5808 10.7534 19.6808 11.3667L19.8275 12.2334C19.8742 12.5067 19.6875 12.7667 19.4208 12.8067C19.1475 12.8534 18.8875 12.6667 18.8475 12.4L18.7008 11.5334C18.6075 10.9534 18.5875 10.84 17.8808 10.84H16.1342C15.4275 10.84 15.4142 10.9334 15.3142 11.5267L15.1608 12.3934C15.1208 12.64 14.9075 12.8134 14.6675 12.8134Z"
                        fill="#656877" />
                      <path
                        d="M19.1406 24.1667H14.8606C12.5339 24.1667 12.4406 22.8801 12.3673 21.8401L11.9339 15.1267C11.9139 14.8534 12.1273 14.6134 12.4006 14.5934C12.6806 14.5801 12.9139 14.7867 12.9339 15.0601L13.3673 21.7734C13.4406 22.7867 13.4673 23.1667 14.8606 23.1667H19.1406C20.5406 23.1667 20.5673 22.7867 20.6339 21.7734L21.0673 15.0601C21.0873 14.7867 21.3273 14.5801 21.6006 14.5934C21.8739 14.6134 22.0873 14.8467 22.0673 15.1267L21.6339 21.8401C21.5606 22.8801 21.4673 24.1667 19.1406 24.1667Z"
                        fill="#656877" />
                      <path
                        d="M18.1067 20.5H15.8867C15.6134 20.5 15.3867 20.2733 15.3867 20C15.3867 19.7267 15.6134 19.5 15.8867 19.5H18.1067C18.3801 19.5 18.6067 19.7267 18.6067 20C18.6067 20.2733 18.3801 20.5 18.1067 20.5Z"
                        fill="#656877" />
                      <path
                        d="M18.6673 17.8334H15.334C15.0607 17.8334 14.834 17.6067 14.834 17.3334C14.834 17.06 15.0607 16.8334 15.334 16.8334H18.6673C18.9407 16.8334 19.1673 17.06 19.1673 17.3334C19.1673 17.6067 18.9407 17.8334 18.6673 17.8334Z"
                        fill="#656877" />
                    </svg>
                  </div>
                  <woot-avatar-uploader :label="$t('PROFILE_SETTINGS.FORM.PROFILE_IMAGE.LABEL')" :src="avatarUrl"
                    @change="handleImageUpload" />
                </div>
                <div class="d-flex">
                  <div class="small-3 columns">
                    <label :class="{ error: $v.name.$error }" class="right inline profile_labels">
                      {{ $t('PROFILE_SETTINGS.FORM.NAME.LABEL') }} :
                    </label>
                  </div>
                  <div class="small-9 columns ml-5">
                    <input v-model="name" type="text" :placeholder="$t('PROFILE_SETTINGS.FORM.NAME.PLACEHOLDER')"
                      @input="$v.name.$touch" class="profile_inputs" />
                  </div>
                </div>
                <div class="d-flex">
                  <div class="small-3 columns">
                    <label :class="{ error: $v.displayName.$error }" class="right inline profile_labels">
                      {{ $t('PROFILE_SETTINGS.FORM.DISPLAY_NAME.LABEL') }} :
                    </label>
                  </div>
                  <div class="small-9 columns ml-5">
                    <input v-model="displayName" type="text" :placeholder="
                      $t('PROFILE_SETTINGS.FORM.DISPLAY_NAME.PLACEHOLDER')
                    " @input="$v.displayName.$touch" class="profile_inputs" />
                  </div>
                </div>
                <div class="d-flex">
                  <div class="small-3 columns ">
                    <label v-if="!globalConfig.disableUserProfileUpdate" :class="{ error: $v.email.$error }"
                      class="right inline profile_labels">
                      {{ $t('PROFILE_SETTINGS.FORM.EMAIL.LABEL') }} :
                    </label>
                  </div>
                  <div class="small-9 columns ml-5">
                    <input v-model.trim="email" type="email" :placeholder="$t('PROFILE_SETTINGS.FORM.EMAIL.PLACEHOLDER')"
                      @input="$v.email.$touch" class="profile_inputs" />
                    <span v-if="$v.email.$error" class="message">
                      {{ $t('PROFILE_SETTINGS.FORM.EMAIL.ERROR') }}
                    </span>
                    <div class="d-flex mt-2 ps_zone mb-5">
                      <svg width="14" height="18" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M14.0204 10.33L12.8004 9.11C12.5104 8.86 12.3404 8.49 12.3304 8.08C12.3104 7.63 12.4904 7.18 12.8204 6.85L14.0204 5.65C15.0604 4.61 15.4504 3.61 15.1204 2.82C14.8004 2.04 13.8104 1.61 12.3504 1.61H1.90039V0.75C1.90039 0.34 1.56039 0 1.15039 0C0.740391 0 0.400391 0.34 0.400391 0.75V19.25C0.400391 19.66 0.740391 20 1.15039 20C1.56039 20 1.90039 19.66 1.90039 19.25V14.37H12.3504C13.7904 14.37 14.7604 13.93 15.0904 13.14C15.4204 12.35 15.0404 11.36 14.0204 10.33Z"
                          fill="#292D32" />
                      </svg>
                      <h4 class="text-gray-400 custom_txt ml-3">PS: {{ $t('PROFILE_SETTINGS.FORM.PROFILE_SECTION.NOTE') }}</h4>
                    </div>
                  </div>
                </div>
                <message-signature />
                <div class="float-right mt-3 mleft_5">
                  <woot-button type="submit" :is-loading="isProfileUpdating">
                    {{ $t('PROFILE_SETTINGS.BTN_TEXT') }}
                  </woot-button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div v-if="currentTab === 'tab2'" class="pl-5">
          <div class="profile--settings--row row">
            <div class="columns small-9 medium-5 card-preview">
              <button v-for="keyOption in keyOptions" :key="keyOption.key" class="preview-button"
                @click="toggleEditorMessageKey(keyOption.key)">
                <preview-card :heading="keyOption.heading" :content="keyOption.content" :src="keyOption.src"
                  :active="isEditorHotKeyEnabled(uiSettings, keyOption.key)" />
              </button>
            </div>
          </div>
        </div>
        <div v-if="currentTab === 'tab4'" class="password_tab">
          <change-password v-if="!globalConfig.disableUserProfileUpdate" />
        </div>
        <div v-if="currentTab === 'tab5'">
          <notification-settings />
        </div>
        <div v-if="currentTab === 'tab6'">
          <div class="profile--settings--row row">
            <div>
              <div class="d-flex mt-2 ps_zone mb-3">
                <svg width="14" height="18" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M14.0204 10.33L12.8004 9.11C12.5104 8.86 12.3404 8.49 12.3304 8.08C12.3104 7.63 12.4904 7.18 12.8204 6.85L14.0204 5.65C15.0604 4.61 15.4504 3.61 15.1204 2.82C14.8004 2.04 13.8104 1.61 12.3504 1.61H1.90039V0.75C1.90039 0.34 1.56039 0 1.15039 0C0.740391 0 0.400391 0.34 0.400391 0.75V19.25C0.400391 19.66 0.740391 20 1.15039 20C1.56039 20 1.90039 19.66 1.90039 19.25V14.37H12.3504C13.7904 14.37 14.7604 13.93 15.0904 13.14C15.4204 12.35 15.0404 11.36 14.0204 10.33Z"
                    fill="#292D32" />
                </svg>
                <h4 class="text-gray-400 custom_txt ml-3">PS:{{
                  useInstallationName(
                    $t('PROFILE_SETTINGS.FORM.ACCESS_TOKEN.NOTE'),
                    globalConfig.installationName
                  )
                }}</h4>
              </div>
              <woot-code :script="currentUser.access_token" />
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import { required, minLength, email } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import { clearCookiesOnLogout } from '../../../../store/utils/api';
import NotificationSettings from './NotificationSettings';
import alertMixin from 'shared/mixins/alertMixin';
import ChangePassword from './ChangePassword';
import MessageSignature from './MessageSignature';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import uiSettingsMixin, {
  isEditorHotKeyEnabled,
} from 'dashboard/mixins/uiSettings';
import PreviewCard from 'dashboard/components/ui/PreviewCard.vue';
import Tabs from 'vue-tabs-with-active-line';
import WootButton from "../../../../components/ui/WootButton";

const TABS = [{
  title: 'Personnal information',
  value: 'tab1',
}, {
  title: 'Hotkey',
  value: 'tab2',
}, {
  title: 'Password',
  value: 'tab4',
}, {
  title: 'Notification',
  value: 'tab5',
}, {
  title: 'Access Token',
  value: 'tab6',
}
];

const TABSFR = [{
    title: 'Information personnelle',
    value: 'tab1',
}, {
    title: 'Raccourcis clavier',
    value: 'tab2',
}, {
    title: 'Mot de passe',
    value: 'tab4',
}, {
    title: 'Notification',
    value: 'tab5',
}, {
    title: "Token d'accès",
    value: 'tab6',
}
];

export default {
  components: {
    WootButton,
    NotificationSettings,
    ChangePassword,
    MessageSignature,
    PreviewCard,
    Tabs
  },
  mixins: [alertMixin, globalConfigMixin, uiSettingsMixin],
  data() {
    return {
      avatarFile: '',
      avatarUrl: '',
      name: '',
      displayName: '',
      email: '',
      isProfileUpdating: false,
      tabs: this.$root.$i18n.locale === 'fr' ? TABSFR : TABS ,
      currentTab: 'tab1',
      errorMessage: '',
      keyOptions: [
        {
          key: 'enter',
          src: '/assets/images/dashboard/editor/enter-editor.png',
          heading: this.$t(
            'PROFILE_SETTINGS.FORM.SEND_MESSAGE.CARD.ENTER_KEY.HEADING'
          ),
          content: this.$t(
            'PROFILE_SETTINGS.FORM.SEND_MESSAGE.CARD.ENTER_KEY.CONTENT'
          ),
        },
        {
          key: 'cmd_enter',
          src: '/assets/images/dashboard/editor/cmd-editor.png',
          heading: this.$t(
            'PROFILE_SETTINGS.FORM.SEND_MESSAGE.CARD.CMD_ENTER_KEY.HEADING'
          ),
          content: this.$t(
            'PROFILE_SETTINGS.FORM.SEND_MESSAGE.CARD.CMD_ENTER_KEY.CONTENT'
          ),
        },
      ],
    };
  },
  validations: {
    name: {
      required,
      minLength: minLength(1),
    },
    displayName: {},
    email: {
      required,
      email,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      currentUserId: 'getCurrentUserID',
      globalConfig: 'globalConfig/get',
    }),
    tabBackgroundImage() {
      switch (this.currentTab) {
        case "tab1":
          return require('../../../../assets/images/informations_settings.svg');
        case "tab2":
          return require('../../../../assets/images/profile_hotkey.svg');
        case "tab3":
          return require('../../../../assets/images/informations_settings.svg');
        case "tab4":
          return require('../../../../assets/images/profile_lock.svg');
        case "tab5":
          return require('../../../../assets/images/profile_notification.svg');
        case "tab6":
          return require('../../../../assets/images/profile_token.svg');
        default:
          return "";
      }
    },
  },
  watch: {
    currentUserId(newCurrentUserId, prevCurrentUserId) {
      if (prevCurrentUserId !== newCurrentUserId) {
        this.initializeUser();
      }
    },
  },
  mounted() {
    if (this.currentUserId) {
      this.initializeUser();
    }
  },
  methods: {
    handleClick(newTab) {
      this.currentTab = newTab;
    },
    initializeUser() {
      this.name = this.currentUser.name;
      this.email = this.currentUser.email;
      this.avatarUrl = this.currentUser.avatar_url;
      this.displayName = this.currentUser.display_name;
    },
    isEditorHotKeyEnabled,
    async updateUser() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.showAlert(this.$t('PROFILE_SETTINGS.FORM.ERROR'));
        return;
      }

      this.isProfileUpdating = true;
      const hasEmailChanged = this.currentUser.email !== this.email;
      try {
        await this.$store.dispatch('updateProfile', {
          name: this.name,
          email: this.email,
          avatar: this.avatarFile,
          displayName: this.displayName,
        });
        this.isProfileUpdating = false;
        if (hasEmailChanged) {
          clearCookiesOnLogout();
          this.errorMessage = this.$t('PROFILE_SETTINGS.AFTER_EMAIL_CHANGED');
        }
        this.errorMessage = this.$t('PROFILE_SETTINGS.UPDATE_SUCCESS');
      } catch (error) {
        this.errorMessage = this.$t('RESET_PASSWORD.API.ERROR_MESSAGE');
        if (error?.response?.data?.error) {
          this.errorMessage = error.response.data.error;
        }
      } finally {
        this.isProfileUpdating = false;
        this.showAlert(this.errorMessage);
      }
    },
    handleImageUpload({ file, url }) {
      this.avatarFile = file;
      this.avatarUrl = url;
    },
    async deleteAvatar() {
      try {
        await this.$store.dispatch('deleteAvatar');
        this.avatarUrl = '';
        this.avatarFile = '';
        this.showAlert(this.$t('PROFILE_SETTINGS.AVATAR_DELETE_SUCCESS'));
      } catch (error) {
        this.showAlert(this.$t('PROFILE_SETTINGS.AVATAR_DELETE_FAILED'));
      }
    },
    showDeleteButton() {
      return this.avatarUrl && !this.avatarUrl.includes('www.gravatar.com');
    },
    toggleEditorMessageKey(key) {
      this.updateUISettings({ editor_message_key: key });
      this.showAlert(
        this.$t('PROFILE_SETTINGS.FORM.SEND_MESSAGE.UPDATE_SUCCESS')
      );
    },
  },
};
</script>

<style lang="scss">
@import '~dashboard/assets/scss/variables.scss';
@import '~dashboard/assets/scss/mixins.scss';

.profile--settings {
  overflow: auto;
  padding: 24px;
}

.profile--settings--row {
  //@include border-normal-bottom;
  align-items: center;
  display: flex;
  padding: $space-normal;

  .small-3 {
    padding: $space-normal $space-medium $space-normal 0;
  }

  .small-9 {
    padding: $space-normal;
  }

  .card-preview {
    display: flex;
    flex-direction: row;

    .preview-button {
      cursor: pointer;
      margin-right: var(--space-normal);
    }
  }
}

.default-tabs {
  position: relative;
  margin: 0 auto;

  &__item {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 17px;
    color: #656877;
    display: inline-block;
    margin: 0 5px;
    padding: 10px !important;
    padding-bottom: 8px !important;
    color: gray;
    text-decoration: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.25s;

    &_active {
      color: #483A9D;
    }

    &:hover {
      border-bottom: 0 !important;
    }

    &:focus {
      outline: none;
      border-bottom: 2px solid gray;
      color: #483A9D;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__active-line {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    background-color: #483A9D;
    transition: transform 0.4s ease, width 0.4s ease;
  }
}

.content {
  margin-top: 10px !important;
  height: auto !important;
  background-repeat: no-repeat;
  background-position: right 7% top 7%;
  border: 0.5px solid #A49DCF !important;
  border-radius: 8px !important;
  padding: 12rem 12rem 6rem 16rem !important;
  background-color: white;
  display: flex;
  justify-content: center;
  margin: 15px;
}

.custom_txt {
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  color: #737373 !important;
  margin-top: 2px !important;
}

.mleft_5 {
  margin-right: -4rem !important;
  margin-top: 5rem !important;
}

.password_tab {
  width: 65% !important;
  margin-left: -18rem !important;
  margin-top: 1rem !important;
}

.del_btn {
  margin-right: -24px !important;
  margin-left: 23px !important;
  z-index: 1 !important;
}
</style>
