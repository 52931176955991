<template>
  <woot-modal :show.sync="show" :on-close="onClose">
    <div class="column content-box">
      <woot-modal-header :header-title="$t('ATTRIBUTES_MGMT.ADD.TITLE')" />

      <form class="row" @submit.prevent="addAttributes">
        <div class="columns ">

            <multiselect-dropdown
                    :options="models"
                    :selected-item="attributeModel"
                    :multiselector-title="$t('ATTRIBUTES_MGMT.ADD.FORM.MODEL.PLACEHOLDER')"
                    :multiselector-placeholder="$t('ATTRIBUTES_MGMT.ADD.FORM.MODEL.PLACEHOLDER')"
                    :add-agent="true"
                    :has-thumbnail="false"
                    :is-attribute="true"
                    @click="selectAttributeOption"
            />
            <span v-if="$v.attributeModel.$error" class="message">
              {{ $t('ATTRIBUTES_MGMT.ADD.FORM.MODEL.ERROR') }}
            </span>
                    <input
                            v-model="displayName"
                            type="text"
                            :class="{ error: $v.displayName.$error }"
                            :error="
              $v.displayName.$error
                ? $t('ATTRIBUTES_MGMT.ADD.FORM.NAME.ERROR')
                : ''
            "
                            :placeholder="$t('ATTRIBUTES_MGMT.ADD.FORM.NAME.PLACEHOLDER')"
                            @input="onDisplayNameChange"
                            @blur="$v.displayName.$touch"
                            class="mt-4"
                    />
                    <!-- displayName's error message  -->
                    <span v-if="$v.displayName.$error" class="message">
              {{ $t('ATTRIBUTES_MGMT.ADD.FORM.NAME.ERROR') }}
          </span>
                    <input
                            v-model="attributeKey"
                            type="text"
                            :class="{ error: $v.attributeKey.$error }"
                            :error="$v.attributeKey.$error ? keyErrorMessage : ''"
                            :placeholder="$t('ATTRIBUTES_MGMT.ADD.FORM.KEY.PLACEHOLDER')"
                            @blur="$v.attributeKey.$touch"
                    />
                    <!-- attributeKey's error message  -->
                    <span v-if="$v.attributeKey.$error" class="message">
              {{ keyErrorMessage }}
            </span>
                    <textarea
                            v-model="description"
                            rows="3"
                            type="text"
                            :placeholder="$t('ATTRIBUTES_MGMT.ADD.FORM.DESC.PLACEHOLDER')"
                            @blur="$v.description.$touch"
                    />
                    <span v-if="$v.description.$error" class="message">
              {{ $t('ATTRIBUTES_MGMT.ADD.FORM.DESC.ERROR') }}
            </span>
            <multiselect-dropdown
                    :options="types"
                    :selected-item="attributeType"
                    :multiselector-title="$t('ATTRIBUTES_MGMT.ADD.FORM.TYPE.PLACEHOLDER')"
                    :multiselector-placeholder="$t('ATTRIBUTES_MGMT.ADD.FORM.TYPE.PLACEHOLDER')"
                    :add-agent="true"
                    :has-thumbnail="false"
                    :is-attribute="true"
                    @click="selectTypeOption"
            />
            <span v-if="$v.attributeType.$error" class="message">
              {{ $t('ATTRIBUTES_MGMT.ADD.FORM.TYPE.ERROR') }}
            </span>
                    <div v-if="isAttributeTypeList" class="multiselect--wrap">
                        <label>
                            {{ $t('ATTRIBUTES_MGMT.ADD.FORM.TYPE.LIST.LABEL') }}
                        </label>
                        <multiselect
                                :style="{'font-size':'13px'}"
                                ref="tagInput"
                                v-model="values"
                                :placeholder="
                $t('ATTRIBUTES_MGMT.ADD.FORM.TYPE.LIST.PLACEHOLDER')
              "
                                label="name"
                                track-by="name"
                                :class="{ invalid: isMultiselectInvalid }"
                                :options="options"
                                :multiple="true"
                                :taggable="true"
                                @close="onTouch"
                                @tag="addTagValue"
                        />
                        <label v-show="isMultiselectInvalid" class="error-message">
                            {{ $t('ATTRIBUTES_MGMT.ADD.FORM.TYPE.LIST.ERROR')  }}
                        </label>
                    </div>
                    <div class="medium-12 columns mt-4" :style="{'direction': 'rtl'}" style="margin-bottom: -2rem;">
                        <woot-submit-button
                                :disabled="isButtonDisabled"
                                :button-text="$t('ATTRIBUTES_MGMT.ADD.SUBMIT')"
                        />
                        <button class="button clear" @click.prevent="onClose">
                            {{ $t('ATTRIBUTES_MGMT.ADD.CANCEL_BUTTON_TEXT') }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </woot-modal>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import { convertToAttributeSlug } from 'dashboard/helper/commons.js';
import { ATTRIBUTE_MODELS, ATTRIBUTE_TYPES, updateOptionForAttributeType } from './constants';
import alertMixin from 'shared/mixins/alertMixin';
import MultiselectDropdown from 'shared/components/ui/MultiselectDropdown.vue'  ;

export default {
  components: {
     MultiselectDropdown,
  },
  mixins: [alertMixin],
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
    currentIndex: {
      type: Number,
      default: 0,
    }
  },

  data() {
    const attributeModel = this.getAttributeModel(this.currentIndex) ;
    return {
      displayName: '',
      description: '',
      attributeModel,
      attributeType: {
          id: 0,
          option: ATTRIBUTE_TYPES[0].option,
      },
      attributeKey: '',
      models: ATTRIBUTE_MODELS,
      types: ATTRIBUTE_TYPES,
      values: [],
      options: [],
      show: true,
      isTouched: false,
    };
  },
  mounted() {
      updateOptionForAttributeType(this.$root.$i18n.locale);
  },
  computed: {
    ...mapGetters({
      uiFlags: 'getUIFlags',
    }),
    isMultiselectInvalid() {
      return this.isTouched && this.values.length === 0;
    },
    isTagInputInvalid() {
      return this.isAttributeTypeList && this.values.length === 0;
    },
    attributeListValues() {
      return this.values.map(item => item.name);
    },
    isButtonDisabled() {
      return (
        this.$v.displayName.$invalid ||
        this.$v.description.$invalid ||
        this.uiFlags.isCreating ||
        this.isTagInputInvalid
      );
    },
    keyErrorMessage() {
      if (!this.$v.attributeKey.isKey) {
        return this.$t('ATTRIBUTES_MGMT.ADD.FORM.KEY.IN_VALID');
      }
      return this.$t('ATTRIBUTES_MGMT.ADD.FORM.KEY.ERROR');
    },
    isAttributeTypeList() {
      return this.attributeType.id === 6;
    },
  },

  validations: {
    displayName: {
      required,
      minLength: minLength(1),
    },
    description: {
      required,
    },
    attributeModel: {
      required,
    },
    attributeType: {
      required,
    },
    attributeKey: {
      required,
      isKey(value) {
        return !(value.indexOf(' ') >= 0);
      },
    },
  },

  methods: {
    addTagValue(tagValue) {
      const tag = {
        name: tagValue,
      };
      this.values.push(tag);
      this.$refs.tagInput.$el.focus();
    },
    onTouch() {
      this.isTouched = true;
    },
    onDisplayNameChange() {
      this.attributeKey = convertToAttributeSlug(this.displayName);
    },
    async addAttributes() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      try {
        await this.$store.dispatch('attributes/create', {
          attribute_display_name: this.displayName,
          attribute_description: this.description,
          attribute_model: this.attributeModel.id,
          attribute_display_type: this.attributeType.id,
          attribute_key: this.attributeKey,
          attribute_values: this.attributeListValues,
        });
        this.$emit("create", { model : this.attributeModel.id === 0 ? 'conversation_attribute' : 'contact_attribute' });
        this.alertMessage = this.$t('ATTRIBUTES_MGMT.ADD.API.SUCCESS_MESSAGE');
        this.onClose();
      } catch (error) {
        const errorMessage = error?.message;
        this.alertMessage =
          errorMessage || this.$t('ATTRIBUTES_MGMT.ADD.API.ERROR_MESSAGE');
      } finally {
        this.showAlert(this.alertMessage);
      }
    },
    selectAttributeOption(attribute) {
        if (this.attributeModel && this.attributeModel.id === attribute.id) {
            if (this.attributeModel.id === 0) {
                this.attributeModel = {
                    id: 1,
                    option: 'Contact',

                };
            }
            else if (this.attributeModel.id === 1) {
                this.attributeModel = {
                    id: 0,
                    option: 'Conversation',
                };
            }
        }
        else {
            this.attributeModel = attribute;
        }
    },
    selectTypeOption(type) {
        if (this.attributeType && this.attributeType.id === type.id) {
            this.attributeType = {
                id: 0,
                option: ATTRIBUTE_TYPES[0].option,
            }
        }
        else {
            this.attributeType = type ;
        }
    },
    getAttributeModel(index) {
      if (index === 0) {
        return {
          id: 0,
          option: 'Conversation',
        };
      } else if (index === 1) {
        return {
          id: 1,
          option: 'Contact',
        };
      }
      return {
        id: 0,
        option: 'Conversation'
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.key-value {
  padding: 0 var(--space-small) var(--space-small) 0;
  font-family: monospace;
}
.multiselect--wrap {
  margin-bottom: var(--space-normal);
  margin-top: 10px;  /* margin between multiselect */
  .error-message {
    color: var(--r-400);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-normal);
  }
  .invalid {
    ::v-deep {
      .multiselect__tags {
        border: 1px solid var(--r-400);
      }
    }
  }
}
::v-deep {
  .multiselect {
    margin-bottom: 0;
  }
  .multiselect__content-wrapper {
    display: none;
  }
  .multiselect--active .multiselect__tags {
    border-radius: var(--border-radius-normal);
  }
}

/* error message's style */
.message {
  color: #f94b4a;
  color: var(--r-400);
  display: block;
  font-size: 1.4rem;
  font-size: var(--font-size-small);
  font-weight: 400;
  margin-bottom: 1rem;
  margin-top: -1.6rem;
  width: 100%;
}
</style>
