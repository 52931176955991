<template>
    <div class="column content-box">
        <woot-button
                color-scheme="primary"
                icon="add-circle"
                @click="openAddPopup()"
                class="float-right mt-2"
        >
            {{ $t('ATTRIBUTES_MGMT.HEADER_BTN_TXT') }}
        </woot-button>
        <custom-attribute
            :current-page="currentPage"
            :size="size"
            @my-attribute="handleMyAttribute"
            @my-attribute-change="handleMyAttributeChange"
            @delete="onChangeTotal"
        />
        <table-footer
             :current-page="currentPage"
             :total-count="countAttributeSelected === 0 ? countAttribute : countAttributeSelected"
             :page-size="size"
             @page-change="onPageChange"
        />
        <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup">
            <add-attribute :on-close="hideAddPopup" :current-index="currentIndex" @create="onChangeTotal" />
        </woot-modal>

    </div>
</template>

<script>
import AddAttribute from './AddAttribute';
import CustomAttribute from './CustomAttribute';
import TableFooter from 'dashboard/components/widgets/TableFooter';
export default {
    components: {
        AddAttribute,
        CustomAttribute,
        TableFooter,
    },
    data() {
        return {
            showAddPopup: false,
            size: 5,
            currentPage: 1,
            countAttribute: 0,
            countAttributeSelected: 0,
            total: 0,
            prevCount: 0,
            selectAttribute: false,
            index:0,
        };
    },
    computed: {
      currentIndex() {
        return this.index ;
      }
    },
    methods: {
        openAddPopup() {
            this.showAddPopup = true;
        },
        hideAddPopup() {
            this.showAddPopup = false;
        },
        onPageChange(page) {
            this.currentPage = page;
        },
        handleMyAttribute(totalAttribute) {
            this.countAttribute = totalAttribute;
        },
        handleMyAttributeChange(totalAttributeSelected, index) {
            this.currentPage = 1 ;
            this.selectAttribute = false;
            this.countAttributeSelected = totalAttributeSelected;
            this.selectAttribute = true;
            this.index = index;
        },
        onChangeTotal(payload) {
            const attribute = payload.model;
            if(this.countAttributeSelected === 0) {
               this.prevCount = this.countAttribute;
            }
            else {
                this.prevCount = this.countAttributeSelected;
            }
            const attributes = this.$store.getters['attributes/getAttributesByModel'](attribute);
            if (this.selectAttribute === true) {
                this.countAttributeSelected = attributes.length ;
                this.total = this.countAttributeSelected;
            }
            else {
                this.countAttribute = attributes.length ;
                this.total = this.countAttribute;
            }
            /* return to the previous page when the last element of the current page is deleted */
            if (this.total < this.prevCount &&  this.total % 5 === 0 && this.total !== 0) {
                this.currentPage = this.currentPage - 1 ;
            }
        }
    },
};
</script>

<style></style>
