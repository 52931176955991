<template>
  <section class="campaigns-table-wrap">
      <div class="empty_camp" v-if="showEmptyResult" >
          <svg width="70" height="70" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20 17.0667C18.6333 17.0667 17.5 15.9334 17.5 14.5667V8.7334C17.5 7.36673 18.6333 6.2334 20 6.2334C21.3667 6.2334 22.5 7.36673 22.5 8.7334V14.5667C22.5 15.9667 21.3667 17.0667 20 17.0667Z" fill="#DAD8EB"/>
              <path d="M33.3335 17.0667C31.9668 17.0667 30.8335 15.9334 30.8335 14.5667V8.7334C30.8335 7.36673 31.9668 6.2334 33.3335 6.2334C34.7002 6.2334 35.8335 7.36673 35.8335 8.7334V14.5667C35.8335 15.9667 34.7002 17.0667 33.3335 17.0667Z" fill="#DAD8EB"/>
              <path d="M46.6665 17.0667C45.2998 17.0667 44.1665 15.9334 44.1665 14.5667V8.7334C44.1665 7.36673 45.2998 6.2334 46.6665 6.2334C48.0332 6.2334 49.1665 7.36673 49.1665 8.7334V14.5667C49.1665 15.9667 48.0332 17.0667 46.6665 17.0667Z" fill="#DAD8EB"/>
              <path d="M74.1668 44.1662C74.1668 35.4329 67.4002 28.3662 58.8668 27.6329C56.4002 23.5995 52.0002 20.8662 46.9335 20.8662H22.3668C14.6335 20.8662 8.3335 27.1662 8.3335 34.8995V36.6662H60.9668V34.8995C60.9668 34.2662 60.8668 33.6329 60.7668 33.0329C65.6002 34.4662 69.1668 38.8662 69.1668 44.1662C69.1668 49.3995 65.7002 53.7662 60.9668 55.2329V39.9995H8.3335V59.2995C8.3335 67.0329 14.6335 73.3329 22.3668 73.3329H46.9335C54.2668 73.3329 60.2335 67.6662 60.8335 60.4662C68.4335 58.9329 74.1668 52.1995 74.1668 44.1662Z" fill="#DAD8EB"/>
          </svg>
          <div class="camp_empty_msg">
              <span v-if="isOngoingType"> {{ $t('CAMPAIGN.ONGOING.404') }} </span>
              <span v-if="!isOngoingType"> {{ $t('CAMPAIGN.ONE_OFF.404') }} </span>
          </div>
      </div>
<!--      <empty-state v-if="showEmptyResult" :title="emptyMessage" />-->
    <ve-table
      v-else
      :columns="columns"
      style="width:100%"
      :table-data="tableData"
      :border-around="true" />

<!--      <div v-else>
          <table class="table table-hover mb-4">
              <tbody><tr>
                  <th>Title</th>
                  <th>Message</th>
                  <th>Inbox</th>
                  <th>Status</th>
                  <th>Sender</th>
                  <th>URL</th>
                  <th>Time</th>
                  <th class="">Actions</th>
              </tr>
              <tr v-for="(agent, index) in campaigns" :key="agent.id">
                  <td>
                      {{agent.title}}
                  </td>
                  <td>
                      {{agent.message}}
                  </td>
                  <td>
                      {{agent.inbox.name}}
                  </td>
                  <td>
                     <span v-if="agent.enabled" class="enabled_case"> Enabled <svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                          <path d="M8.00016 15.1663C4.04683 15.1663 0.833496 11.953 0.833496 7.99967C0.833496 4.04634 4.04683 0.833008 8.00016 0.833008C11.9535 0.833008 15.1668 4.04634 15.1668 7.99967C15.1668 11.953 11.9535 15.1663 8.00016 15.1663ZM8.00016 1.83301C4.60016 1.83301 1.8335 4.59967 1.8335 7.99967C1.8335 11.3997 4.60016 14.1663 8.00016 14.1663C11.4002 14.1663 14.1668 11.3997 14.1668 7.99967C14.1668 4.59967 11.4002 1.83301 8.00016 1.83301Z" fill="#49CF48"/>
                                                          <path d="M7.05346 10.3867C6.92012 10.3867 6.79346 10.3334 6.70012 10.2401L4.81346 8.3534C4.62012 8.16007 4.62012 7.84007 4.81346 7.64673C5.00679 7.4534 5.32679 7.4534 5.52012 7.64673L7.05346 9.18007L10.4801 5.7534C10.6735 5.56007 10.9935 5.56007 11.1868 5.7534C11.3801 5.94673 11.3801 6.26673 11.1868 6.46006L7.40679 10.2401C7.31346 10.3334 7.18679 10.3867 7.05346 10.3867Z" fill="#49CF48"/>
                                                          </svg>
                      </span>
                      <span v-if="!agent.enabled"> Not enabled  </span>
                  </td>
                  <td>
                      Bot
                  </td>
                  <td class="text-decoration-underline">
                      {{agent.trigger_rules.url}}
                  </td>
                  <td>
                      <span class="time_case">
                          <svg width="14" height="14" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M16.5 9C16.5 13.14 13.14 16.5 9 16.5C4.86 16.5 1.5 13.14 1.5 9C1.5 4.86 4.86 1.5 9 1.5C13.14 1.5 16.5 4.86 16.5 9Z" stroke="#656877" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M11.7827 11.3853L9.45766 9.99781C9.05266 9.75781 8.72266 9.18031 8.72266 8.70781V5.63281" stroke="#656877" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                          {{agent.trigger_rules.time_on_page}} Sec
                      </span>
                  </td>
                  <td>
                      <svg  class="delete_button" width="36" height="36" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect width="40" height="40" rx="5" fill="white"/>
                          <path d="M27.5001 15.6084C27.4834 15.6084 27.4584 15.6084 27.4334 15.6084C23.0251 15.1667 18.6251 15.0001 14.2667 15.4417L12.5667 15.6084C12.2167 15.6417 11.9084 15.3917 11.8751 15.0417C11.8417 14.6917 12.0917 14.3917 12.4334 14.3584L14.1334 14.1917C18.5667 13.7417 23.0584 13.9167 27.5584 14.3584C27.9001 14.3917 28.1501 14.7001 28.1167 15.0417C28.0917 15.3667 27.8167 15.6084 27.5001 15.6084Z" fill="#656877"/>
                          <path d="M17.0831 14.767C17.0498 14.767 17.0165 14.767 16.9748 14.7587C16.6415 14.7003 16.4081 14.3753 16.4665 14.042L16.6498 12.9503C16.7831 12.1503 16.9665 11.042 18.9081 11.042H21.0915C23.0415 11.042 23.2248 12.192 23.3498 12.9587L23.5331 14.042C23.5915 14.3837 23.3581 14.7087 23.0248 14.7587C22.6831 14.817 22.3581 14.5837 22.3081 14.2503L22.1248 13.167C22.0081 12.442 21.9831 12.3003 21.0998 12.3003H18.9165C18.0331 12.3003 18.0165 12.417 17.8915 13.1587L17.6998 14.242C17.6498 14.5503 17.3831 14.767 17.0831 14.767Z" fill="#656877"/>
                          <path d="M22.675 28.9586H17.325C14.4167 28.9586 14.3 27.3503 14.2083 26.0503L13.6667 17.6586C13.6417 17.317 13.9083 17.017 14.25 16.992C14.6 16.9753 14.8917 17.2336 14.9167 17.5753L15.4583 25.967C15.55 27.2336 15.5833 27.7086 17.325 27.7086H22.675C24.425 27.7086 24.4583 27.2336 24.5417 25.967L25.0833 17.5753C25.1083 17.2336 25.4083 16.9753 25.75 16.992C26.0917 17.017 26.3583 17.3086 26.3333 17.6586L25.7917 26.0503C25.7 27.3503 25.5833 28.9586 22.675 28.9586Z" fill="#656877"/>
                          <path d="M21.3834 24.375H18.6084C18.2667 24.375 17.9834 24.0917 17.9834 23.75C17.9834 23.4083 18.2667 23.125 18.6084 23.125H21.3834C21.7251 23.125 22.0084 23.4083 22.0084 23.75C22.0084 24.0917 21.7251 24.375 21.3834 24.375Z" fill="#656877"/>
                          <path d="M22.0832 21.042H17.9165C17.5748 21.042 17.2915 20.7587 17.2915 20.417C17.2915 20.0753 17.5748 19.792 17.9165 19.792H22.0832C22.4248 19.792 22.7082 20.0753 22.7082 20.417C22.7082 20.7587 22.4248 21.042 22.0832 21.042Z" fill="#656877"/>
                      </svg>

                      <svg   class="edit_button cursor-pointer" width="36" height="36" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect width="40" height="40" rx="5" fill="white"/>
                          <path d="M14.6167 26.2666C14.1083 26.2666 13.6333 26.0916 13.2917 25.7666C12.8583 25.3583 12.65 24.7416 12.725 24.075L13.0333 21.375C13.0917 20.8666 13.4 20.1916 13.7583 19.825L20.6 12.5833C22.3083 10.775 24.0917 10.725 25.9 12.4333C27.7083 14.1416 27.7583 15.925 26.05 17.7333L19.2083 24.975C18.8583 25.35 18.2083 25.7 17.7 25.7833L15.0167 26.2416C14.875 26.25 14.75 26.2666 14.6167 26.2666ZM23.275 12.425C22.6333 12.425 22.075 12.825 21.5083 13.425L14.6667 20.675C14.5 20.85 14.3083 21.2666 14.275 21.5083L13.9667 24.2083C13.9333 24.4833 14 24.7083 14.15 24.85C14.3 24.9916 14.525 25.0416 14.8 25L17.4833 24.5416C17.725 24.5 18.125 24.2833 18.2917 24.1083L25.1333 16.8666C26.1667 15.7666 26.5417 14.75 25.0333 13.3333C24.3667 12.6916 23.7917 12.425 23.275 12.425Z" fill="#656877"/>
                          <path d="M24.4502 19.125C24.4335 19.125 24.4085 19.125 24.3919 19.125C21.7919 18.8667 19.7002 16.8917 19.3002 14.3084C19.2502 13.9667 19.4835 13.65 19.8252 13.5917C20.1669 13.5417 20.4835 13.775 20.5419 14.1167C20.8585 16.1334 22.4919 17.6834 24.5252 17.8834C24.8669 17.9167 25.1169 18.225 25.0835 18.5667C25.0419 18.8834 24.7669 19.125 24.4502 19.125Z" fill="#656877"/>
                          <path d="M27.5 28.958H12.5C12.1583 28.958 11.875 28.6747 11.875 28.333C11.875 27.9913 12.1583 27.708 12.5 27.708H27.5C27.8417 27.708 28.125 27.9913 28.125 28.333C28.125 28.6747 27.8417 28.958 27.5 28.958Z" fill="#656877"/>
                      </svg>
                  </td>
              </tr>
              </tbody>
          </table>
      </div>-->


    <div v-if="isLoading" class="campaign--loader">
      <spinner />
      <span>{{ $t('CAMPAIGN.LIST.LOADING_MESSAGE') }}</span>
    </div>
  </section>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import { VeTable } from 'vue-easytable';
import Spinner from 'shared/components/Spinner.vue';
import Label from 'dashboard/components/ui/Label';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import WootButton from 'dashboard/components/ui/WootButton.vue';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import UserAvatarWithName from 'dashboard/components/widgets/UserAvatarWithName';
import campaignMixin from 'shared/mixins/campaignMixin';
import timeMixin from 'dashboard/mixins/time';
import rtlMixin from 'shared/mixins/rtlMixin';
import InboxName from 'dashboard/components/widgets/InboxName';
import BotAvatar from "../../../../components/widgets/BotAvatar";
import CampaignStatus from "../../../../components/widgets/CampaignStatus";

export default {
  components: {
    EmptyState,
    Spinner,
    VeTable,
  },

  mixins: [clickaway, timeMixin, campaignMixin, messageFormatterMixin],

  props: {
    campaigns: {
      type: Array,
      default: () => [],
    },
    showEmptyResult: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
    methods : {
        editComp(row){
            this.$emit('on-delete-click', row)
        }
    },
  computed: {
    currentInboxId() {
      return this.$route.params.inboxId;
    },
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.currentInboxId);
    },
    inboxes() {
      if (this.isOngoingType) {
        return this.$store.getters['inboxes/getWebsiteInboxes'];
      }
      return this.$store.getters['inboxes/getTwilioInboxes'];
    },
    emptyMessage() {
      if (this.isOngoingType) {
        return this.inboxes.length
          ? this.$t('CAMPAIGN.ONGOING.404')
          : this.$t('CAMPAIGN.ONGOING.INBOXES_NOT_FOUND');
      }

      return this.inboxes.length
        ? this.$t('CAMPAIGN.ONE_OFF.404')
        : this.$t('CAMPAIGN.ONE_OFF.INBOXES_NOT_FOUND');
    },
    tableData() {
      if (this.isLoading) {
        return [];
      }
      return this.campaigns.map(item => {
        return {
          ...item,
          url: item.trigger_rules.url,
          timeOnPage: item.trigger_rules.time_on_page +' Sec',
          scheduledAt: item.scheduled_at
            ? this.messageStamp(new Date(item.scheduled_at), 'LLL d, h:mm a')
            : '---',
        };
      });
    },
    columns() {
      const visibleToAllTable = [
        {
          field: 'title',
          key: 'title',
          title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.TITLE'),
          align: 'left',
          renderBodyCell: ({ row }) => (
            <div class="row--title-block">
              <h6 class="sub-block-title title style_texting text-truncate">{row.title}</h6>
            </div>
          ),
        },

        {
          field: 'message',
          key: 'message',
          title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.MESSAGE'),
          align: 'left',
          renderBodyCell: ({ row }) => {
            if (row.message) {
              return (
                <div class="text-truncate">
                  <span
                      class="text-truncate style_texting"
                    domPropsInnerHTML={this.formatMessage(row.message)}
                  ></span>
                </div>
              );
            }
            return '';
          },
        },
        {
          field: 'inbox',
          key: 'inbox',
          title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.INBOX'),
          align: 'left',
          renderBodyCell: ({ row }) => {
            return <InboxName inbox={row.inbox} />;
          },
        },
      ];
      if (this.isOngoingType) {
        return [
          ...visibleToAllTable,
          {
            field: 'enabled',
            key: 'enabled',
            title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.STATUS'),
            align: 'left',
            renderBodyCell: ({ row }) => {
              const labelText = row.enabled
                ? this.$t('CAMPAIGN.LIST.STATUS.ENABLED')
                : this.$t('CAMPAIGN.LIST.STATUS.DISABLED');
              const colorScheme = row.enabled ? 'success' : 'secondary';
              return <CampaignStatus title={labelText} colorScheme={colorScheme} />;
            },
          },
          {
            field: 'sender',
            key: 'sender',
            title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.SENDER'),
            align: 'left',
            renderBodyCell: ({ row }) => {
              if (row.sender) return <UserAvatarWithName user={row.sender} />;
              return <BotAvatar />;
            },
          },
          {
            field: 'url',
            key: 'url',
            title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.URL'),
            align: 'left',
            renderBodyCell: ({ row }) => (
              <div class="url_txt">
                <a
                  target="_blank"
                  rel="noopener noreferrer nofollow "
                  href={row.url}
                  title={row.url}
                  class="url_txt"
                >
                  {row.url}
                </a>
              </div>
            ),
          },
          {
            field: 'timeOnPage',
            key: 'timeOnPage',
            title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.TIME_ON_PAGE'),
            align: 'left',
          },

          {
            field: 'buttons',
            key: 'buttons',
            title: 'Actions',
            align: 'left',
            renderBodyCell: row => (
              <div class="d-flex">
                <WootButton
                  variant="clear"
                  color-scheme="secondary"
                  classNames="grey-btn"
                  onClick={() => this.$emit('on-edit-click', row)}
                  class="mr-2"
                >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.61666 16.2666C4.10832 16.2666 3.63332 16.0916 3.29166 15.7666C2.85832 15.3583 2.64999 14.7416 2.72499 14.075L3.03332 11.375C3.09166 10.8666 3.39999 10.1916 3.75832 9.82496L10.6 2.58329C12.3083 0.774959 14.0917 0.72496 15.9 2.43329C17.7083 4.14163 17.7583 5.92496 16.05 7.73329L9.20832 14.975C8.85832 15.35 8.20832 15.7 7.69999 15.7833L5.01666 16.2416C4.87499 16.25 4.74999 16.2666 4.61666 16.2666ZM13.275 2.42496C12.6333 2.42496 12.075 2.82496 11.5083 3.42496L4.66666 10.675C4.49999 10.85 4.30832 11.2666 4.27499 11.5083L3.96666 14.2083C3.93332 14.4833 3.99999 14.7083 4.14999 14.85C4.29999 14.9916 4.52499 15.0416 4.79999 15L7.48332 14.5416C7.72499 14.5 8.12499 14.2833 8.29166 14.1083L15.1333 6.86663C16.1667 5.76663 16.5417 4.74996 15.0333 3.33329C14.3667 2.69163 13.7917 2.42496 13.275 2.42496Z" fill="#656877"/>
                        <path d="M14.4502 9.12504C14.4335 9.12504 14.4085 9.12504 14.3919 9.12504C11.7919 8.8667 9.7002 6.8917 9.3002 4.30837C9.2502 3.9667 9.48353 3.65004 9.8252 3.5917C10.1669 3.5417 10.4835 3.77504 10.5419 4.1167C10.8585 6.13337 12.4919 7.68337 14.5252 7.88337C14.8669 7.9167 15.1169 8.22504 15.0835 8.5667C15.0419 8.88337 14.7669 9.12504 14.4502 9.12504Z" fill="#656877"/>
                        <path d="M17.5 18.958H2.5C2.15833 18.958 1.875 18.6747 1.875 18.333C1.875 17.9913 2.15833 17.708 2.5 17.708H17.5C17.8417 17.708 18.125 17.9913 18.125 18.333C18.125 18.6747 17.8417 18.958 17.5 18.958Z" fill="#656877"/>
                    </svg>
                </WootButton>
                <WootButton
                  variant="link"
                  color-scheme="secondary"
                  onClick={() => this.$emit('on-delete-click', row)}
                  class="ml-4"
                >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.5001 5.60839C17.4834 5.60839 17.4584 5.60839 17.4334 5.60839C13.0251 5.16673 8.62505 5.00006 4.26672 5.44173L2.56672 5.60839C2.21672 5.64173 1.90839 5.39173 1.87505 5.04173C1.84172 4.69173 2.09172 4.39173 2.43339 4.35839L4.13338 4.19173C8.56672 3.74173 13.0584 3.91673 17.5584 4.35839C17.9001 4.39173 18.1501 4.70006 18.1167 5.04173C18.0917 5.36673 17.8167 5.60839 17.5001 5.60839Z" fill="#656877"/>
                        <path d="M7.08314 4.76699C7.0498 4.76699 7.01647 4.76699 6.9748 4.75866C6.64147 4.70033 6.40814 4.37533 6.46647 4.04199L6.6498 2.95033C6.78314 2.15033 6.96647 1.04199 8.90814 1.04199H11.0915C13.0415 1.04199 13.2248 2.19199 13.3498 2.95866L13.5331 4.04199C13.5915 4.38366 13.3581 4.70866 13.0248 4.75866C12.6831 4.81699 12.3581 4.58366 12.3081 4.25033L12.1248 3.16699C12.0081 2.44199 11.9831 2.30033 11.0998 2.30033H8.91647C8.03314 2.30033 8.01647 2.41699 7.89147 3.15866L7.6998 4.24199C7.6498 4.55033 7.38314 4.76699 7.08314 4.76699Z" fill="#656877"/>
                        <path d="M12.675 18.9586H7.325C4.41666 18.9586 4.3 17.3503 4.20833 16.0503L3.66666 7.65864C3.64166 7.31697 3.90833 7.01697 4.25 6.99197C4.6 6.97531 4.89166 7.23364 4.91666 7.57531L5.45833 15.967C5.55 17.2336 5.58333 17.7086 7.325 17.7086H12.675C14.425 17.7086 14.4583 17.2336 14.5417 15.967L15.0833 7.57531C15.1083 7.23364 15.4083 6.97531 15.75 6.99197C16.0917 7.01697 16.3583 7.30864 16.3333 7.65864L15.7917 16.0503C15.7 17.3503 15.5833 18.9586 12.675 18.9586Z" fill="#656877"/>
                        <path d="M11.3834 14.375H8.6084C8.26673 14.375 7.9834 14.0917 7.9834 13.75C7.9834 13.4083 8.26673 13.125 8.6084 13.125H11.3834C11.7251 13.125 12.0084 13.4083 12.0084 13.75C12.0084 14.0917 11.7251 14.375 11.3834 14.375Z" fill="#656877"/>
                        <path d="M12.0832 11.042H7.9165C7.57484 11.042 7.2915 10.7587 7.2915 10.417C7.2915 10.0753 7.57484 9.79199 7.9165 9.79199H12.0832C12.4248 9.79199 12.7082 10.0753 12.7082 10.417C12.7082 10.7587 12.4248 11.042 12.0832 11.042Z" fill="#656877"/>
                    </svg>
                </WootButton>
              </div>
            ),
          },
        ];
      }
      return [
        ...visibleToAllTable,
        {
          field: 'campaign_status',
          key: 'campaign_status',
          title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.STATUS'),
          align: 'left',
          renderBodyCell: ({ row }) => {
            const labelText =
              row.campaign_status === 'completed'
                ? this.$t('CAMPAIGN.LIST.STATUS.COMPLETED')
                : this.$t('CAMPAIGN.LIST.STATUS.ACTIVE');
            const colorScheme =
              row.campaign_status === 'completed' ? 'secondary' : 'success';
            return <Label title={labelText} colorScheme={colorScheme} />;
          },
        },
        {
          field: 'scheduledAt',
          key: 'scheduledAt',
          title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.SCHEDULED_AT'),
          align: 'left',
        },
        {
          field: 'buttons',
          key: 'buttons',
          title: '',
          align: 'left',
          renderBodyCell: row => (
            <div class="button-wrapper">
              <WootButton
                variant="link"
                icon="dismiss-circle"
                color-scheme="secondary"
                onClick={() => this.$emit('on-delete-click', row)}
              >
                {this.$t('CAMPAIGN.LIST.BUTTONS.DELETE')}
              </WootButton>
            </div>
          ),
        },
      ];
    },

  },

};
</script>

<style lang="scss" scoped>
.campaigns-table-wrap::v-deep {
  .ve-table {
    table{
      width: 100%;
    }
    .ve-table-container{
      border: none !important;
      background: #FFFFFF !important;;
      border-radius: 8px !important;;
    }

    .ve-table-container::-webkit-scrollbar {
      display: none !important;
    }

    .ve-table-container::-webkit-scrollbar-track {
      display: none !important;
    }

    .ve-table-container::-webkit-scrollbar-thumb {
        display: none !important;
    }

    padding-bottom: var(--space-large);
    thead.ve-table-header .ve-table-header-tr .ve-table-header-th {
      font-size: var(--font-size-mini);
      padding: var(--space-small) var(--space-two);
    }
    tbody.ve-table-body .ve-table-body-tr .ve-table-body-td {
      padding: var(--space-slab) var(--space-two);
    }
    .ve-table-header-th{
      border: none !important;
      font-family: 'Inter' !important;
      font-style: normal !important;
      font-weight: 300 !important;
      font-size: 13px !important;
      line-height: 17px !important;
      color: #A8A8A8 !important;
      background-color: white !important;
      text-transform: capitalize !important;
    }
  }

  .row--title-block {
    align-items: center;
    display: flex;
    text-align: left;

    .title {
      font-size: var(--font-size-small);
      margin: 0;
      text-transform: capitalize;
    }
  }
  .label {
    padding: var(--space-smaller) var(--space-small);
  }
}

.campaign--loader {
  align-items: center;
  display: flex;
  font-size: var(--font-size-default);
  justify-content: center;
  padding: var(--space-big);
}

.button-wrapper {
  justify-content: space-evenly;
  display: flex;
  flex-direction: row;
  min-width: 20rem;
}

.empty_camp{
  text-align: center;
  margin-top: 24rem;
  .camp_empty_msg{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    color: #A49DCF;
  }
}

.enabled_case{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 10px;
  color: #49CF48;
  svg {
    margin-bottom: -1.5px;
    margin-left: 3px;
  }
}

.time_case{
  svg{
    margin-bottom: -2px;
  }
}

.delete_button:hover{
  background-color: rgba(0, 162, 0, 0.38);
}

.edit_button:hover{
  background-color: rgba(252, 3, 3, 0.35);
}

.url_txt{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-decoration-line: underline;
  color: #656877;
  word-wrap: break-word;
}

.style_texting{
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 19px !important;
  color: #656877 !important;
}

.time_txt{
  color: red !important;
}

</style>
