<template>
  <div
    class="flex-container flex-dir-column medium-flex-dir-row filtersSectionSelector mt-0 "
    style="margin-right: 1rem;"
  >
    <div v-if="showAgentsFilter" class="pull-right" style="margin-right: 1rem;">
      <multiselect-dropdown
        v-model="selectedAgents"
        :placeholder="$t('CSAT_REPORTS.FILTERS.AGENTS.PLACEHOLDER')"
        :selected-item="selectedAgents"
        label="name"
        track-by="id"
        :options="agentsFilterItemsList"
        :option-height="20"
        :multiple="true"
        :close-on-select="false"
        :clear-on-select="false"
        :hide-selected="true"
        :report="true"
        @click="handleAgentsFilterSelection"
        :style="{ width: '20rem !important' }"
        class="custom-multiselect"
      />
    </div>
    <div
      v-if="showSubjectsFilter"
      class="pull-right"
      style="margin-right: 1rem;"
    >
      <multiselect-dropdown
        v-model="selectedSubject"
        :placeholder="$t('CSAT_REPORTS.FILTERS.AGENTS.PLACEHOLDER')"
        :multiselector-title="$t('CSAT_REPORTS.FILTERS.CSAT_TITLE')"
        :input-placeholder="$t('CSAT_REPORTS.FILTERS.SEARCH_PLACEHOLDER')"
        :selected-item="selectedSubject"
        label="name"
        track-by="id"
        :options="subjectsFilterItemsList"
        :option-height="20"
        :multiple="true"
        :close-on-select="false"
        :clear-on-select="false"
        :hide-selected="true"
        :report="true"
        :has-thumbnail="false"
        @click="handleSubjectsFilterSelection"
        :style="{ width: '24rem !important' }"
        class="custom-multiselect"
      />
    </div>
    <woot-date-range-picker
      show-range
      :value="customDateRange"
      :confirm-text="$t('REPORT.CUSTOM_DATE_RANGE.CONFIRM')"
      :placeholder="$t('REPORT.CUSTOM_DATE_RANGE.PLACEHOLDER')"
      :open="isDateClosed"
      @change="onChange"
    />
    <div
      v-if="notLast7Days && groupByFilter"
      class="small-12 medium-3 pull-right margin-left-small d-none"
    >
      <multiselect
        class="custom-multiselect"
        :style="{ width: '20rem !important' }"
        v-model="currentSelectedFilter"
        track-by="id"
        label="groupBy"
        :placeholder="$t('REPORT.GROUP_BY_FILTER_DROPDOWN_LABEL')"
        :options="filterItemsList"
        :allow-empty="false"
        :show-labels="false"
        @input="changeFilterSelection"
      />
    </div>

    <div class="small-12 medium-3 pull-right d-none">
      <multiselect
        v-model="currentDateRangeSelection"
        track-by="name"
        label="name"
        :placeholder="$t('FORMS.MULTISELECT.SELECT_ONE')"
        selected-label
        :options="dateRange"
        :searchable="false"
        :allow-empty="false"
        @select="changeDateSelection"
      />
    </div>
  </div>
</template>
<script>
import WootDateRangePicker from 'dashboard/components/ui/DateRangePicker.vue';
const CUSTOM_DATE_RANGE_ID = 5;
import subDays from 'date-fns/subDays';
import startOfDay from 'date-fns/startOfDay';
import getUnixTime from 'date-fns/getUnixTime';
import { GROUP_BY_FILTER } from '../constants';
import endOfDay from 'date-fns/endOfDay';
import MultiselectDropdown from '../../../../../../shared/components/ui/MultiselectDropdown';

export default {
  components: {
    WootDateRangePicker,
    MultiselectDropdown,
  },
  props: {
    filterItemsList: {
      type: Array,
      default: () => [],
    },
    agentsFilterItemsList: {
      type: Array,
      default: () => [],
    },
    subjectsFilterItemsList: {
      type: Array,
      default: () => [],
    },
    selectedGroupByFilter: {
      type: Object,
      default: () => {},
    },
    groupByFilter: {
      type: Boolean,
      default: false,
    },
    agentsFilter: {
      type: Boolean,
      default: false,
    },
    showBusinessHoursSwitch: {
      type: Boolean,
      default: true,
    },
    showAgentsFilter: {
      type: Boolean,
      default: false,
    },
    showSubjectsFilter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentDateRangeSelection: this.$t('REPORT.DATE_RANGE')[5],
      dateRange: this.$t('REPORT.DATE_RANGE'),
      customDateRange: [new Date(), new Date()],
      currentSelectedFilter: null,
      selectedAgents: [],
      businessHoursSelected: false,
      isDateClosed: false,
      selectedSubject: {
        id: 2 ,
        name: this.$t('CSAT_REPORTS.FILTERS.SERVICE_CLAIM') ,
        value: 'Claim.Service'
        }
    };
  },
  computed: {
    isDateRangeSelected() {
      return this.currentDateRangeSelection.id === CUSTOM_DATE_RANGE_ID;
    },
    to() {
      if (this.isDateRangeSelected) {
        return this.toCustomDate(this.customDateRange[1]);
      }
      return this.toCustomDate(new Date());
    },
    from() {
      if (this.isDateRangeSelected) {
        return this.fromCustomDate(this.customDateRange[0]);
      }
      const dateRange = {
        0: 6,
        1: 29,
        2: 89,
        3: 179,
        4: 364,
      };
      const diff = dateRange[this.currentDateRangeSelection.id];
      const fromDate = subDays(new Date(), diff);
      return this.fromCustomDate(fromDate);
    },
    groupBy() {
      if (this.isDateRangeSelected) {
        return GROUP_BY_FILTER[4].period;
      }
      const groupRange = {
        0: GROUP_BY_FILTER[1].period,
        1: GROUP_BY_FILTER[2].period,
        2: GROUP_BY_FILTER[3].period,
        3: GROUP_BY_FILTER[3].period,
        4: GROUP_BY_FILTER[3].period,
      };
      return groupRange[this.currentDateRangeSelection.id];
    },
    notLast7Days() {
      return this.groupBy !== GROUP_BY_FILTER[1].period;
    },
  },
  watch: {
    filterItemsList() {
      this.currentSelectedFilter = this.selectedGroupByFilter;
    },
    businessHoursSelected() {
      this.$emit('business-hours-toggle', this.businessHoursSelected);
    },
    '$root.$i18n.locale': function(newLocale, oldLocale) {
      this.selectedSubject = {
        id: 2,
        name: this.$t('CSAT_REPORTS.FILTERS.SERVICE_CLAIM'),
        value: 'Claim.Service'
      };
    }
  },
  mounted() {
    this.onDateRangeChange();
  },
  methods: {
    onDateRangeChange() {
      this.$emit('date-range-change', {
        from: this.from,
        to: this.to,
        groupBy: this.groupBy,
      });
    },
    fromCustomDate(date) {
      return getUnixTime(startOfDay(date));
    },
    toCustomDate(date) {
      return getUnixTime(endOfDay(date));
    },
    changeDateSelection(selectedRange) {
      this.currentDateRangeSelection = selectedRange;
      this.onDateRangeChange();
    },
    onChange(value) {
      this.customDateRange = value;
      this.onDateRangeChange();
    },
    changeFilterSelection() {
      this.$emit('filter-change', this.currentSelectedFilter);
    },
    handleAgentsFilterSelection(value) {
      this.selectedAgents = value;
      this.$emit('agents-filter-change', this.selectedAgents);
    },
    handleSubjectsFilterSelection(value) {
      this.selectedSubject = value;
      this.$emit('subject-filter-change', this.selectedSubject);
    },
    handleMultiselectClickOpen() {
      this.isDateClosed = true ;
    },
    handleMultiselectClickClose() {
        this.isDateClosed = false ;
    },
  },
};
</script>

<style lang="scss" scoped>
.date-picker {
  margin-left: var(--space-smaller);
}

.d-none {
  display: none !important;
}

.custom-multiselect {
  height: 35px !important;
  /*width: 20rem !important ;*/
}

.filtersSectionSelector {
  justify-content: end !important;
}
</style>
