<template>
  <div class="cin-list">
    <ul>
      <li v-for="history in sortedContactHistories" :key="history.id" class="cin-item">
        <span class="cin">{{ history.cin }}</span>
        <span class="created-date">{{ formatDateTime(history.created_at) }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import moment from 'moment'; // For date formatting

export default {
  components: {  },
  props: {
    contactId: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    contact() {
      return this.$store.getters['contacts/getContact'](this.contactId);
    },
    // Sort contact histories by created_at
    sortedContactHistories() {
      // Filter the current contact's history and sort by creation date
      return this.contact.contact_histories.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });
    }
  },
  mounted() {
    const { contactId } = this;
  },

  methods: {
    formatDateTime(dateTime) {
      return moment(dateTime).format('DD MMM YYYY, HH:mm'); // Format date
    }
  },
};
</script>

<style scoped>
.cin-item {
    display: flex; /* Use flexbox to align elements on the same line */
    justify-content: space-between; /* Place the CIN on the left and the date on the right */
    font-size: 16px;
    padding: 2px 0;
    border-bottom: 1px solid #e0e0e0; /* Separation between elements */
    text-align: left;
}

.cin {
    flex: 1; /* The CIN number occupies all the space on the left. */
}

.created-date {
    color: #9e9e9e;
    font-size: 14px;
    text-align: right;
}

.cin-item:hover {
    background-color: #f5f5f5;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

li {
    margin: 5px 0;
}
</style>
