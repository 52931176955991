<template>
  <div class="column content-box">
    <div class="d-flex report-action">
      <report-filter-selector
      :show-subjects-filter="true"
      :show-inbox-filter="true"
      :show-rating-filter="true"
      :show-team-filter="isTeamsEnabled"
      :show-business-hours-switch="false"
      :agents-filter-items-list="agentList"
      :subjects-filter-items-list="subjectList"
      @date-range-change="onDateRangeChange"
      @filter-change="onFilterChange"
      @agents-filter-change="onAgentsFilterChange"
      @subject-filter-change="onSubjectFilterChange"
    />
    <woot-button
      color-scheme="primary"
      class="button-download"
      icon="arrow-download"
      @click="downloadReports"
    >
      {{ $t('CSAT_REPORTS.DOWNLOAD') }}
    </woot-button>
    </div>
    <csat-metrics :filters="requestPayload" />
    <!--<csat-table :page-index="pageIndex" @page-change="onPageNumberChange" />-->
    <new-csat-table :page-index="pageIndex" @page-change="onPageNumberChange" />
  </div>
</template>
<script>
import CsatMetrics from './components/CsatMetrics';
import CsatTable from './components/CsatTable';
import NewCsatTable from './components/NewCsatTable.vue';
import ReportFilterSelector from './components/FilterSelector';
import { generateFileName } from '../../../../helper/downloadHelper';
import { REPORTS_EVENTS } from '../../../../helper/AnalyticsHelper/events';
import { mapGetters } from 'vuex';
import { FEATURE_FLAGS } from '../../../../featureFlags';
import alertMixin from '../../../../../shared/mixins/alertMixin';
import { CSAT_SUBJECTS, CSAT_SUBJECTS_FR } from '../../../../../shared/constants/messages';
import { GROUP_BY_FILTER } from "./constants";

export default {
  name: 'CsatResponses',
  components: {
    CsatMetrics,
    CsatTable,
    NewCsatTable,
    ReportFilterSelector,
  },
  mixins: [alertMixin],
  data() {
    return {
      pageIndex: 1,
      from: 0,
      to: 0,
      userIds: [],
      subjectId: [],
      inbox: null,
      team: null,
      rating: null,
      workflow: "Claim.Service",  /* default workflow */
      groupByfilterItemsList: this.$t('REPORT.GROUP_BY_DAY_OPTIONS'),
      selectedGroupByFilter: null,
      groupBy: GROUP_BY_FILTER[1],
    };
  },
  computed: {
    ...mapGetters({
      agents: 'agents/getAgents',
      accountId: 'getCurrentAccountId',
      isFeatureEnabledOnAccount: 'accounts/isFeatureEnabledonAccount',
    }),
   /* return confirmed agent list */
   agentList() {
          const confirmedAgents = this.agents.filter(agent => agent.confirmed === true);
          return confirmedAgents;
   },
    requestPayload() {
          return {
              from: this.from,
              to: this.to,
              /*user_ids: this.userIds,*/
              inbox_id: this.inbox,
              workflow: this.workflow,
              language: this.$root.$i18n.locale,
              /*team_id: this.team,
              rating: this.rating,*/
          };
    },
   isTeamsEnabled() {
          return this.isFeatureEnabledOnAccount(
              this.accountId,
              FEATURE_FLAGS.TEAM_MANAGEMENT
          );
   },
  /* to return list of subjects by language */
    subjectList() {
      if (this.$root.$i18n.locale === 'fr') {
        return CSAT_SUBJECTS_FR ;
      }
      else {
        return CSAT_SUBJECTS;
      }
    },
  },
  mounted() {
    this.$store.dispatch('agents/get');
  },
  methods: {
    getAllData() {
      this.$store.dispatch('csat/getMetrics', {
        from: this.from,
        to: this.to,
        workflow: this.workflow,
        /*user_ids: this.userIds,*/
      });
      this.getResponses();
    },
    getResponses() {
      this.$store.dispatch('csat/get', {
        page: this.pageIndex,
        ...this.requestPayload,
        from: this.from,
        to: this.to,
        workflow: this.workflow,
        /*user_ids: this.userIds,*/
      });
    },
    downloadReports() {
      const type = 'csat';
      try {
        this.$store.dispatch('csat/downloadCSATReports', {
          fileName: generateFileName({ type, to: this.to }) ,
          ...this.requestPayload,
        });
      } catch (error) {
        this.showAlert(this.$t('REPORT.CSAT_REPORTS.DOWNLOAD_FAILED'));
      }
    },
      /*downloadReports() {
      const type = 'csat';
      this.$store.dispatch('csat/downloadCSATReports', {
        from: this.from,
        to: this.to,
        user_ids: this.userIds,
        fileName: generateFileName({ type, to: this.to }),
      });
    },*/
    onPageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getResponses();
    },
    onFilterChange({
                         from,
                         to,
                         selectedAgents,
                         selectedInbox,
                         selectedTeam,
                         selectedRating,
                     }) {
          // do not track filter change on inital load
          if (this.from !== 0 && this.to !== 0) {
              this.$track(REPORTS_EVENTS.FILTER_REPORT, {
                  filterType: 'date',
                  reportType: 'csat',
              });
          }

          this.from = from;
          this.to = to;
          this.userIds = selectedAgents.map(el => el.id);
          this.inbox = selectedInbox?.id;
          this.team = selectedTeam?.id;
          this.rating = selectedRating?.value;

          this.getAllData();
    },
    /*onDateRangeChange({ from, to }) {
      // do not track filter change on inital load
      if (this.from !== 0 && this.to !== 0) {
        this.$track(REPORTS_EVENTS.FILTER_REPORT, {
          filterType: 'date',
          reportType: 'csat',
        });
      }
      this.from = from;
      this.to = to;
      this.getAllData();
    },*/
    onAgentsFilterChange(agents) {
      this.userIds = agents.map(el => el.id);
      this.getAllData();
      this.$track(REPORTS_EVENTS.FILTER_REPORT, {
        filterType: 'agent',
        reportType: 'csat',
      });
    },
    onSubjectFilterChange(subject) {
      this.workflow = subject.value ;
      this.pageIndex = 1;
      this.getAllData();
      this.$track(REPORTS_EVENTS.FILTER_REPORT, {
        filterType: 'agent',
        reportType: 'csat',
      });
    },
    fetchFilterItems(groupBy) {
      switch (groupBy) {
        case GROUP_BY_FILTER[2].period:
          return this.$t('REPORT.GROUP_BY_WEEK_OPTIONS');
        case GROUP_BY_FILTER[3].period:
          return this.$t('REPORT.GROUP_BY_MONTH_OPTIONS');
        case GROUP_BY_FILTER[4].period:
          return this.$t('REPORT.GROUP_BY_YEAR_OPTIONS');
        default:
          return this.$t('REPORT.GROUP_BY_DAY_OPTIONS');
      }
    },
    onDateRangeChange({ from, to, groupBy }) {
      // do not track filter change on inital load
      if (this.from !== 0 && this.to !== 0) {
        this.$track(REPORTS_EVENTS.FILTER_REPORT, {
          filterType: 'date',
          reportType: this.type,
        });
      }

      this.from = from;
      this.to = to;
      this.groupByfilterItemsList = this.fetchFilterItems(groupBy);
      const filterItems = this.groupByfilterItemsList.filter(
        item => item.id === this.groupBy.id
      );
      if (filterItems.length > 0) {
        this.selectedGroupByFilter = filterItems[0];
      } else {
        this.selectedGroupByFilter = this.groupByfilterItemsList[0];
        this.groupBy = GROUP_BY_FILTER[this.selectedGroupByFilter.id];
      }
      this.getAllData() ;
    },
  },
};
</script>

<style lang="scss" scoped>

  .report-action {
    margin-bottom: 3rem!important;
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
  }
  .button-download {
    height: 3.9rem !important;
    border-radius: 8px !important;
  }

</style>
