<template>
  <div class="settings--content">
    <loading-state v-if="uiFlags.isFetching || uiFlags.isFetchingAgentBot" />
    <form v-else class="row" @submit.prevent="updateActiveAgentBot">
      <settings-section
        :title="subBlockTitle"
        :sub-title="subHead"
      >
        <div class="medium-7 columns right-part">
          <label>
              <multiselect-dropdown
                      :options="agentBots"
                      :selected-item="selectedAgentBot"
                      :multiselector-title="$t('AGENT_BOTS.BOT_CONFIGURATION.SELECT_PLACEHOLDER')"
                      :multiselector-placeholder="$t('AGENT_BOTS.BOT_CONFIGURATION.SELECT_PLACEHOLDER')"
                      :no-options="$t('AGENT_BOTS.BOT_CONFIGURATION.NO_OPTIONS')"
                      :add-agent="true"
                      :has-thumbnail="false"
                      :is-bot="true"
                      @click="selectBotOption"
              />
          </label>
          <div class="button-container" style="width: max-content;">
            <woot-submit-button
              :button-text="submitText"
              :loading="uiFlags.isSettingAgentBot"
            />
            <woot-button
              type="button"
              :disabled="!selectedAgentBot.id"
              :loading="uiFlags.isDisconnecting"
              variant="smooth"
              color-scheme="alert"
              class="button--disconnect"
              @click="disconnectBot"
            >
              {{ disconnectText }}
            </woot-button>
          </div>
        </div>
      </settings-section>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SettingsSection from 'dashboard/components/SettingsSection';
import LoadingState from 'dashboard/components/widgets/LoadingState';
import alertMixin from 'shared/mixins/alertMixin';
import MultiselectDropdown from 'shared/components/ui/MultiselectDropdown.vue'  ;

export default {
  components: {
    LoadingState,
    SettingsSection,
    MultiselectDropdown
  },
  mixins: [alertMixin],
  props: {
    inbox: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedAgentBot: {},
      subBlockTitle: this.$root.$i18n.locale === 'fr' ? 'Sélectionner un agent bot' : 'Select an agent bot' ,
      subHead: this.$root.$i18n.locale === 'fr' ? 'Vous pouvez attribuer cette boîte de réception à un agent robot figurant dans la liste. Le robot peut initialement gérer la conversation et la transférer à un agent si nécessaire.'
                                                : 'You can set an agent bot from the list to this inbox. The bot can initially handle the conversation and transfer it to an agent when needed.' ,
      submitText: this.$root.$i18n.locale === 'fr' ? 'Mettre à jour' : 'Update' ,
      disconnectText: this.$root.$i18n.locale === 'fr' ? 'Déconnecter le bot' : 'Disconnect Bot' ,
    };
  },
  computed: {
    ...mapGetters({
      agentBots: 'agentBots/getBots',
      uiFlags: 'agentBots/getUIFlags',
    }),
    activeAgentBot() {
      return this.$store.getters['agentBots/getActiveAgentBot'](this.inbox.id);
    },
  },
  watch: {
    activeAgentBot() {
      this.selectedAgentBot = this.activeAgentBot;
    },
  },
  mounted() {
    this.$store.dispatch('agentBots/get');
    this.$store.dispatch('agentBots/fetchAgentBotInbox', this.inbox.id);
  },

  methods: {
    async updateActiveAgentBot() {
      try {
        await this.$store.dispatch('agentBots/setAgentBotInbox', {
          inboxId: this.inbox.id,
          // Added this to make sure that empty values are not sent to the API
          botId: this.selectedAgentBot.id ? this.selectedAgentBot.id : undefined,
        });
        this.showAlert(this.$t('AGENT_BOTS.BOT_CONFIGURATION.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('AGENT_BOTS.BOT_CONFIGURATION.ERROR_MESSAGE'));
      }
    },
    async disconnectBot() {
      try {
        await this.$store.dispatch('agentBots/disconnectBot', {
          inboxId: this.inbox.id,
        });
        this.showAlert(
          this.$t('AGENT_BOTS.BOT_CONFIGURATION.DISCONNECTED_SUCCESS_MESSAGE')
        );
      } catch (error) {
        this.showAlert(
          error?.message ||
            this.$t('AGENT_BOTS.BOT_CONFIGURATION.DISCONNECTED_ERROR_MESSAGE')
        );
      }
    },
    selectBotOption(option) {
        if (this.selectedAgentBot && this.selectedAgentBot.id === option.id) {
            this.selectedAgentBot = this.activeAgentBot;
        }
        else {
            this.selectedAgentBot = option;
        }
    }
  },
};
</script>

<style scoped lang="scss">
.button--disconnect {
  margin-left: var(--space-small);
}
.right-part{
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
