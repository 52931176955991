<template>
  <div
    class="flex-container flex-dir-column medium-flex-dir-row filtersSection"
  >
      <div v-if="type === 'agent'" style="width: 25rem">
        <multiselect-dropdown
                :options="filterItemsList"
                :selected-item="currentSelectedFilter"
                :multiselector-title="$t('AGENT_MGMT.MULTI_SELECTOR.TITLE.AGENT')"
                :multiselector-placeholder="$t('AGENT_MGMT.MULTI_SELECTOR.PLACEHOLDER')"
                :no-search-result="
                $t('AGENT_MGMT.MULTI_SELECTOR.SEARCH.NO_RESULTS.AGENT')
              "
                :input-placeholder="
                $t('AGENT_MGMT.MULTI_SELECTOR.SEARCH.PLACEHOLDER.AGENT')
              "
                :is-agent="true"
                :report="true"
                @click="changeFilter"
        />
      </div>
      <div v-if="type === 'label'" style="width: 23rem">
        <multi-label-drop-down
                :options="filterItemsList"
                :selected-item="currentSelectedFilter"
                :multiselector-title="$t('LABEL_REPORTS.FILTER_DROPDOWN_LABEL')"
                :no-search-result="
                      $t('NEW_CONVERSATION.FORM.INBOX.SEARCH.NO_RESULTS')
                    "
                :input-placeholder="
                      $t('NEW_CONVERSATION.FORM.INBOX.SEARCH.PLACEHOLDER')
                    "
                :report="true"
                @click="changeFilter"
        />
      </div>
      <div v-if="type === 'inbox'" style="width: 30rem">
        <inbox-drop-down
                :options="filterItemsList"
                :selected-item="currentSelectedFilter"
                :from-report="true"
                :multiselector-title="$t('NEW_CONVERSATION.FORM.INBOX.SELECT_INBOX')"
                :no-search-result="
                      $t('NEW_CONVERSATION.FORM.INBOX.SEARCH.NO_RESULTS')
                    "
                :input-placeholder="
                      $t('NEW_CONVERSATION.FORM.INBOX.SEARCH.PLACEHOLDER')
                    "
                :report="true"
                @click="changeFilter"
        />
      </div>
      <div v-if="type === 'team'" style="width: 30rem">
          <multiselect-dropdown
                  :options="filterItemsList"
                  :selected-item="currentSelectedFilter"
                  :multiselector-title="$t('AGENT_MGMT.MULTI_SELECTOR.TITLE.TEAM')"
                  :multiselector-placeholder="$t('AGENT_MGMT.MULTI_SELECTOR.PLACEHOLDER')"
                  :no-search-result="
                $t('AGENT_MGMT.MULTI_SELECTOR.SEARCH.NO_RESULTS.TEAM')
              "
                  :input-placeholder="
                $t('AGENT_MGMT.MULTI_SELECTOR.SEARCH.PLACEHOLDER.TEAM')
              "
                  :is-team="true"
                  :report="true"
                  @click="changeFilter"
          />
      </div>
    <woot-date-range-picker
      v-if="isDateRangeSelected"
      show-range
      :value="customDateRange"
      :confirm-text="$t('REPORT.CUSTOM_DATE_RANGE.CONFIRM')"
      :placeholder="$t('REPORT.CUSTOM_DATE_RANGE.PLACEHOLDER')"
      :open="isDateClosed"
      @change="onChange"
    />
    <div
      v-if="notLast7Days"
      class="small-12 medium-3 pull-right margin-left-small d-none"
    >
      <multiselect
        v-model="currentSelectedGroupByFilter"
        track-by="id"
        label="groupBy"
        :placeholder="$t('REPORT.GROUP_BY_FILTER_DROPDOWN_LABEL')"
        :options="groupByFilterItemsList"
        :allow-empty="false"
        :show-labels="false"
        @input="changeGroupByFilterSelection"
        class="custom-multiselect d-none"
      />
    </div>
  </div>
</template>
<script>
import endOfDay from 'date-fns/endOfDay';
import getUnixTime from 'date-fns/getUnixTime';
import startOfDay from 'date-fns/startOfDay';
import subDays from 'date-fns/subDays';
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import WootDateRangePicker from 'dashboard/components/ui/DateRangePicker.vue';
import MultiselectDropdown from 'shared/components/ui/MultiselectDropdown.vue' ;
import { GROUP_BY_FILTER } from '../constants';
import MultiLabelDropDown from './MultiLabelDropDown.vue';
import InboxDropDown from '../../../conversation/contact/InboxDropDown.vue';
const CUSTOM_DATE_RANGE_ID = 5;

export default {
  components: {
    WootDateRangePicker,
    Thumbnail,
    MultiselectDropdown,
    MultiLabelDropDown,
    InboxDropDown,
  },
  props: {
    filterItemsList: {
      type: Array,
      default: () => [],
    },
    groupByFilterItemsList: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'agent',
    },
    selectedGroupByFilter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      currentSelectedFilter: {},
      currentDateRangeSelection: this.$t('REPORT.DATE_RANGE')[5],
      dateRange: this.$t('REPORT.DATE_RANGE'),
      customDateRange: [new Date(), new Date()],
      currentSelectedGroupByFilter: null,
      businessHoursSelected: false,
      isDateClosed: false,
    };
  },
  computed: {
    isDateRangeSelected() {
      return this.currentDateRangeSelection.id === CUSTOM_DATE_RANGE_ID;
    },
    to() {
      if (this.isDateRangeSelected) {
        return this.toCustomDate(this.customDateRange[1]);
      }
      return this.toCustomDate(new Date());
    },
    from() {
      if (this.isDateRangeSelected) {
        return this.fromCustomDate(this.customDateRange[0]);
      }
      const dateRange = {
        0: 6,
        1: 29,
        2: 89,
        3: 179,
        4: 364,
      };
      const diff = dateRange[this.currentDateRangeSelection.id];
      const fromDate = subDays(new Date(), diff);
      return this.fromCustomDate(fromDate);
    },
    multiselectLabel() {
      const typeLabels = {
        agent: this.$t('AGENT_REPORTS.FILTER_DROPDOWN_LABEL'),
        label: this.$t('LABEL_REPORTS.FILTER_DROPDOWN_LABEL'),
        inbox: this.$t('INBOX_REPORTS.FILTER_DROPDOWN_LABEL'),
        team: this.$t('TEAM_REPORTS.FILTER_DROPDOWN_LABEL'),
      };
      return typeLabels[this.type] || this.$t('FORMS.MULTISELECT.SELECT_ONE');
    },
    groupBy() {
      if (this.isDateRangeSelected) {
        return GROUP_BY_FILTER[4].period;
      }
      const groupRange = {
        0: GROUP_BY_FILTER[1].period,
        1: GROUP_BY_FILTER[2].period,
        2: GROUP_BY_FILTER[3].period,
        3: GROUP_BY_FILTER[3].period,
        4: GROUP_BY_FILTER[3].period,
      };
      return groupRange[this.currentDateRangeSelection.id];
    },
    notLast7Days() {
      return this.groupBy !== GROUP_BY_FILTER[1].period;
    },
  },
  watch: {
    filterItemsList(val) {
      this.currentSelectedFilter = val[0];
      this.changeFilterSelection();
    },
    groupByFilterItemsList() {
      this.currentSelectedGroupByFilter = this.selectedGroupByFilter;
    },
    businessHoursSelected() {
      this.$emit('business-hours-toggle', this.businessHoursSelected);
    },
  },
  mounted() {
    this.onDateRangeChange();
  },
  methods: {
    onDateRangeChange() {
      this.$emit('date-range-change', {
        from: this.from,
        to: this.to,
        groupBy: this.groupBy,
      });
    },
    fromCustomDate(date) {
      return getUnixTime(startOfDay(date));
    },
    toCustomDate(date) {
      return getUnixTime(endOfDay(date));
    },
    changeDateSelection(selectedRange) {
      this.currentDateRangeSelection = selectedRange;
      this.onDateRangeChange();
    },
    changeFilter(option) {
        if ((this.currentSelectedFilter) && (this.currentSelectedFilter.id === option.id)) {
            if (this.type === 'agent') {
                this.currentSelectedFilter = {
                    id: '',
                    name: this.$t('AGENT_MGMT.MULTI_SELECTOR.TITLE.AGENT'),
                };
            }
            else if (this.type === 'label') {
                this.currentSelectedFilter = {
                    id: '',
                    title: this.$t('LABEL_REPORTS.FILTER_DROPDOWN_LABEL'),
                    color: '',
                };
            }
            else if (this.type === 'inbox') {
                this.currentSelectedFilter = {
                    id: '',
                    name: this.$t('NEW_CONVERSATION.FORM.INBOX.SELECT_INBOX'),
                };
            }
            else if (this.type === 'team') {
                this.currentSelectedFilter = {
                    id: '',
                    name: this.$t('AGENT_MGMT.MULTI_SELECTOR.TITLE.TEAM'),
                };
            }
        } else {
            this.currentSelectedFilter = option ;
        }
        this.changeFilterSelection();
    },
    changeFilterSelection() {
      if ((this.currentSelectedFilter) && (this.currentSelectedFilter.id !== "")) {
          this.$emit('filter-change', this.currentSelectedFilter);
      }
    },
    onChange(value) {
      this.customDateRange = value;
      this.onDateRangeChange();
    },
    changeGroupByFilterSelection() {
      this.$emit('group-by-filter-change', this.currentSelectedGroupByFilter);
    },
    handleMultiselectAgentOpen() {
      this.isDateClosed = true ;
    },
    handleMultiselectAgentClose() {
      this.isDateClosed = false ;
    },
    handleMultiselectLabelOpen() {
      this.isDateClosed = true ;
    },
    handleMultiselectLabelClose() {
      this.isDateClosed = false ;
    },
    handleMultiselectOpen() {
      this.isDateClosed = true ;
    },
    handleMultiselectClose() {
      this.isDateClosed = false ;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/widgets/_reports';
.custom-multiselect {
  height: 35px !important;
  width: auto !important;
}
.d-none {
  display: none !important;
}

.filtersSection {
  justify-content: end !important;
  margin-right: 1rem;
}

::v-deep .selector-wrap {
  margin-right: 12rem;
}
</style>
