<template>
  <div class="row">
    <div class="columns form_section">
      <form class="row" @submit.prevent="handleSubmit">
        <woot-input
              v-model.trim="title"
              :class="{ error: $v.title.$error }"
              class="medium-12 columns input_team"
              :placeholder="$t('TEAMS_SETTINGS.FORM.NAME.LABEL')"
              @input="$v.title.$touch"
              :maxlength="50"
        />
        <textarea
              v-model.trim="description"
              :class="{ error: $v.description.$error }"
              class="medium-12 columns textarea_team"
              :placeholder="$t('TEAMS_SETTINGS.FORM.DESCRIPTION.LABEL')"
              @input="$v.description.$touch"
              maxlength="200"
        />

        <div class="medium-12 mt-3">
          <input  v-model="allowAutoAssign" type="checkbox" :value="true" />
          <label for="conversation_creation" class="conversation_creation">
            {{ $t('TEAMS_SETTINGS.FORM.AUTO_ASSIGN.LABEL') }}
          </label>
        </div>
        <div class="modal-footer d-flex">
            <woot-submit-button
              :disabled="$v.title.$invalid || submitInProgress"
              :button-text="submitButtonText"
              :loading="submitInProgress"
              class="ml-2"
            />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import WootSubmitButton from '../../../../components/buttons/FormSubmitButton';
import validations from './helpers/validations';
import router from "../../../index";

export default {
  components: {
    WootSubmitButton,
  },

  props: {
    onSubmit: {
      type: Function,
      default: () => {},
    },
    submitInProgress: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: () => {},
    },
    submitButtonText: {
      type: String,
      default: '',
    },
  },
  data() {
    const formData = this.formData || {};
    const {
      description = '',
      name: title = '',
      allow_auto_assign: allowAutoAssign = true,
    } = formData;

    return {
      description,
      title,
      allowAutoAssign,
    };
  },
  validations,
  methods: {
      goBack() {
          if (this.backUrl !== '') {
              router.push(this.backUrl);
          } else {
              router.go(-1);
          }
      },
    handleSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.onSubmit({
        description: this.description,
        name: this.title,
        allow_auto_assign: this.allowAutoAssign,
      });
    },
  },
};
</script>

<style scoped lang="scss">

  .textarea_team{
    min-height: 15rem !important;
    font-size: 15px !important;
    padding: 7px !important;
  }

  .modal-footer{
    position: fixed !important;
    right: 4.5rem !important;
    bottom: 4.5rem !important;
  }

  input::placeholder {
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #3f434b !important;
  }

  .conversation_creation{
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    color: #737373 !important;
  }

  .back-button{
    color: #656877 !important;
  }

  .form_section{
    padding-left: 10rem;
    padding-top: 3rem;
  }

  .input_team{
    height: 4.9rem !important;
  }



</style>
