<template>
  <modal :show.sync="show" :on-close="onClose">
    <div class="column content-box">
      <woot-modal-header :header-title="pageTitle" />
      <form class="row medium-8" @submit.prevent="editAgent()">
        <div class="medium-12 columns">
            <input
              v-model.trim="agentName"
              type="text"
              :placeholder="$t('AGENT_MGMT.EDIT.FORM.NAME.PLACEHOLDER')"
              @input="$v.agentName.$touch"
              maxlength="50"
            />
        </div>

        <div class="medium-12 columns" style="margin-bottom: 1.5rem!important; margin-top: 0.5rem!important;">
            <multiselect-dropdown
                    ref="dropdownComponent0"
                    :options="roles"
                    :selected-item="agentType"
                    :multiselector-title="$t('AGENT_MGMT.ADD.FORM.AGENT_TYPE.PLACEHOLDER')"
                    :multiselector-placeholder="$t('AGENT_MGMT.ADD.FORM.AGENT_TYPE.PLACEHOLDER')"
                    :add-agent="true"
                    :has-thumbnail="false"
                    :close="closeDropdown0"
                    @click="selectRole"
                    @close="onChangeStatus0"
            />
            <span v-if="$v.agentType.$error" class="message">
              {{ $t('AGENT_MGMT.EDIT.FORM.AGENT_TYPE.ERROR') }}
            </span>
        </div>

        <div class="medium-12 columns">
          <label :class="{ error: $v.agentAvailability.$error }" style="display: block;">
            {{ $t('PROFILE_SETTINGS.FORM.AVAILABILITY.LABEL') }}
              <multiselect-dropdown
                      ref="dropdownComponent1"
                      :options="availabilityStatuses"
                      :selected-item="agentAvailability"
                      :multiselector-title="$t('AGENT_MGMT.EDIT.FORM.AGENT_AVAILABILITY.PLACEHOLDER')"
                      :multiselector-placeholder="$t('AGENT_MGMT.EDIT.FORM.AGENT_AVAILABILITY.PLACEHOLDER')"
                      :edit-agent="true"
                      :has-thumbnail="false"
                      :close="closeDropdown1"
                      @click="selectAvailability"
                      @close="onChangeStatus1"
              />
            <span v-if="$v.agentAvailability.$error" class="message">
              {{ $t('AGENT_MGMT.EDIT.FORM.AGENT_AVAILABILITY.ERROR') }}
            </span>
          </label>
        </div>
        <div class="medium-12 modal-footer" style="margin-top: 2rem; margin-bottom: -3rem ">
          <div class="medium-6 columns" style="margin-right: 0.6rem;">
            <button class="button clear" @click.prevent="onClose">
               {{ $t('AGENT_MGMT.EDIT.CANCEL_BUTTON_TEXT') }}
            </button>
            <woot-submit-button
              :disabled="
                $v.agentType.$invalid ||
                  $v.agentName.$invalid ||
                  uiFlags.isUpdating
              "
              :button-text="$t('AGENT_MGMT.EDIT.FORM.SUBMIT')"
              :loading="uiFlags.isUpdating"
            />
          </div>
          <div class="medium-6 columns text-right">
            <woot-button
              icon="lock-closed"
              variant="clear"
              @click.prevent="resetPassword"
            >
              {{ $t('AGENT_MGMT.EDIT.PASSWORD_RESET.ADMIN_RESET_BUTTON') }}
            </woot-button>
          </div>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import WootSubmitButton from '../../../../components/buttons/FormSubmitButton';
import Modal from '../../../../components/Modal';
import Auth from '../../../../api/auth';
import wootConstants from 'dashboard/constants/globals';
import MultiselectDropdown from 'shared/components/ui/MultiselectDropdown.vue' ;

const { AVAILABILITY_STATUS_KEYS } = wootConstants;

export default {
  components: {
    WootSubmitButton,
    Modal,
    MultiselectDropdown
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    availability: {
      type: String,
      default: '',
    },
    onClose: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      roles: [
        {
          id: 1,
          type: 'administrator',
          name: this.$t('AGENT_MGMT.AGENT_TYPES.ADMINISTRATOR'),
        },
        {
          id: 2,
          type: 'agent',
          name: this.$t('AGENT_MGMT.AGENT_TYPES.AGENT'),
        },
      ],
      agentName: this.name,
      agentAvailability: {
          id: this.availability === 'online' ? 1 : this.availability === 'busy' ? 2 : 3,
          label: this.availability === 'online' ? this.$t('PROFILE_SETTINGS.FORM.AVAILABILITY.STATUSES_LIST')[0]
                : this.availability === 'busy' ? this.$t('PROFILE_SETTINGS.FORM.AVAILABILITY.STATUSES_LIST')[1]
                : this.$t('PROFILE_SETTINGS.FORM.AVAILABILITY.STATUSES_LIST')[2],
          value: this.availability,
      },
      agentType: {
          id: this.type === 'administrator' ? 1 : 2,
          name: this.type === 'administrator' ? this.$t('AGENT_MGMT.AGENT_TYPES.ADMINISTRATOR') : this.$t('AGENT_MGMT.AGENT_TYPES.AGENT'),
          type: this.type,
      },
      agentCredentials: {
        email: this.email,
      },
      show: true,
      close0: false,
      close1: false,
    };
  },
  validations: {
    agentName: {
      required,
      minLength: minLength(1),
    },
    agentType: {
      required,
    },
    agentAvailability: {
      required,
    },
  },
  mounted() {
    document.addEventListener('mousedown', this.handleClickOutside0);
    document.addEventListener('mousedown', this.handleClickOutside1);
  },
  computed: {
    pageTitle() {
      return `${this.$t('AGENT_MGMT.EDIT.TITLE')} - ${this.name}`;
    },
    ...mapGetters({
      uiFlags: 'agents/getUIFlags',
    }),
    availabilityStatuses() {
      return this.$t('PROFILE_SETTINGS.FORM.AVAILABILITY.STATUSES_LIST').map(
        (statusLabel, index) => ({
          label: statusLabel,
          value: AVAILABILITY_STATUS_KEYS[index],
          disabled:
            this.currentUserAvailability === AVAILABILITY_STATUS_KEYS[index],
        })
      );
    },
    closeDropdown0() {
      return this.close0;
    },
    closeDropdown1() {
      return this.close1;
    },
  },
  methods: {
    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
    selectRole(role) {
          if (this.agentType && this.agentType.id === role.id) {
              if (this.agentType.type === 'agent') {
                  this.agentType = {
                      id: 1,
                      name: this.$t('AGENT_MGMT.AGENT_TYPES.ADMINISTRATOR'),
                      type: 'administrator',
                  };
              }
              else {
                  this.agentType = {
                      id: 2,
                      name: this.$t('AGENT_MGMT.AGENT_TYPES.AGENT'),
                      type: 'agent',
                  };
              }

          }
          else {
              this.agentType = role;
          }
    },
    selectAvailability(status) {
        if (this.agentAvailability && this.agentAvailability.value === status.value) {
            this.agentAvailability = {
                id: this.availability === 'online' ? 1 : this.availability === 'busy' ? 2 : 3,
                label: this.availability === 'online' ? this.$t('PROFILE_SETTINGS.FORM.AVAILABILITY.STATUSES_LIST')[0]
                      : this.availability === 'busy' ? this.$t('PROFILE_SETTINGS.FORM.AVAILABILITY.STATUSES_LIST')[1]
                      : this.$t('PROFILE_SETTINGS.FORM.AVAILABILITY.STATUSES_LIST')[2],
                value: this.availability,
            }
        }
        else {
            this.agentAvailability = {
                id: status.value === 'online' ? 1 : this.availability === 'busy' ? 2 : 3,
                label: status.label,
                value: status.value
            };
        }
    },
    async editAgent() {
      try {
        await this.$store.dispatch('agents/update', {
          id: this.id,
          name: this.agentName,
          role: this.agentType.type,
          availability: this.agentAvailability.value,
        });
        this.showAlert(this.$t('AGENT_MGMT.EDIT.API.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (error) {
        this.showAlert(this.$t('AGENT_MGMT.EDIT.API.ERROR_MESSAGE'));
      }
    },
    async resetPassword() {
      try {
        await Auth.resetPassword(this.agentCredentials);
        this.showAlert(
          this.$t('AGENT_MGMT.EDIT.PASSWORD_RESET.ADMIN_SUCCESS_MESSAGE')
        );
      } catch (error) {
        this.showAlert(this.$t('AGENT_MGMT.EDIT.PASSWORD_RESET.ERROR_MESSAGE'));
      }
    },
    handleClickOutside0(event) {
      // Check that the reference exists before accessing it
      if (this.$refs.dropdownComponent0 && this.$refs.dropdownComponent0.$refs.dropdownElement) {
        const dropdownElement = this.$refs.dropdownComponent0.$refs.dropdownElement;
        if (!dropdownElement.contains(event.target)) {
          this.onCloseDropdown0();
        }
      }
    },
    onCloseDropdown0() {
      this.close0=true;
    },
    onChangeStatus0(value) {
      if (value) {
        this.close0 = false;
      }
    },
    handleClickOutside1(event) {
      // Check that the reference exists before accessing it
      if (this.$refs.dropdownComponent1 && this.$refs.dropdownComponent1.$refs.dropdownElement) {
        const dropdownElement = this.$refs.dropdownComponent1.$refs.dropdownElement;
        if (!dropdownElement.contains(event.target)) {
          this.onCloseDropdown1();
        }
      }
    },
    onCloseDropdown1() {
      this.close1=true;
    },
    onChangeStatus1(value) {
      if (value) {
        this.close1 = false;
      }
    },
  },
};
</script>
