<template>
  <div class="wizard-body small-9 columns">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.SMS.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.SMS.DESC')"
    />
    <div class="medium-8 columns">
        <select v-model="provider">
          <option value="twilio">
            {{ $t('INBOX_MGMT.ADD.SMS.PROVIDERS.TWILIO') }}
          </option>
          <option value="360dialog">
            {{ $t('INBOX_MGMT.ADD.SMS.PROVIDERS.BANDWIDTH') }}
          </option>
        </select>
    </div>
    <twilio v-if="provider === 'twilio'" type="sms" />
    <bandwidth-sms v-else />
  </div>
</template>

<script>
import PageHeader from '../../SettingsSubPageHeader';
import BandwidthSms from './BandwidthSms.vue';
import Twilio from './Twilio';

export default {
  components: {
    PageHeader,
    Twilio,
    BandwidthSms,
  },
  data() {
    return {
      provider: 'twilio',
    };
  },
};
</script>
<style scoped lang="scss">
.cust_input{
  height: 5.2rem !important;
  border: 1px solid #737373 !important;
  color: #3c4858 !important;
  margin-top: 12px !important;
}

select{
  height: 5.2rem !important;
  border: 1px solid #737373 !important;
  font-size: 13px !important;
  margin-top: 12px !important;
}

.help-text{
  margin-top: 10px;
  color: gray;
}

.modal-footer{
  position: absolute !important;
  right: 4rem !important;
  bottom: 4rem !important;
}

.custom_padding{
  padding: 2rem 10rem !important;
}

</style>
