<template>
    <modal :show.sync="show" :on-close="onClose">
        <div class="column content-box">
            <woot-modal-header
                    :header-title="$t('CANNED_MGMT.ADD.TITLE')"
                    :header-content="$t('CANNED_MGMT.ADD.DESC')"
            />
            <form class="row" @submit.prevent="addCannedResponse()">
                <div class="medium-12 columns">
                    <label class="short-code" :class="{ error: $v.shortCode.$error }">
                        {{ $t('CANNED_MGMT.ADD.FORM.SHORT_CODE.LABEL') }}
                        <input
                                v-model.trim="shortCode"
                                type="text"
                                :placeholder="$t('CANNED_MGMT.ADD.FORM.SHORT_CODE.PLACEHOLDER')"
                                @input="$v.shortCode.$touch"
                                maxlength="50"
                        />
                    </label>
                </div>

                <div class="medium-12 columns">
                    <label class="content-area"  :class="{ error: $v.content.$error }">
                        {{ $t('CANNED_MGMT.ADD.FORM.CONTENT.LABEL') }}
                        <textarea
                                v-model.trim="content"
                                rows="5"
                                type="text"
                                :placeholder="$t('CANNED_MGMT.ADD.FORM.CONTENT.PLACEHOLDER')"
                                @input="$v.content.$touch"
                                maxlength="200"
                        />
                    </label>
                </div>
                <div class="medium-12 columns d-flex justify-content-end" style="margin-bottom: -2rem;" >
                    <button class="button clear" @click.prevent="onClose">
                        {{ $t('CANNED_MGMT.ADD.CANCEL_BUTTON_TEXT') }}
                    </button>
                    <woot-submit-button
                            class="create-btn"
                            :disabled="
                $v.content.$invalid ||
                  $v.shortCode.$invalid ||
                  addCanned.showLoading
              "
                            :button-text="$t('CANNED_MGMT.ADD.FORM.SUBMIT')"
                            :loading="addCanned.showLoading"
                    />

                </div>
            </form>
        </div>
    </modal>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';

import WootSubmitButton from '../../../../components/buttons/FormSubmitButton';
import Modal from '../../../../components/Modal';
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor';
import alertMixin from 'shared/mixins/alertMixin';

export default {
    components: {
        WootSubmitButton,
        Modal,
    },
    mixins: [alertMixin],
    props: {
        responseContent: {
            type: String,
            default: '',
        },
        onClose: {
            type: Function,
            default: () => {},
        },
    },
    data() {
        return {
            shortCode: '',
            content: this.responseContent || '',
            addCanned: {
                showLoading: false,
                message: '',
            },
            show: true,
        };
    },
    validations: {
        shortCode: {
            required,
            minLength: minLength(2),
        },
        content: {
            required,
        },
    },
    methods: {
        resetForm() {
            this.shortCode = '';
            this.content = '';
            this.$v.shortCode.$reset();
            this.$v.content.$reset();
        },
        addCannedResponse() {
            // Show loading on button
            this.addCanned.showLoading = true;
            // Make API Calls
            this.$store
                .dispatch('createCannedResponse', {
                    short_code: this.shortCode,
                    content: this.content,
                })
                .then(response => {
                    // Reset Form, Show success message
                    this.addCanned.showLoading = false;
                    this.showAlert(this.$t('CANNED_MGMT.ADD.API.SUCCESS_MESSAGE'));
                    this.resetForm();
                    this.onClose();
                })
                .catch(error => {
                    this.addCanned.showLoading = false;
                    const errorMessage =
                        error?.message || this.$t('CANNED_MGMT.ADD.API.ERROR_MESSAGE');
                    this.showAlert(errorMessage);
                });
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep {
  .ProseMirror-menubar {
    display: none;
  }

  .ProseMirror-woot-style {
    min-height: 20rem;

    p {
      font-size: var(--font-size-default);
    }
  }

  .message-editor {
    border: 1px solid var(--s-200);
  }
}

.short-code{
  width: 100%;
}
.content-area{
  width: 100%;
}
</style>
