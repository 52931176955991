var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dropdown-wrap"},[(!_vm.addAgent && !_vm.editAgent && !_vm.isBot && !_vm.isAttribute && !_vm.isContract && _vm.options.length && !_vm.isConv && !_vm.eventName && !_vm.filterQuestion && !_vm.isOperatorFilter
   && !_vm.isQueryOperator && !_vm.actionName && !_vm.actionParam)?_c('div',{staticClass:"search-wrap"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],ref:"searchbar",staticClass:"search-input",attrs:{"type":"text","autofocus":"true","placeholder":_vm.inputPlaceholder},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"list-scroll-container",class:{'scroll-dropdown': _vm.isConv || _vm.eventName}},[_c('div',{staticClass:"multiselect-dropdown--list",class:{'height-dropdown': _vm.isConv}},[_c('woot-dropdown-menu',_vm._l((_vm.filteredOptions),function(option){return _c('woot-dropdown-item',{key:option.id,style:({
                  marginLeft: option.hasOwnProperty('attributes') ? '-2rem' : '0rem'
          })},[_c('woot-button',{staticClass:"multiselect-dropdown--item",class:{
              active: _vm.isActive(option), 'height': _vm.isConv
            },style:({ cursor: option.hasOwnProperty('attributes') ? 'auto' : 'pointer' }),attrs:{"variant":_vm.isActive(option) ? 'hollow' : 'clear',"color-scheme":"secondary","disabled":option.hasOwnProperty('attributes') || _vm.conditionPermission(option)},on:{"click":function($event){$event.preventDefault();return (function () { return _vm.onclick(option); })($event)}}},[_c('div',{staticClass:"user-wrap"},[(_vm.hasThumbnail)?_c('Thumbnail',{attrs:{"src":option.thumbnail,"size":"24px","username":option.name,"status":option.availability_status,"has-border":""}}):_vm._e(),_vm._v(" "),(_vm.newConv)?_c('inbox-dropdown-item',{attrs:{"name":option.name,"inbox-identifier":_vm.computedInboxSource(option),"channel-type":option.channel_type}}):_vm._e(),_vm._v(" "),(!_vm.newConv)?_c('div',{staticClass:"name-wrap"},[_c('span',{staticClass:"name text-truncate text-block-title",class:{'font-size': _vm.isConv},style:({
                  fontSize: option.hasOwnProperty('attributes') ? '1.6rem' : '1.4rem'
                  })},[_vm._v("\n                  "+_vm._s(_vm.optionName(option))+"\n                ")]),_vm._v(" "),(_vm.isActive(option))?_c('fluent-icon',{attrs:{"icon":"checkmark"}}):_vm._e()],1):_vm._e()],1)])],1)}),1),_vm._v(" "),(_vm.noResult)?_c('h4',{staticClass:"no-result text-truncate text-block-title"},[_vm._v("\n        "+_vm._s(_vm.noSearchResult)+"\n      ")]):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }