<template>
  <div class="orders_section">
    <div class="mb-3">
      <button class="order_btn">
        Place new Order
      </button>
      <h4 class="mt-4 mb-4">Order History</h4>
      <div class="search_block">
        <form role="search">
          <svg
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z"
              fill="#656877"
            ></path>
            <path
              d="M21.9999 22.7499C21.8099 22.7499 21.6199 22.6799 21.4699 22.5299L19.4699 20.5299C19.1799 20.2399 19.1799 19.7599 19.4699 19.4699C19.7599 19.1799 20.2399 19.1799 20.5299 19.4699L22.5299 21.4699C22.8199 21.7599 22.8199 22.2399 22.5299 22.5299C22.3799 22.6799 22.1899 22.7499 21.9999 22.7499Z"
              fill="#656877"
            ></path>
          </svg>
          <input id="search" v-model="search" placeholder="Search" />
        </form>
      </div>
    </div>
    <div v-for="order in filteredList" class="order_box mt-2 mb-4">
      <div class="header d-flex justify-space-between">
        <span class="ref_name">Ref:CAR-{{ order.reference }}</span>
        <div class="order_status">
          {{ order.status }}
        </div>
      </div>
      <div class="d-flex mb-2 ">
        <div class="d-flex mr-4">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 4.3125C5.6925 4.3125 5.4375 4.0575 5.4375 3.75V1.5C5.4375 1.1925 5.6925 0.9375 6 0.9375C6.3075 0.9375 6.5625 1.1925 6.5625 1.5V3.75C6.5625 4.0575 6.3075 4.3125 6 4.3125Z"
              fill="#483A9D"
            />
            <path
              d="M12 4.3125C11.6925 4.3125 11.4375 4.0575 11.4375 3.75V1.5C11.4375 1.1925 11.6925 0.9375 12 0.9375C12.3075 0.9375 12.5625 1.1925 12.5625 1.5V3.75C12.5625 4.0575 12.3075 4.3125 12 4.3125Z"
              fill="#483A9D"
            />
            <path
              d="M15.375 7.37988H2.625C2.3175 7.37988 2.0625 7.12488 2.0625 6.81738C2.0625 6.50988 2.3175 6.25488 2.625 6.25488H15.375C15.6825 6.25488 15.9375 6.50988 15.9375 6.81738C15.9375 7.12488 15.6825 7.37988 15.375 7.37988Z"
              fill="#483A9D"
            />
            <path
              d="M12 17.0625H6C3.2625 17.0625 1.6875 15.4875 1.6875 12.75V6.375C1.6875 3.6375 3.2625 2.0625 6 2.0625H12C14.7375 2.0625 16.3125 3.6375 16.3125 6.375V12.75C16.3125 15.4875 14.7375 17.0625 12 17.0625ZM6 3.1875C3.855 3.1875 2.8125 4.23 2.8125 6.375V12.75C2.8125 14.895 3.855 15.9375 6 15.9375H12C14.145 15.9375 15.1875 14.895 15.1875 12.75V6.375C15.1875 4.23 14.145 3.1875 12 3.1875H6Z"
              fill="#483A9D"
            />
            <path
              d="M6.375 10.875C6.2775 10.875 6.18 10.8525 6.09 10.815C6 10.7775 5.91751 10.725 5.84251 10.6575C5.77501 10.5825 5.72249 10.5 5.68499 10.41C5.64749 10.32 5.625 10.2225 5.625 10.125C5.625 9.93001 5.70751 9.73502 5.84251 9.59252C5.91751 9.52502 6 9.4725 6.09 9.435C6.225 9.375 6.37501 9.36 6.52501 9.39C6.57001 9.3975 6.615 9.4125 6.66 9.435C6.705 9.45 6.75 9.47252 6.795 9.50252C6.8325 9.53252 6.86999 9.56252 6.90749 9.59252C6.93749 9.63002 6.97499 9.66751 6.99749 9.70501C7.02749 9.75001 7.05001 9.79501 7.06501 9.84001C7.08751 9.885 7.10251 9.93 7.11001 9.975C7.11751 10.0275 7.125 10.0725 7.125 10.125C7.125 10.32 7.04249 10.515 6.90749 10.6575C6.76499 10.7925 6.57 10.875 6.375 10.875Z"
              fill="#483A9D"
            />
            <path
              d="M9 10.8751C8.805 10.8751 8.61001 10.7926 8.46751 10.6576C8.43751 10.6201 8.40751 10.5826 8.37751 10.5451C8.34751 10.5001 8.32499 10.4552 8.30999 10.4102C8.28749 10.3652 8.27249 10.3202 8.26499 10.2752C8.25749 10.2227 8.25 10.1776 8.25 10.1251C8.25 10.0276 8.27249 9.93015 8.30999 9.84015C8.34749 9.75015 8.40001 9.66766 8.46751 9.59266C8.67751 9.38266 9.015 9.31514 9.285 9.43514C9.3825 9.47264 9.45749 9.52516 9.53249 9.59266C9.66749 9.73516 9.75 9.93015 9.75 10.1251C9.75 10.1776 9.74251 10.2227 9.73501 10.2752C9.72751 10.3202 9.71251 10.3652 9.69001 10.4102C9.67501 10.4552 9.65249 10.5001 9.62249 10.5451C9.59249 10.5826 9.56249 10.6201 9.53249 10.6576C9.45749 10.7251 9.3825 10.7777 9.285 10.8152C9.195 10.8527 9.0975 10.8751 9 10.8751Z"
              fill="#483A9D"
            />
            <path
              d="M6.375 13.5001C6.2775 13.5001 6.18 13.4777 6.09 13.4402C6 13.4027 5.91751 13.3501 5.84251 13.2826C5.77501 13.2076 5.72249 13.1327 5.68499 13.0352C5.64749 12.9452 5.625 12.8476 5.625 12.7501C5.625 12.5551 5.70751 12.3602 5.84251 12.2177C5.91751 12.1502 6 12.0976 6.09 12.0601C6.3675 11.9401 6.69749 12.0077 6.90749 12.2177C6.93749 12.2552 6.97499 12.2927 6.99749 12.3302C7.02749 12.3752 7.05001 12.4201 7.06501 12.4651C7.08751 12.5101 7.10251 12.5551 7.11001 12.6076C7.11751 12.6526 7.125 12.7051 7.125 12.7501C7.125 12.9451 7.04249 13.1401 6.90749 13.2826C6.76499 13.4176 6.57 13.5001 6.375 13.5001Z"
              fill="#483A9D"
            />
          </svg>
          <span class="ml-1 mt-1 purs_date">{{
            order.purchasedate | moment
          }}</span>
        </div>
        <div class="d-flex mr-4">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.75 11.0624H1.5C1.1925 11.0624 0.9375 10.8074 0.9375 10.4999V5.71487C0.9375 5.47487 1.08749 5.26486 1.30499 5.18236C1.52999 5.09986 1.77751 5.16738 1.92751 5.34738C2.38501 5.89488 3.09751 6.20237 3.80251 6.17987C4.40251 6.16487 4.95749 5.93989 5.37749 5.54239C5.57249 5.37739 5.72999 5.18237 5.84999 4.96487C6.08249 4.56737 6.195 4.13236 6.1875 3.68987C6.1725 2.99987 5.87252 2.36987 5.36252 1.91237C5.18252 1.75487 5.1225 1.50736 5.205 1.28986C5.2875 1.07236 5.49751 0.922363 5.73001 0.922363H11.25C11.5575 0.922363 11.8125 1.17736 11.8125 1.48486V8.98486C11.8125 10.1399 10.89 11.0624 9.75 11.0624ZM2.0625 9.93738H9.75C10.2675 9.93738 10.6875 9.51738 10.6875 8.99988V2.06238H6.8925C7.155 2.54988 7.2975 3.09739 7.3125 3.66739C7.3275 4.31989 7.16249 4.96487 6.83249 5.52737C6.65249 5.84987 6.40499 6.15739 6.13499 6.38239C5.53499 6.95239 4.70999 7.28988 3.83249 7.31238C3.20999 7.33488 2.59501 7.17738 2.07001 6.89238V9.93738H2.0625Z"
              fill="#483A9D"
            />
            <path
              d="M14.25 15.5625H13.5C13.1925 15.5625 12.9375 15.3075 12.9375 15C12.9375 14.4825 12.5175 14.0625 12 14.0625C11.4825 14.0625 11.0625 14.4825 11.0625 15C11.0625 15.3075 10.8075 15.5625 10.5 15.5625H7.5C7.1925 15.5625 6.9375 15.3075 6.9375 15C6.9375 14.4825 6.5175 14.0625 6 14.0625C5.4825 14.0625 5.0625 14.4825 5.0625 15C5.0625 15.3075 4.8075 15.5625 4.5 15.5625H3.75C2.1975 15.5625 0.9375 14.3025 0.9375 12.75V10.5C0.9375 10.1925 1.1925 9.9375 1.5 9.9375H9.75C10.2675 9.9375 10.6875 9.5175 10.6875 9V3.75C10.6875 3.4425 10.9425 3.1875 11.25 3.1875H12.63C13.3725 3.1875 14.055 3.58501 14.4225 4.23001L15.705 6.4725C15.8025 6.645 15.8025 6.8625 15.705 7.035C15.6075 7.2075 15.42 7.3125 15.2175 7.3125H14.25C14.145 7.3125 14.0625 7.395 14.0625 7.5V9.75C14.0625 9.855 14.145 9.9375 14.25 9.9375H16.5C16.8075 9.9375 17.0625 10.1925 17.0625 10.5V12.75C17.0625 14.3025 15.8025 15.5625 14.25 15.5625ZM13.9875 14.4375H14.25C15.18 14.4375 15.9375 13.68 15.9375 12.75V11.0625H14.25C13.53 11.0625 12.9375 10.47 12.9375 9.75V7.5C12.9375 6.78 13.5225 6.1875 14.25 6.1875L13.4475 4.785C13.2825 4.4925 12.9675 4.3125 12.63 4.3125H11.8125V9C11.8125 10.14 10.89 11.0625 9.75 11.0625H2.0625V12.75C2.0625 13.68 2.82 14.4375 3.75 14.4375H4.01248C4.25998 13.575 5.055 12.9375 6 12.9375C6.945 12.9375 7.74002 13.575 7.98752 14.4375H10.02C10.2675 13.575 11.0625 12.9375 12.0075 12.9375C12.9525 12.9375 13.74 13.575 13.9875 14.4375Z"
              fill="#483A9D"
            />
            <path
              d="M6 17.0625C4.86 17.0625 3.9375 16.14 3.9375 15C3.9375 13.86 4.86 12.9375 6 12.9375C7.14 12.9375 8.0625 13.86 8.0625 15C8.0625 16.14 7.14 17.0625 6 17.0625ZM6 14.0625C5.4825 14.0625 5.0625 14.4825 5.0625 15C5.0625 15.5175 5.4825 15.9375 6 15.9375C6.5175 15.9375 6.9375 15.5175 6.9375 15C6.9375 14.4825 6.5175 14.0625 6 14.0625Z"
              fill="#483A9D"
            />
            <path
              d="M12 17.0625C10.86 17.0625 9.9375 16.14 9.9375 15C9.9375 13.86 10.86 12.9375 12 12.9375C13.14 12.9375 14.0625 13.86 14.0625 15C14.0625 16.14 13.14 17.0625 12 17.0625ZM12 14.0625C11.4825 14.0625 11.0625 14.4825 11.0625 15C11.0625 15.5175 11.4825 15.9375 12 15.9375C12.5175 15.9375 12.9375 15.5175 12.9375 15C12.9375 14.4825 12.5175 14.0625 12 14.0625Z"
              fill="#483A9D"
            />
            <path
              d="M16.5 11.0625H14.25C13.53 11.0625 12.9375 10.47 12.9375 9.75V7.5C12.9375 6.78 13.53 6.1875 14.25 6.1875H15.2175C15.42 6.1875 15.6075 6.2925 15.705 6.4725L16.9875 8.7225C17.0325 8.805 17.0625 8.9025 17.0625 9V10.5C17.0625 10.8075 16.8075 11.0625 16.5 11.0625ZM14.25 7.3125C14.145 7.3125 14.0625 7.395 14.0625 7.5V9.75C14.0625 9.855 14.145 9.9375 14.25 9.9375H15.9375V9.15001L14.8875 7.3125H14.25Z"
              fill="#483A9D"
            />
            <path
              d="M3.75 7.3125C1.785 7.3125 0.1875 5.715 0.1875 3.75C0.1875 2.655 0.675022 1.64248 1.52252 0.967484C2.15252 0.464984 2.9475 0.1875 3.75 0.1875C5.715 0.1875 7.3125 1.785 7.3125 3.75C7.3125 4.77 6.87 5.745 6.0975 6.42C5.445 6.9975 4.6125 7.3125 3.75 7.3125ZM3.75 1.3125C3.195 1.3125 2.66998 1.49998 2.22748 1.85248C1.64998 2.30998 1.3125 3.0075 1.3125 3.75C1.3125 5.0925 2.4075 6.1875 3.75 6.1875C4.335 6.1875 4.90502 5.97 5.36252 5.58C5.88752 5.115 6.1875 4.455 6.1875 3.75C6.1875 2.4075 5.0925 1.3125 3.75 1.3125Z"
              fill="#483A9D"
            />
            <path
              d="M2.99992 5.0625C2.81242 5.0625 2.62491 4.96501 2.51991 4.79251C2.36241 4.52251 2.44492 4.17749 2.71492 4.01999L3.38243 3.62251V2.8125C3.38243 2.505 3.63743 2.25 3.94493 2.25C4.25243 2.25 4.50743 2.505 4.50743 2.8125V3.9375C4.50743 4.1325 4.40244 4.32001 4.23744 4.41751L3.29994 4.98001C3.19494 5.04001 3.09742 5.0625 2.99992 5.0625Z"
              fill="#483A9D"
            />
          </svg>
          <span class="ml-1 mt-1 purs_date">{{
            order.purchasedate | moment
          }}</span>
        </div>
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.91983 17.0325C7.10983 17.0325 6.29233 16.8 5.66233 16.3275L2.45233 13.9275C1.60483 13.2975 0.944824 11.97 0.944824 10.905V5.34755C0.944824 4.20004 1.78483 2.97754 2.86483 2.57254L6.59983 1.17004C7.34233 0.892539 8.50483 0.892539 9.24733 1.17004L12.9748 2.57254C13.8523 2.90254 14.5948 3.78004 14.8348 4.75504C14.8723 4.92004 14.8348 5.10005 14.7298 5.23505C14.6248 5.37005 14.4598 5.45253 14.2873 5.45253L8.35483 5.46004C7.40233 5.52004 7.16983 5.67755 7.16983 7.06505V11.1375C7.16983 12.57 7.35733 12.75 8.81233 12.75H13.2523C13.4848 12.75 13.6948 12.8926 13.7773 13.1101C13.8598 13.3275 13.7998 13.575 13.6273 13.7325C13.5448 13.8 13.4773 13.8675 13.3948 13.9275L10.1848 16.335C9.53983 16.8 8.72983 17.0325 7.91983 17.0325ZM7.91983 2.09255C7.58233 2.09255 7.23733 2.13755 6.98983 2.23505L3.25483 3.63755C2.62483 3.87755 2.06233 4.68004 2.06233 5.36254V10.92C2.06233 11.625 2.55733 12.615 3.11983 13.035L6.32983 15.435C7.19233 16.0725 8.63983 16.0725 9.50233 15.435L11.5798 13.8825H8.80483C6.73483 13.8825 6.03733 13.1925 6.03733 11.145V7.07254C6.03733 5.47504 6.41983 4.46255 8.31733 4.34255L8.80483 4.33504H13.4173C13.1923 4.01254 12.8923 3.75755 12.5773 3.63755L8.84983 2.23505C8.59483 2.13755 8.25733 2.09255 7.91983 2.09255Z"
            fill="#483A9D"
          />
          <path
            d="M16.4996 8.97754H6.59961C6.29211 8.97754 6.03711 8.72254 6.03711 8.41504C6.03711 8.10754 6.29211 7.85254 6.59961 7.85254H16.4996C16.8071 7.85254 17.0621 8.10754 17.0621 8.41504C17.0621 8.72254 16.8071 8.97754 16.4996 8.97754Z"
            fill="#483A9D"
          />
          <path
            d="M14.2946 13.8751H8.80461C6.73461 13.8751 6.03711 13.1851 6.03711 11.1376V7.06516C6.03711 5.46765 6.4196 4.45514 8.3171 4.33514L8.80461 4.32764H14.2946C16.3646 4.32764 17.0621 5.01766 17.0621 7.06516V11.2276C17.0471 13.2076 16.3496 13.8751 14.2946 13.8751ZM8.80461 5.45264L8.34711 5.46014C7.39461 5.52014 7.16211 5.67765 7.16211 7.06516V11.1376C7.16211 12.5701 7.34961 12.7501 8.80461 12.7501H14.2946C15.7271 12.7501 15.9221 12.5626 15.9371 11.2276V7.07264C15.9371 5.64014 15.7496 5.46014 14.2946 5.46014H8.80461V5.45264Z"
            fill="#483A9D"
          />
          <path
            d="M9.48742 12.0073H8.48242C8.17492 12.0073 7.91992 11.7523 7.91992 11.4448C7.91992 11.1373 8.17492 10.8823 8.48242 10.8823H9.48742C9.79492 10.8823 10.0499 11.1373 10.0499 11.4448C10.0499 11.7523 9.80242 12.0073 9.48742 12.0073Z"
            fill="#483A9D"
          />
          <path
            d="M13.515 12.0073H11.0625C10.755 12.0073 10.5 11.7523 10.5 11.4448C10.5 11.1373 10.755 10.8823 11.0625 10.8823H13.515C13.8225 10.8823 14.0775 11.1373 14.0775 11.4448C14.0775 11.7523 13.83 12.0073 13.515 12.0073Z"
            fill="#483A9D"
          />
        </svg>
      </div>
      <div class="d-flex ">
        <span class="final_amount">Final ammount : </span>
        <span class="amount_tot ml-1">{{ order.total }}dt</span>
      </div>
      <div class="d-block items_section">
        <div></div>
        <h5>5 product</h5>
        <div class="d-flex justify-space-between">
          <hooper style="height: 45px ; width: 60%">
            <slide v-for="items in order.items">
              <div v-for="img in items.image">
                <img v-if="img" :src="img" class="avatar" />
                <img
                  v-if="!img"
                  src="https://cdn-icons-png.flaticon.com/512/12/12931.png"
                  class="avatar1"
                />
              </div>
              <!--                                <img v-if="img.image" :src="img.image" :alt="img.sku" class="avatar">
                                <img v-if="!img.image" src="https://cdn-icons-png.flaticon.com/512/12/12931.png" :alt="img.sku" class="avatar1">
                           -->
            </slide>
            <hooper-navigation slot="hooper-addons"></hooper-navigation>
          </hooper>
          <div class="d-flex btn_orders">
            <svg
              width="45"
              height="45"
              viewBox="0 0 45 45"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="45" height="45" rx="8" fill="#EDECF6" />
              <path
                d="M23 33.75C22.22 33.75 21.46 33.35 20.94 32.65L19.93 31.3C19.72 31.02 19.44 30.86 19.14 30.84C18.84 30.83 18.54 30.96 18.3 31.21L17.73 30.7L18.28 31.21C16.84 32.75 15.73 32.63 15.2 32.42C14.66 32.21 13.75 31.52 13.75 29.3V18.04C13.75 13.6 15.03 12.25 19.22 12.25H26.78C30.97 12.25 32.25 13.6 32.25 18.04V29.3C32.25 31.51 31.34 32.2 30.8 32.42C30.27 32.63 29.17 32.75 27.72 31.21C27.48 30.95 27.18 30.81 26.87 30.84C26.57 30.86 26.28 31.02 26.07 31.3L25.06 32.65C24.54 33.35 23.78 33.75 23 33.75ZM19.08 29.33C19.12 29.33 19.17 29.33 19.21 29.33C19.95 29.37 20.65 29.76 21.12 30.39L22.13 31.74C22.62 32.39 23.37 32.39 23.86 31.74L24.87 30.39C25.35 29.76 26.04 29.37 26.79 29.33C27.53 29.29 28.27 29.6 28.81 30.18C29.57 30.99 30.06 31.09 30.24 31.02C30.48 30.92 30.74 30.34 30.74 29.3V18.04C30.74 14.43 30.11 13.75 26.77 13.75H19.22C15.88 13.75 15.25 14.43 15.25 18.04V29.3C15.25 30.35 15.51 30.93 15.75 31.02C15.92 31.09 16.42 30.99 17.18 30.18C17.72 29.63 18.39 29.33 19.08 29.33Z"
                fill="#483A9D"
              />
              <path
                d="M27.23 22.75H21.73C21.32 22.75 20.98 22.41 20.98 22C20.98 21.59 21.32 21.25 21.73 21.25H27.23C27.64 21.25 27.98 21.59 27.98 22C27.98 22.41 27.64 22.75 27.23 22.75Z"
                fill="#483A9D"
              />
              <path
                d="M27.23 18.75H21.73C21.32 18.75 20.98 18.41 20.98 18C20.98 17.59 21.32 17.25 21.73 17.25H27.23C27.64 17.25 27.98 17.59 27.98 18C27.98 18.41 27.64 18.75 27.23 18.75Z"
                fill="#483A9D"
              />
              <path
                d="M18.7798 19C18.2298 19 17.7798 18.55 17.7798 18C17.7798 17.45 18.2298 17 18.7798 17C19.3298 17 19.7798 17.45 19.7798 18C19.7798 18.55 19.3298 19 18.7798 19Z"
                fill="#483A9D"
              />
              <path
                d="M18.7798 23C18.2298 23 17.7798 22.55 17.7798 22C17.7798 21.45 18.2298 21 18.7798 21C19.3298 21 19.7798 21.45 19.7798 22C19.7798 22.55 19.3298 23 18.7798 23Z"
                fill="#483A9D"
              />
            </svg>
            <svg
              class="ml-2"
              width="45"
              height="45"
              viewBox="0 0 45 45"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="45" height="45" rx="8" fill="#483A9D" />
              <path
                d="M26.0002 33.75C25.7302 33.75 25.4802 33.6 25.3502 33.37C25.2202 33.14 25.2202 32.85 25.3602 32.62L26.4102 30.87C26.6202 30.51 27.0802 30.4 27.4402 30.61C27.8002 30.82 27.9102 31.28 27.7002 31.64L27.4302 32.09C30.1902 31.44 32.2602 28.96 32.2602 26C32.2602 25.59 32.6002 25.25 33.0102 25.25C33.4202 25.25 33.7602 25.59 33.7602 26C33.7502 30.27 30.2702 33.75 26.0002 33.75Z"
                fill="white"
              />
              <path
                d="M13 20.75C12.59 20.75 12.25 20.41 12.25 20C12.25 15.73 15.73 12.25 20 12.25C20.27 12.25 20.52 12.4 20.65 12.63C20.78 12.86 20.78 13.15 20.64 13.38L19.59 15.13C19.38 15.49 18.92 15.6 18.56 15.39C18.2 15.18 18.09 14.72 18.3 14.36L18.57 13.91C15.81 14.56 13.74 17.04 13.74 20C13.75 20.41 13.41 20.75 13 20.75Z"
                fill="white"
              />
              <path
                d="M28.6799 18.4996C28.5499 18.4996 28.4199 18.4697 28.2999 18.3997L24.3299 16.0996C23.9699 15.8896 23.8499 15.4296 24.0599 15.0696C24.2699 14.7096 24.7299 14.5896 25.0799 14.7996L28.6799 16.8796L32.2499 14.8096C32.6099 14.5996 33.0699 14.7296 33.2699 15.0796C33.4799 15.4396 33.3499 15.8996 32.9999 16.1096L29.0499 18.3896C28.9399 18.4596 28.8099 18.4996 28.6799 18.4996Z"
                fill="white"
              />
              <path
                d="M28.6802 22.5698C28.2702 22.5698 27.9302 22.2298 27.9302 21.8198V17.7397C27.9302 17.3297 28.2702 16.9897 28.6802 16.9897C29.0902 16.9897 29.4302 17.3297 29.4302 17.7397V21.8198C29.4302 22.2398 29.0902 22.5698 28.6802 22.5698Z"
                fill="white"
              />
              <path
                d="M28.6799 22.7502C28.2199 22.7502 27.7499 22.6502 27.3799 22.4402L24.9799 21.1102C24.1999 20.6802 23.6099 19.6702 23.6099 18.7802V16.2402C23.6099 15.3402 24.1999 14.3402 24.9899 13.9002L27.3899 12.5702C28.1299 12.1602 29.2399 12.1602 29.9899 12.5702L32.3899 13.9002C33.1699 14.3302 33.7599 15.3402 33.7599 16.2302V18.7702C33.7599 19.6702 33.1699 20.6702 32.3899 21.1002L29.9899 22.4302C29.5999 22.6502 29.1399 22.7502 28.6799 22.7502ZM28.1099 13.8702L25.7099 15.2002C25.4099 15.3702 25.1099 15.8802 25.1099 16.2202V18.7602C25.1099 19.1102 25.4099 19.6202 25.7099 19.7802L28.1099 21.1202C28.3999 21.2802 28.9599 21.2802 29.2499 21.1202L31.6499 19.7902C31.9499 19.6202 32.2499 19.1102 32.2499 18.7702V16.2302C32.2499 15.8802 31.9499 15.3702 31.6499 15.2102L29.2499 13.8802C28.9599 13.7102 28.3899 13.7102 28.1099 13.8702Z"
                fill="white"
              />
              <path
                d="M17.32 29.4996C17.19 29.4996 17.06 29.4697 16.94 29.3997L12.97 27.0996C12.61 26.8896 12.49 26.4296 12.7 26.0696C12.91 25.7096 13.37 25.5896 13.72 25.7996L17.32 27.8796L20.89 25.8096C21.25 25.5996 21.71 25.7296 21.91 26.0796C22.12 26.4396 21.99 26.8996 21.64 27.1096L17.69 29.3896C17.58 29.4596 17.45 29.4996 17.32 29.4996Z"
                fill="white"
              />
              <path
                d="M17.3198 33.5698C16.9098 33.5698 16.5698 33.2298 16.5698 32.8198V28.7397C16.5698 28.3297 16.9098 27.9897 17.3198 27.9897C17.7298 27.9897 18.0698 28.3297 18.0698 28.7397V32.8198C18.0698 33.2398 17.7398 33.5698 17.3198 33.5698Z"
                fill="white"
              />
              <path
                d="M17.32 33.7499C16.86 33.7499 16.39 33.6499 16.02 33.4399L13.62 32.1099C12.84 31.6799 12.25 30.67 12.25 29.78V27.2399C12.25 26.3399 12.84 25.3399 13.62 24.9099L16.02 23.58C16.76 23.17 17.88 23.17 18.62 23.58L21.02 24.9099C21.8 25.3399 22.39 26.3499 22.39 27.2399V29.78C22.39 30.68 21.8 31.6799 21.01 32.1199L18.61 33.45C18.25 33.65 17.79 33.7499 17.32 33.7499ZM16.75 24.8699L14.35 26.2C14.05 26.37 13.75 26.8799 13.75 27.2199V29.76C13.75 30.11 14.05 30.62 14.35 30.78L16.75 32.1099C17.04 32.2699 17.6 32.2699 17.89 32.1099L20.29 30.78C20.59 30.61 20.89 30.1 20.89 29.76V27.2199C20.89 26.8699 20.59 26.36 20.29 26.2L17.89 24.8599C17.61 24.7099 17.04 24.7099 16.75 24.8699Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5 text-center" v-if="filteredList.length === 0">
      <span class="empty_search"> There is no order with this reference.</span>
    </div>

  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper';
import 'hooper/dist/hooper.css';
export default {
  name: 'OrdersBlock',
  components: {
    moment: moment,
    Hooper,
    Slide,
    HooperNavigation,
  },
  props: {
    customerEmail: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      listItems: [],
      listOrder: [],
      listOrderIds: [],
      ItemsPerOrder: [],
      ImgPerOrder: [],
      search: '',
      OrdersNumber: 5,
      hooperSettings: {
        itemsToShow: 2,
        centerMode: true,
        infiniteScroll: false,
      },
      loading: true,
      skusImg: [],
    };
  },
  filters: {
    moment: function(date) {
      return moment(date).format('YYYY-MM-DD');
    },
  },
  methods: {
    async getOperationsData() {
      let custEmail = this.customerEmail;
      await axios
        .get(
          `https://convergence-app-logic.k8s.satoripop.io/order/getorders?email=${custEmail}`,
          {
            params: this.axiosParams,
          }
        )
        .then(response => {
          this.listItems = response.data;
          for (let i = 0; i < this.listItems.length; i++) {
            this.listOrder.push(this.listItems[i]);
          }
        });

      console.warn(this.filteredList);
    },
    async getPosts() {
      await axios
        .post(
          'https://convergence-app-logic.k8s.satoripop.io/order/getitemsimages',
          {
            skus: [
              '6192001605224',
              '8718951228399',
              '11010100010',
              '20202020',
              '11010100010',
            ],
          },
          {
            headers: {
              'Content-type': 'application/json',
            },
          }
        )
        .then(response => {
          this.ImgPerOrder = response.data.imageDataList;
        })
        .catch(error => {
          console.log('errorr: ' + error);
        });
    },
  },
  computed: {
    axiosParams() {
      const params = new URLSearchParams();
      params.append('currentpage', '0');
      params.append('pagesize', '20');
      return params;
    },
    filteredList() {
      return this.listOrder.filter(order => {
        return order.reference
          .toLowerCase()
          .includes(this.search.toLowerCase());
      });
    },
  },
  mounted() {
    this.getOperationsData();
    this.getPosts();
  },
};
</script>

<style scoped lang="scss">
.order_btn {
  background: var(--cv-primary);
  border-radius: 8px;
  width: 100%;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  padding: 12px 0;
}

.orders_section {
  background-color: white !important;
  padding: 12px;
  //padding: 0 8px;
  h4 {
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    line-height: 22px !important;
    color: #656877 !important;
    margin-top: 5px;
  }
  .order_box {
    background: #ffffff;
    border: 0.5px solid #a49dcf;
    border-radius: 8px;
    padding: 8px;
    .header {
      margin-bottom: 1rem;
      //padding: 0 6px;
      .ref_name {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #656877;
        margin-top: 8px;
      }
      .order_status {
        padding: 5px;
        background: #fffbf4;
        border: 1px solid #f8ab26;
        border-radius: 4px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #f8ab26;
        text-transform: capitalize;
      }
    }
    .final_amount {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #656877;
    }
    .amount_tot {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 17px;
      color: #ff2c52;
      margin-top: -2px;
    }
  }

  .items_section {
    h5 {
      font-family: 'Inter' !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 10px !important;
      line-height: 12px !important;
      color: #656877 !important;
    }
  }
}

.search_block {
  width: 100% !important;
}

.purs_date {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  color: #483a9d;
}

.empty_search {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #292d32;
  text-align: center;
  margin-top: 1.5rem;
}

.avatar {
  vertical-align: middle;
  width: 40px;
  height: 40px;
  border-radius: 10px;
}

.avatar1 {
  vertical-align: middle;
  width: 35px;
  height: 35px;
  border-radius: 10px;
}

.hooper-slide {
  margin-right: 20px !important;
  background: #ffffff !important;
  border: 0.5px solid #a49dcf !important;
  border-radius: 8px !important;
  width: fit-content !important;
}

.hooper-navigation {
  .hooper-slide {
    .is-disabled {
      display: none !important;
    }
  }
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #b7b7b7;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-bottom: 4px solid var(--cv-primary);
  border-left: 4px solid transparent;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
