/* global axios */
import ApiClient from './ApiClient';

class NEWCSATReportAPI extends ApiClient {
  constructor() {
    super('new_csat_surveys', { accountScoped: true });
  }

  get({ page, from, to, workflow, inbox_id, team_id, rating } = {}) {
    /* to replace the specific part of the url */
    let url = this.url.replace(/\/api\/v1\/accounts\/\d+\//, "/api/v1/");
    return axios.get(`${url}`, {
      params: {
        page,
        since: from,
        until: to,
        sort: '-created_at',
        workflow,
        inbox_id,
        rating,
      },
    });
  }

  download({ from, to, workflow, inbox_id, team_id, rating } = {}) {
    /* to replace the specific part of the url */
    let url = this.url.replace(/\/api\/v1\/accounts\/\d+\//, "/api/v1/");
    return axios.get(`${url}/download`, {
      params: {
        since: from,
        until: to,
        sort: '-created_at',
        workflow,
        inbox_id,
        rating,
      },
    });
  }

  getMetrics({ from, to, workflow, inbox_id, team_id, rating } = {}) {
    /* to replace the specific part of the url */
    let url = this.url.replace(/\/api\/v1\/accounts\/\d+\//, "/api/v1/");
    //no ratings for metrics
    return axios.get(`${url}/metrics`, {
      params: { since: from, until: to, workflow, inbox_id, rating },
    });
  }
}

export default new NEWCSATReportAPI();
