<template>
  <div class="subject-table-container">
    <div>
      <table class="table table-hover mb-4">
        <tbody>
        <tr>
          <th style="width: 22%;">
            <div style="font-size: medium;color: #676777;font-weight: 500;">
              {{ $t('OVERVIEW_REPORTS.SUBJECTS.TABLE_HEADER.STATISTICS') }}
            </div>
            <br>
            <span style="font-size: large; color: black; font-weight: 500;">
              {{ $t('OVERVIEW_REPORTS.SUBJECTS.TABLE_HEADER.NUMBER') }}
            </span>
          </th>
          <th style="width: 74%;">  </th>
          <th>
            <div style="font-size: medium;color: #676777;font-weight: 500;">
              {{ $t('OVERVIEW_REPORTS.SUBJECTS.TABLE_HEADER.TOTAL') }}:
            </div>
            <br>
             <span style="font-size: large; color: #5a4da5; font-weight: 500;">
               {{ abandonedMsgTotal }}
             </span>
          </th>
        </tr>
        <tr v-if="subject.label" v-for="(subject, index) in subjectList" :key="subject.id">
          <td class="d-flex">
            <span class="ml-3 mt-2" style="color: rgb(103, 103, 119); font-weight: 500;">
              {{ subject.label }}
            </span>
          </td>
          <td>
            <woot-horizontal-bar :collection="chartData(subject)" :from-subject="true" :subjects-width="subjectsWidth" :style="myStyles" />
          </td>
          <td v-if="subject.label">
            <span v-if="subject.id === 2" style="color: rgb(90, 77, 165);font-weight: bolder;">
              {{ abandonedClaimService }}
            </span>
            <span v-else-if="subject.id === 3" style="color: rgb(90, 77, 165);font-weight: bolder;">
              {{ abandonedClaimSinister }}
            </span>
            <span v-else-if="subject.id === 4" style="color: rgb(90, 77, 165);font-weight: bolder;">
              {{ abandonedContractInfoCount }}
            </span>
            <span v-else-if="subject.id === 5" style="color: rgb(90, 77, 165);font-weight: bolder;">
              {{ abandonedClosestAgency }}
            </span>
            <span v-if="subject.id === 2" style="font-weight: 500;">
              /{{claimServiceTotal}}
            </span>
            <span v-else-if="subject.id === 3" style="font-weight: 500;">
              /{{claimSinisterTotal}}
            </span>
            <span v-else-if="subject.id === 4" style="font-weight: 500;">
              /{{contractInfoTotal}}
            </span>
            <span v-else-if="subject.id === 5" style="font-weight: 500;">
              /{{closestAgencyTotal}}
            </span>
          </td>

        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { VeTable, VePagination } from 'vue-easytable';
import Spinner from 'shared/components/Spinner.vue';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import rtlMixin from 'shared/mixins/rtlMixin';
import ConversationApi from '../../../../../../../dashboard/api/inbox/conversation';
import Vue from "vue";
import { CSAT_SUBJECTS, CSAT_SUBJECTS_FR } from '../../../../../../../shared/constants/messages'

export default {
  name: 'SubjectsTable',
  components: {
    EmptyState,
    Spinner,
    VeTable,
    VePagination,
  },
  mixins: [rtlMixin],
  data() {
    return {
      currentPage: 1,
      resolvedConversations: {},
      abandonedContractInfoCount: 0,
      terminatedContractInfoCount: 0,
      contractInfoTotal: 0,
      abandonedClaimService: 0,
      terminatedClaimService: 0,
      claimServiceTotal: 0,
      abandonedClaimSinister: 0,
      terminatedClaimSinister: 0,
      claimSinisterTotal: 0,
      abandonedClosestAgency: 0,
      terminatedClosestAgency: 0,
      closestAgencyTotal: 0,
      abandonedMsgTotal: 0,
    }
  },
  mounted() {
    this.getAbandonedMsgCount() ;
  },
  computed: {
    /* to return list of subjects by language */
    subjectList() {
      if (this.$root.$i18n.locale === 'fr') {
        return CSAT_SUBJECTS_FR ;
      }
      else {
        return CSAT_SUBJECTS;
      }
    },
    /* to return the bar width of each subject */
    subjectsWidth() {
      return [
        {
          id: 2,
          width: this.getSubjectWidth(2),
        },
        {
          id: 3,
          width: this.getSubjectWidth(3),
        },
        {
          id: 4,
          width: this.getSubjectWidth(4),
        },
        {
          id: 5,
          width: this.getSubjectWidth(5),
        }
      ]
    },
    myStyles() {
      return {
        height: `38px`,
        position: 'relative'
      }
    },
  },
  methods: {
    /* to return the abandoned messages of each subject */
    async getAbandonedMsgCount() {
      const { appLogicURL } = window.convergenceConfig;
      const response = await axios.get(`${appLogicURL}/gat/getStatistic`);
      this.abandonedContractInfoCount = response.data.nb_information_contrat_abandonne;
      this.terminatedContractInfoCount = response.data.nb_information_contrat_termine;
      this.contractInfoTotal = this.abandonedContractInfoCount + this.terminatedContractInfoCount ;
      this.abandonedClaimService = response.data.nb_reclamation_service_abandonne;
      this.terminatedClaimService = response.data.nb_reclamation_service_termine;
      this.claimServiceTotal = this.abandonedClaimService + this.terminatedClaimService;
      this.abandonedClaimSinister = response.data.nb_reclamation_sinistre_abandonne;
      this.terminatedClaimSinister = response.data.nb_reclamation_sinistre_termine;
      this.claimSinisterTotal = this.abandonedClaimSinister + this.terminatedClaimSinister;
      this.abandonedClosestAgency = response.data.nb_agence_proche_abandonne;
      this.terminatedClosestAgency = response.data.nb_agence_proche_termine;
      this.closestAgencyTotal = this.abandonedClosestAgency + this.terminatedClosestAgency;
      this.abandonedMsgTotal = this.abandonedContractInfoCount + this.abandonedClaimService + this.abandonedClaimSinister + this.abandonedClosestAgency ;
    },
    chartData(subject) {
      return {
        labels: ['Subjects'],
        datasets: [{
          label: subject.label,
          data: this.getSubjectPercentage(subject.id),
          backgroundColor: '#493a9c',
          height: '38px !important',
        }],
      };
    },
    /* to return the percentage of the abandoned messages for each subject */
    getSubjectPercentage(id) {
      if (id === 2) {
        return [this.computeDistribution(this.abandonedClaimService,this.claimServiceTotal)]
      }
      else if (id === 3) {
        return [this.computeDistribution(this.abandonedClaimSinister,this.claimSinisterTotal)]
      }
      else if (id === 4) {
        return [this.computeDistribution(this.abandonedContractInfoCount,this.contractInfoTotal)]
      }
      else if (id === 5) {
        return [this.computeDistribution(this.abandonedClosestAgency,this.closestAgencyTotal)]
      }
    },
    computeDistribution (value, total) {
      return ((value * 100) / total).toFixed(2);
    },
    getSubjectWidth(id) {
      let subjectPercentage = this.getSubjectPercentage(id);
      let subjectWidth = ((subjectPercentage * 74) / 100).toFixed(2) ;
      return subjectWidth ;
    },
  },
};
</script>

<style lang="scss" scoped>
.subject-table-container {
  display: flex;
  flex-direction: column;
  flex: 1;

  .ve-table {
    &::v-deep {
      th.ve-table-header-th {
        font-size: var(--font-size-mini) !important;
        padding: var(--space-small) var(--space-two) !important;
      }

      td.ve-table-body-td {
        padding: var(--space-one) var(--space-two) !important;
      }
    }
  }

  &::v-deep .ve-pagination {
    background-color: transparent;
  }

  &::v-deep .ve-pagination-select {
    display: none;
  }

  .row-user-block {
    align-items: center;
    display: flex;
    text-align: left;

    .user-block {
      display: flex;
      flex-direction: column;
      min-width: 0;
      .title {
        font-size: var(--font-size-small);
        margin: var(--zero);
        line-height: 1;
      }
      .sub-title {
        font-size: var(--font-size-mini);
      }
    }

    /*.user-thumbnail-box {
      margin-right: var(--space-small);
    }*/
  }

  .table-pagination {
    margin-top: var(--space-normal);
    text-align: right;
  }
}

.agents-loader {
  align-items: center;
  display: flex;
  font-size: var(--font-size-default);
  justify-content: center;
  padding: var(--space-large);
}

</style>
