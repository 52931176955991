<template>
  <div class="column content-box">
    <div class="row">
      <div class="columns">
        <router-link
            :to="addAccountScoping('settings/macros/new')"
            class="button primary float-right mt-2 mb-5"
            >
             <fluent-icon icon="add-circle" />
              <span class="button__content">
                {{ $t('MACROS.HEADER_BTN_TXT') }}
              </span>
        </router-link>
        <div
          v-if="!uiFlags.isFetching && !records.length"
          class="macros__empty-state mt-5"
        >
          <p class="no-items-error-message">
            {{ $t('MACROS.LIST.404') }}
          </p>
        </div>
        <woot-loading-state
          v-if="uiFlags.isFetching"
          :message="$t('MACROS.LOADING')"
        />
        <table v-if="!uiFlags.isFetching && records.length" class="table table-hover mb-4">
          <thead>
            <th
              v-for="thHeader in $t('MACROS.LIST.TABLE_HEADER')"
              :key="thHeader"
            >
              {{ thHeader }}
            </th>
          </thead>
          <tbody>
            <macros-table-row
              v-for="(macro, index) in currentRecords"
              :key="index"
              :macro="macro"
              @delete="openDeletePopup(macro, index)"
            />
          </tbody>
        </table>
      </div>
    </div>
    <woot-delete-modal
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('LABEL_MGMT.DELETE.CONFIRM.TITLE')"
      :message="$t('MACROS.DELETE.CONFIRM.MESSAGE')"
      :message-value="deleteMessage"
      :confirm-text="$t('MACROS.DELETE.CONFIRM.YES')"
      :reject-text="$t('MACROS.DELETE.CONFIRM.NO')"
    />
    <table-footer
        :current-page="currentPage"
        :total-count="total === 0 ? records.length : total"
        :page-size="size"
         @page-change="onPageChange"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import accountMixin from 'dashboard/mixins/account.js';
import MacrosTableRow from './MacrosTableRow';
import TableFooter from 'dashboard/components/widgets/TableFooter';
export default {
  components: {
    MacrosTableRow,
    TableFooter,
  },
  mixins: [alertMixin, accountMixin],
  data() {
    return {
      showDeleteConfirmationPopup: false,
      selectedResponse: {},
      loading: {},
      size: 6,
      currentPage: 1,
      total: 0,
    };
  },
  computed: {
    ...mapGetters({
      records: 'macros/getMacros',
      uiFlags: 'macros/getUIFlags',
    }),
    deleteMessage() {
      return ` ${this.selectedResponse.name}?`;
    },
    currentRecords() {
      return this.records.slice(
          (this.currentPage - 1) * this.size,
          ((this.currentPage - 1) * this.size) + this.size
      )
    }
  },
  mounted() {
    this.$store.dispatch('macros/get');
  },
  methods: {
    openDeletePopup(response) {
      this.showDeleteConfirmationPopup = true;
      this.selectedResponse = response;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    confirmDeletion() {
      this.loading[this.selectedResponse.id] = true;
      this.closeDeletePopup();
      this.deleteMacro(this.selectedResponse.id);
    },
    async deleteMacro(id) {
      try {
        await this.$store.dispatch('macros/delete', id);
        this.onChangeTotal();
        this.showAlert(this.$t('MACROS.DELETE.API.SUCCESS_MESSAGE'));
        this.loading[this.selectedResponse.id] = false;
      } catch (error) {
        this.showAlert(this.$t('MACROS.DELETE.API.ERROR_MESSAGE'));
      }
    },
    onPageChange(page) {
        this.currentPage = page;
    },
    onChangeTotal() {
        const prevCount = this.total;
        const macros = this.$store.getters['macros/getMacros'];
        this.total = macros.length ;
        /* return to the previous page when the last element of the current page is deleted */
        if (this.total % 6 === 0) {
            this.currentPage = this.currentPage - 1 ;
        }
    },
  },
};
</script>

<style scoped>
.macros__empty-state {
  padding: var(--space-slab);
}
</style>
