<template>
  <div class="column" style="padding: 0 13rem; background-color: white;">
    <woot-modal-header
      class="modal_header"
      :header-title="$t('AUTOMATION.ADD.TITLE')"
    />
    <div class="row modal-content">
      <div class="columns">
        <div>
            <label :class="{ error: $v.automation.name.$error }"
                   class="w-100"
            >
                <input
                  v-model="automation.name"
                  type="text"
                  :placeholder="$t('AUTOMATION.ADD.FORM.NAME.PLACEHOLDER')"
                  class="custom_input"
                  @input="$v.automation.name.$touch"
                  :class="{ error: $v.automation.name.$error }"
                  maxlength="50"
                />
                <span v-if="$v.automation.name.$error" class="message">
                  {{ $t('AUTOMATION.ADD.FORM.NAME.ERROR') }}
                </span>
            </label>
        </div>

        <div>
            <label class="w-100" :class="{ error: $v.automation.description.$error }">
                <textarea
                  v-model="automation.description"
                  :placeholder="$t('AUTOMATION.ADD.FORM.DESC.PLACEHOLDER')"
                  class="custom_input"
                  @input="$v.automation.description.$touch"
                  :class="{ error: $v.automation.description.$error }"
                  maxlength="200"
                />
                <span v-if="$v.automation.description.$error" class="message">
                  {{ $t('AUTOMATION.ADD.FORM.DESC.ERROR') }}
                </span>
            </label>
        </div>
        <div class="event_wrapper">
          <label>
<!--            {{ $t('AUTOMATION.ADD.FORM.EVENT.LABEL') }}-->
            <multiselect-dropdown
              ref="dropdownComponent"
              :options="automationRuleEvents"
              :selected-item="automation.event_name"
              :has-thumbnail="false"
              :event-name="true"
              :close="closeDropdown"
              @click="onEventSelect"
              @close="onChangeStatus"
            />
<!--            <select v-model="automation.event_name" @change="onEventChange()">
              <option
                v-for="event in automationRuleEvents"
                :key="event.key"
                :value="event.key"
              >
                {{ event.value }}
              </option>
            </select>-->
<!--            <span v-if="$v.automation.event_name.key.$error" class="message">
              {{ $t('AUTOMATION.ADD.FORM.EVENT.ERROR') }}
            </span>-->
          </label>
          <p v-if="hasAutomationMutated" class="info-message">
            {{ $t('AUTOMATION.FORM.RESET_MESSAGE') }}
          </p>
        </div>
        <!-- // Conditions Start -->
        <section>
          <label>
            {{ $t('AUTOMATION.ADD.FORM.CONDITIONS.LABEL') }}
          </label>
          <div class="medium-12 columns filters-wrap">
            <filter-input-box
              v-for="(condition, i) in automation.conditions"
              :key="i"
              :index="i"
              v-model="automation.conditions[i]"
              :filter-attributes="getAttributes(automation.event_name.key)"
              :input-type="getInputType(automation.conditions[i].attribute_key)"
              :operators="getOperators(automation.conditions[i].attribute_key)"
              :dropdown-values="
                getConditionDropdownValues(
                  automation.conditions[i].attribute_key, language
                )
              "
              :show-query-operator="i !== automation.conditions.length - 1"
              :custom-attribute-type="
                getCustomAttributeType(automation.conditions[i].attribute_key)
              "
              :v="$v.automation.conditions.$each[i]"
              @resetFilter="resetFilter(i, automation.conditions[i])"
              @removeFilter="removeFilter(i)"
              @multiOpen="onCloseDropdown"
              @multiSelectOpen="onMultiOpen(i)"
            />
            <div class="filter-actions">
              <woot-button
                icon="add"
                color-scheme="primary"
                variant="smooth"
                size="small"
                @click="appendNewCondition"
              >
                {{ $t('AUTOMATION.ADD.CONDITION_BUTTON_LABEL') }}
              </woot-button>
            </div>
          </div>
        </section>
        <!-- // Conditions End -->
        <!-- // Actions Start -->
        <section>
          <label>
            {{ $t('AUTOMATION.ADD.FORM.ACTIONS.LABEL') }}
          </label>
          <div class="medium-12 columns filters-wrap">
            <automation-action-input
              v-for="(action, i) in automation.actions"
              :key="i"
              :index="i"
              v-model="automation.actions[i]"
              :action-types="automationActionTypes"
              :dropdown-values="
                getActionDropdownValues(automation.actions[i].action_name)
              "
              :show-action-input="
                showActionInput(automation.actions[i].action_name)
              "
              :v="$v.automation.actions.$each[i]"
              @resetAction="resetAction(i)"
              @removeAction="removeAction(i)"
              @multiOpen="onCloseDropdown"
              @multiSelectOpen="onMultiOpen(i)"
            />
            <div class="filter-actions">
              <woot-button
                icon="add"
                color-scheme="primary"
                variant="smooth"
                size="small"
                @click="appendNewAction"
              >
                {{ $t('AUTOMATION.ADD.ACTION_BUTTON_LABEL') }}
              </woot-button>
            </div>
          </div>
          <div class="d-flex float-right" style="margin-bottom: -2rem;">
            <woot-button class="button clear" @click.prevent="onClose">
              {{ $t('AUTOMATION.ADD.CANCEL_BUTTON_TEXT') }}
            </woot-button>
            <woot-button class="ml-2" @click="submitAutomation" :disabled="$v.$invalid">
              {{ $t('AUTOMATION.ADD.SUBMIT') }}
            </woot-button>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import automationMethodsMixin from 'dashboard/mixins/automations/methodsMixin';
import automationValidationsMixin from 'dashboard/mixins/automations/validationsMixin';
import filterInputBox from 'dashboard/components/widgets/FilterInput/Index.vue';
import automationActionInput from 'dashboard/components/widgets/AutomationActionInput.vue';
import MultiselectDropdown from 'shared/components/ui/MultiselectDropdown.vue'  ;

import {
    AUTOMATION_RULE_EVENTS,
    AUTOMATION_ACTION_TYPES,
    AUTOMATIONS,
    updateOptionForAutomationAction,
    updateOptionForAutomationEvent,
} from './constants';
export default {
  components: {
    filterInputBox,
    automationActionInput,
    MultiselectDropdown,
  },
  mixins: [alertMixin, automationMethodsMixin, automationValidationsMixin],
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      automationTypes: JSON.parse(JSON.stringify(AUTOMATIONS)),
      automationRuleEvent: AUTOMATION_RULE_EVENTS[0].key,
      automationRuleEvents: AUTOMATION_RULE_EVENTS,
      automationActionTypes: AUTOMATION_ACTION_TYPES,
      automationMutated: false,
      show: true,
      automation: {
        name: null,
        description: null,
        event_name: {
          key: 'conversation_created',
          value: this.$root.$i18n.locale === 'fr' ? 'Conversation créée' : 'Conversation created',
        },
        conditions: [
          {
            attribute_key: 'status',
            filter_operator: 'equal_to',
            values: '',
            query_operator: 'and',
            custom_attribute_type: '',
          },
        ],
        actions: [
          {
            action_name: 'assign_agent',
            action_params: [],
          },
        ],
      },
      showDeleteConfirmationModal: false,
      allCustomAttributes: [],
      mode: 'create',
      close: false,
    };
  },
  computed: {
    hasAutomationMutated() {
      if (
        this.automation.conditions[0].values ||
        this.automation.actions[0].action_params.length
      )
        return true;
      return false;
    },
    language() {
      return this.$root.$i18n.locale ;
    },
    closeDropdown() {
      return this.close;
    }
  },
  mounted() {
    this.$store.dispatch('inboxes/get');
    this.$store.dispatch('agents/get');
    this.$store.dispatch('contacts/get');
    this.$store.dispatch('teams/get');
    this.$store.dispatch('labels/get');
    this.$store.dispatch('campaigns/get');
    this.allCustomAttributes = this.$store.getters['attributes/getAttributes'];
    this.manifestCustomAttributes();
    updateOptionForAutomationAction(this.$root.$i18n.locale);
    updateOptionForAutomationEvent(this.$root.$i18n.locale);
    document.addEventListener('mousedown', this.handleClickOutside);
  },
  methods: {
    handleClickOutside(event) {
      // Check that the reference exists before accessing it
      if (this.$refs.dropdownComponent && this.$refs.dropdownComponent.$refs.dropdownElement) {
        const dropdownElement = this.$refs.dropdownComponent.$refs.dropdownElement;
        if (!dropdownElement.contains(event.target)) {
          this.onCloseDropdown();
        }
      }
    },
    onCloseDropdown() {
      this.close=true;
    },
    onEventSelect(eventName) {
      this.automation.event_name = eventName;
      this.onEventChange();
    },
    onChangeStatus(value) {
      if (value) {
        this.close = false;
      }
    },
    onMultiOpen(openIndex) {
      // Close all multiselects except the one with the index open
      bus.$emit('multi-open', openIndex);
    },
  }
};
</script>
<style lang="scss" scoped>
.filters-wrap {
  padding: var(--space-normal);
  border-radius: var(--border-radius-large);
  border: 1px solid var(--color-border);
  background: var(--color-background-light);
  margin-bottom: var(--space-normal);
}

.filter-actions {
  margin-top: var(--space-normal);
}
.event_wrapper {
  label {
    width: 100%;
  }
  select {
    margin: var(--space-zero);
  }
  .info-message {
    font-size: var(--font-size-mini);
    color: var(--s-500);
    text-align: right;
  }
  margin-bottom: var(--space-medium);
}
.modal_header {
  margin-top: 3rem !important;
  text-align: center;
}

.custom_input {
  height: 40px !important;
  border: 1px solid #a8a8a8 !important;
  border-radius: 8px !important;
  margin-bottom: 1.5rem;
}
.add-btn {
  color: #483a9d !important;
}
</style>
