<template>
  <div class="csat--table-container">
    <div v-if="!csatResponses.length" class="csat--empty-records">
      {{ $t('CSAT_REPORTS.NO_RECORDS') }}
    </div>
    <woot-loading-state
      v-else-if="uiFlags.isFetching"
      :message="$t('CSAT_REPORTS.TABLE.LOADING_MESSAGE')"
    />
    <table v-else class="table table-hover mb-4">
      <tbody>
      <tr>
        <th>{{ $t('CSAT_REPORTS.TABLE.HEADER.CONTACT_NAME') }}</th>
        <th>{{ $t('CSAT_REPORTS.TABLE.HEADER.SUBJECT_NAME') }}</th>
        <th>{{ $t('CSAT_REPORTS.TABLE.HEADER.RATING') }}</th>
        <th>{{ $t('CSAT_REPORTS.TABLE.HEADER.FEEDBACK_TEXT') }}</th>
      </tr>
      <tr v-for="(response, index) in csatResponses" :key="response.id">
        <td class="d-flex">
          <div v-if="csatResponses.length > 0" class="row--user-block" style="margin-top: 6px;">
            <thumbnail
              :src="contactObject[response.contact_id] ? contactObject[response.contact_id].thumbnail : ''"
              size="24px"
              :username="contactObject[response.contact_id] ? contactObject[response.contact_id].name : ''"
              :status="contactObject[response.contact_id] ? contactObject[response.contact_id].availability_status : ''"
            />
            <span v-if="contactObject[response.contact_id]" class="text-block-title text-truncate text-capitalize">
              {{ contactObject[response.contact_id].name }}
            </span>
          </div>
          <!--          <UserAvatarWithName size="24px" :user="getContactObject(response.contact_id)" />-->
        </td>
        <td>
          {{ getSubjectName(response.workflow) }}
        </td>
        <td class="emoji-response">
          {{ getRatingEmoji(response.rating) }}
        </td>
        <td v-if="response.feedback_message !== '' && response.feedback_message !== null">
          {{ response.feedback_message}}
        </td>
        <td v-else>
          ---
        </td>
        <td class="text-right">
          <router-link :to="{name: 'inbox_conversation',params: { conversation_id: response.conversation_id },}">
            #{{response.conversation_id}}
          </router-link>
          <div>
            <time-ago :timestamp="(new Date(response.created_at).getTime()) / 1000" />
          </div>
        </td>

      </tr>
      </tbody>
    </table>
    <div v-if="csatResponses.length > 0" class="table-pagination">
      <table-footer
        :current-page="currentPage"
        :total-count="metrics.totalSentMessagesCount"
        :page-size="25"
        @page-change="onPageNumberChange"
      />
    </div>
  </div>
</template>
<script>
import { VeTable, VePagination } from 'vue-easytable';
import UserAvatarWithName from 'dashboard/components/widgets/UserAvatarWithName';
import { CSAT_RATINGS, CSAT_SUBJECTS, CSAT_SUBJECTS_FR } from 'shared/constants/messages';
import { mapGetters } from 'vuex';
import timeMixin from 'dashboard/mixins/time';
import rtlMixin from 'shared/mixins/rtlMixin';
import Vue from "vue";
import Thumbnail from '../../../../../components/widgets/Thumbnail.vue';
import TableFooter from '../../../../../components/widgets/TableFooter';
import TimeAgo from '../../../../../components/ui/TimeAgo.vue';
import Contact from '../../../../../api/contacts'

export default {
  components: {
    VeTable,
    VePagination,
    Thumbnail,
    TableFooter,
    TimeAgo,
  },
  mixins: [timeMixin, rtlMixin],
  props: {
    pageIndex: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      currentPage: 1,
      contactObject: {},
      subject: {},
      ratingObject: {},
      routerParams: {}
    }
  },
  watch: {
    async csatResponses(newObj, oldObj) {
      if (newObj.length !== oldObj.length) {
        for (const response of this.csatResponses) {
          let val = await this.getContactObject(response.contact_id);
          Vue.set(this.contactObject, response.contact_id, val);
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      uiFlags: 'csat/getUIFlags', /* newCsat/getUIFlags */
      csatResponses: 'csat/getCSATResponses', /* newCsat/getCSATResponses */
      metrics: 'csat/getMetrics', /* newCsat/getMetrics */
      accountId: 'getCurrentAccountId',
    }),
    columns() {
      return [
        {
          field: 'contact',
          key: 'contact',
          title: this.$t('CSAT_REPORTS.TABLE.HEADER.CONTACT_NAME'),
          align: this.isRTLView ? 'right' : 'left',
          width: 200,
          renderBodyCell: ({ row }) => {
            if (row.contact) {
              return <UserAvatarWithName size="24px" user={row.contact} />;
            }
            return '---';
          },
        },
        {
          field: 'subject',
          key: 'subject',
          title: this.$t('CSAT_REPORTS.TABLE.HEADER.SUBJECT_NAME'),
          align: this.isRTLView ? 'right' : 'left',
          width: 200,
          renderBodyCell: ({ row }) => {
            if (row.subject) {
              return (
                <span size="24px" user={row.subject} />
              );
            }
            return '---';
          },
        },
        {
          field: 'rating',
          key: 'rating',
          title: this.$t('CSAT_REPORTS.TABLE.HEADER.RATING'),
          align: 'center',
          width: 80,
          renderBodyCell: ({ row }) => {
            const [ratingObject = {}] = CSAT_RATINGS.filter(
              rating => rating.value === row.rating
            );
            return (
              <span class="emoji-response">{ratingObject.emoji}</span>
            );
          },
        },
        {
          field: 'feedbackText',
          key: 'feedbackText',
          title: this.$t('CSAT_REPORTS.TABLE.HEADER.FEEDBACK_TEXT'),
          align: this.isRTLView ? 'right' : 'left',
          width: 400,
        },
        {
          field: 'conversationId',
          key: 'conversationId',
          title: '',
          align: this.isRTLView ? 'right' : 'left',
          width: 100,
          renderBodyCell: ({ row }) => {
            const routerParams = {
              name: 'inbox_conversation',
              params: { conversation_id: row.conversationId },
            };
            return (
              <div class="text-right">
                <router-link to={routerParams}>
                  {`#${row.conversationId}`}
                </router-link>
                <div class="csat--timestamp" v-tooltip={row.createdAt}>
                  {row.createdAgo}
                </div>
              </div>
            );
          },
        },
      ];
    },
    tableData() {
      return this.csatResponses.map(response => ({
        contact: response.contact_id,
        subject: response.workflow,
        rating: response.rating || '--',
        feedbackText: response.feedback_message || '---',
        conversationId: response.conversation_id,
        /*createdAgo: this.dynamicTime(response.created_at),*/
        /*createdAt: this.messageStamp(response.created_at, 'LLL d yyyy, h:mm a'),*/
      }));
    },
  },
  methods: {
    onPageNumberChange(pageIndex) {
      this.$emit('page-change', pageIndex);
    },
    async getContactObject(id) {
      try {
        /*axios
          .get(`http://localhost:3000/api/v1/accounts/${this.accountId}/contacts/${id}`)*/
        Contact.getContactById(this.accountId, id)
          .then(response => {
            Vue.set(this.contactObject, id, response.data.payload);
          })
      }
      catch (error) {
        throw error ;
      }

    },
    getSubjectName(value) {
      if (this.$root.$i18n.locale === 'fr') {
        const subject = CSAT_SUBJECTS_FR.find(subject => subject.value === value);
        return subject.name ;
      }
      else {
        const subject = CSAT_SUBJECTS.find(subject => subject.value === value);
        return subject.name ;
      }
    },
    getRatingEmoji(value) {
      const rating =  CSAT_RATINGS.find(rating => rating.value === value);
      if (rating !== undefined) {
        return rating.emoji ;
      }
      else {
        return '---'
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.csat--table-container {
  display: flex;
  flex-direction: column;
  flex: 1;

  .ve-table {
    background: var(--white);

    &::v-deep {
      .ve-table-container {
        border-radius: var(--border-radius-normal);
        border: 1px solid var(--color-border) !important;
      }

      .ve-table-sort-icon:active{
        color: #483a9d !important;
      }

      th.ve-table-header-th {
        font-size: var(--font-size-mini) !important;
        padding: var(--space-normal) !important;
      }

      td.ve-table-body-td {
        padding: var(--space-small) var(--space-normal) !important;
      }
    }
  }

  &::v-deep .ve-pagination {
    background-color: transparent;
  }

  &::v-deep .ve-pagination-select {
    display: none;
  }

  .emoji-response {
    font-size: var(--font-size-large);
  }

  .table-pagination {
    margin-top: var(--space-normal);
    text-align: right;
  }
}

.csat--empty-records {
  align-items: center;
  background-color: var(--white);
  border: 1px solid var(--color-border);
  border-top: 0;
  color: var(--b-600);
  display: flex;
  font-size: var(--font-size-small);
  height: 20rem;
  justify-content: center;
  margin-top: -1px;
  width: 100%;
}

.csat--timestamp {
  color: var(--b-400);
  font-size: var(--font-size-small);
}

.row--user-block {
  align-items: center;
  display: flex;
  text-align: left;

  .user-name {
    margin: 0;
    text-transform: capitalize;
  }
  .user-thumbnail-box {
    margin-right: var(--space-small);
  }
}
</style>
