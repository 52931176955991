<template>
  <div class="footer-wrap">
      <div  v-if="config.isDefaultScreen" class="widget_footer p-3" >
          <div class="upper">
            <div class="chatbot_guide cursor-pointer">
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.3333 35.1998C10.8666 35.1998 10.3833 35.0832 9.94995 34.8499C8.99995 34.3499 8.41665 33.3498 8.41665 32.2831V29.9166C3.38331 29.3999 0.083313 25.6998 0.083313 20.3998V10.3999C0.083313 4.66657 3.93331 0.816574 9.66665 0.816574H26.3333C32.0666 0.816574 35.9166 4.66657 35.9166 10.3999V20.3998C35.9166 26.1331 32.0666 29.9831 26.3333 29.9831H20.0499L12.9499 34.7166C12.4666 35.0333 11.9 35.1998 11.3333 35.1998ZM9.66665 3.29989C5.36665 3.29989 2.58331 6.08322 2.58331 10.3832V20.3833C2.58331 24.6833 5.36665 27.4667 9.66665 27.4667C10.35 27.4667 10.9166 28.0333 10.9166 28.7167V32.2667C10.9166 32.4833 11.05 32.5833 11.1333 32.6333C11.2167 32.6833 11.3833 32.7333 11.5667 32.6166L18.9834 27.6833C19.1834 27.55 19.4333 27.4667 19.6833 27.4667H26.35C30.65 27.4667 33.4333 24.6833 33.4333 20.3833V10.3832C33.4333 6.08322 30.65 3.29989 26.35 3.29989H9.66665Z" fill="#483A9D"/>
                    <path d="M17.9997 18.1833C17.3163 18.1833 16.7497 17.6167 16.7497 16.9333V16.5834C16.7497 14.6501 18.1663 13.7 18.6996 13.3334C19.3163 12.9167 19.5163 12.6334 19.5163 12.2C19.5163 11.3667 18.833 10.6833 17.9997 10.6833C17.1663 10.6833 16.483 11.3667 16.483 12.2C16.483 12.8834 15.9164 13.45 15.233 13.45C14.5497 13.45 13.983 12.8834 13.983 12.2C13.983 9.98338 15.783 8.18332 17.9997 8.18332C20.2163 8.18332 22.0163 9.98338 22.0163 12.2C22.0163 14.1 20.6163 15.05 20.0997 15.4C19.4497 15.8333 19.2497 16.1167 19.2497 16.5834V16.9333C19.2497 17.6333 18.683 18.1833 17.9997 18.1833Z" fill="#483A9D"/>
                    <path d="M18 22.3333C17.3 22.3333 16.75 21.7666 16.75 21.0833C16.75 20.4 17.3167 19.8333 18 19.8333C18.6833 19.8333 19.25 20.4 19.25 21.0833C19.25 21.7666 18.7 22.3333 18 22.3333Z" fill="#483A9D"/>
                </svg>
                <p>{{ $t('INBOX_MGMT.WIDGET_BUILDER.FOOTER.GUIDE') }}</p>
            </div>
            <div class="chatbot_call cursor-pointer ml-4">
                <svg width="34" height="36" viewBox="0 0 34 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17 35.9167C15.8333 35.9167 14.65 35.4666 13.7667 34.5833L10.9167 31.7667C10.2 31.0667 9.26666 30.6833 8.26666 30.6833H7C3.55 30.6833 0.75 27.9 0.75 24.4834V6.29997C0.75 2.88331 3.55 0.100021 7 0.100021H27C30.45 0.100021 33.25 2.88331 33.25 6.29997V24.4834C33.25 27.9 30.45 30.6833 27 30.6833H25.7333C24.7333 30.6833 23.7833 31.0667 23.0833 31.7667L20.2333 34.5833C19.35 35.4666 18.1667 35.9167 17 35.9167ZM7 2.58334C4.93333 2.58334 3.25 4.24996 3.25 6.28329V24.4667C3.25 26.5167 4.93333 28.1666 7 28.1666H8.26666C9.93332 28.1666 11.5 28.8166 12.6833 29.9832L15.5333 32.8C16.35 33.6 17.6667 33.6 18.4833 32.8L21.3333 29.9832C22.5167 28.8166 24.0833 28.1666 25.75 28.1666H27C29.0667 28.1666 30.75 26.5 30.75 24.4667V6.28329C30.75 4.23329 29.0667 2.58334 27 2.58334H7Z" fill="#483A9D"/>
                    <path d="M14.3004 22.1834H9.83371C9.10038 22.1834 8.41704 21.8332 7.98371 21.2332C7.56704 20.6666 7.46704 19.9667 7.66704 19.3001C8.25037 17.5167 9.68371 16.55 10.9504 15.6833C12.2837 14.7833 13.0337 14.2167 13.0337 13.25C13.0337 12.3834 12.3337 11.6833 11.467 11.6833C10.6004 11.6833 9.90039 12.3834 9.90039 13.25C9.90039 13.9334 9.33372 14.5 8.65039 14.5C7.96706 14.5 7.40039 13.9334 7.40039 13.25C7.40039 11.0167 9.21704 9.18327 11.467 9.18327C13.717 9.18327 15.5337 11 15.5337 13.25C15.5337 15.6 13.7671 16.8 12.3504 17.7666C11.4671 18.3666 10.6337 18.9334 10.217 19.6834H14.2837C14.967 19.6834 15.5337 20.25 15.5337 20.9334C15.5337 21.6167 14.9837 22.1834 14.3004 22.1834Z" fill="#483A9D"/>
                    <path d="M23.7331 22.1835C23.0498 22.1835 22.4831 21.6168 22.4831 20.9335V19.7833H19.2165C19.2165 19.7833 19.2165 19.7833 19.1998 19.7833C18.3832 19.7833 17.6332 19.3501 17.2165 18.6501C16.7998 17.9335 16.7998 17.0501 17.2165 16.3501C18.3498 14.4001 19.6665 12.1834 20.8665 10.2501C21.3998 9.40006 22.4165 9.0168 23.3665 9.28347C24.3165 9.5668 24.9831 10.4335 24.9665 11.4335V17.3H25.3332C26.0165 17.3 26.5832 17.8667 26.5832 18.55C26.5832 19.2334 26.0165 19.8 25.3332 19.8H24.9831V20.9501C24.9831 21.6335 24.4331 22.1835 23.7331 22.1835ZM22.4831 12.4001C21.4998 14.0001 20.4831 15.7167 19.5665 17.2833H22.4831V12.4001Z" fill="#483A9D"/>
                </svg>
                <p>{{ $t('INBOX_MGMT.WIDGET_BUILDER.FOOTER.BOOK') }}</p>
            </div>
          </div>
          <custom-button
              class="start-conversation"
              :style="{ background: '#483A9D'}"
          >
              {{
              $t('INBOX_MGMT.WIDGET_BUILDER.FOOTER.START_CONVERSATION_BUTTON_TEXT')
              }}
          </custom-button>
      </div>
    <div v-else class="chat-message-input is-focused">
      <resizable-text-area
        id="chat-input"
        :placeholder="
          $t('INBOX_MGMT.WIDGET_BUILDER.FOOTER.CHAT_INPUT_PLACEHOLDER')
        "
        class="user-message-input is-focused"
      />
      <div class="button-wrap">
        <fluent-icon icon="emoji" />
        <fluent-icon class="icon-send" icon="send" />
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from 'dashboard/components/buttons/Button';
import ResizableTextArea from 'shared/components/ResizableTextArea';
export default {
  name: 'WidgetFooter',
  components: {
    CustomButton,
    ResizableTextArea,
  },
  props: {
    config: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables.scss';
.footer-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: var(--space-two);
  padding-right: var(--space-two);

  .start-conversation {
    justify-content: center;
    font-size: var(--font-size-small);
    border-radius: var(--border-radius-normal);
    padding-left: var(--space-two);
    padding-right: var(--space-two);
  }

  .chat-message-input {
    align-items: center;
    display: flex;
    padding: var(--space-zero) var(--space-small) var(--space-zero)
      var(--space-slab);
    border-radius: var(--border-radius-normal);
    background: white;

    &.is-focused {
      box-shadow: 0 0 0 0.1rem var(--color-woot), 0 0 0.2rem 0.2rem var(--w-100);
    }
  }

  .button-wrap {
    display: flex;
    align-items: center;
    padding-left: var(--space-small);
  }

  .user-message-input {
    border: 0;
    height: var(--space-medium);
    min-height: var(--space-medium);
    max-height: var(--space-giga);
    line-height: 1;
    font-size: var(--font-size-small);
    resize: none;
    padding: var(--space-zero);
    padding-top: var(--space-smaller);
    padding-bottom: var(--space-smaller);
    margin-top: var(--space-small);
    margin-bottom: var(--space-small);
  }

  .icon-send {
    margin-left: var(--space-one);
  }
}

.widget_footer{
  background: #FFFFFF;
  border-radius: 8px 8px 0 0;

  .upper{
    display: flex;
    padding: 10px 0;
  }

  .chatbot_guide{
    background: #EDECF6;
    border: 1px solid #DAD8EB;
    border-radius: 8px;
    p{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 17px;
      text-align: center;
      color: #483A9D;
    }
  }

  .chart_call{
    background: #EDECF6;
    border: 1px solid #DAD8EB;
    border-radius: 8px;
    p{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 17px;
      text-align: center;
      color: #483A9D;
    }
  }
}
</style>
