<template>
  <label class="input-container" :style="{ display: saveFilter ? 'block' : 'inline-block' }">
<!--    <span v-if="label">{{ label }}</span>-->
    <input
      :value="value"
      :type="type"
      :placeholder="placeholder"
      :readonly="readonly"
      :style="styles"
      maxlength="max"
      @input="onChange"
      @blur="onBlur"
      @focus="onFocus"
      @keydown.enter.prevent
      class="custom_input"
      :maxlength="maxlength"
    />
    <p v-if="helpText" class="help-text">{{ helpText }}</p>
    <span v-if="error" class="message">
      {{ error }}
    </span>
  </label>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    helpText: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    maxlength: {
      type: Number,
      default: 100
    },
    saveFilter: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    onChange(e) {
      this.$emit('input', e.target.value);
    },
    onBlur(e) {
      this.$emit('blur', e.target.value);
    },
    onFocus(e) {
      this.$emit('focus', e.target.value);
    }
  },
};
</script>
<style scoped lang="scss">
.help-text {
  margin-top: var(--space-micro);
  font-size: var(--font-size-mini);
  color: var(--s-600);
  font-style: normal;
}
.message {
  margin-top: 0 !important;
}
 .custom_input{
   height: 40px !important;
   border: 1px solid #A8A8A8 ;
   border-radius: 8px !important;
   /* in case of error */
   &.error {
     border: 1px solid var(--r-400);
   }
 }

</style>
