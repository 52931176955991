<template>
  <woot-modal :show.sync="show" :on-close="onClose">
    <div class="column content-box">
      <woot-modal-header
        :header-title="$t('AGENT_MGMT.ADD.TITLE')"
        :header-content="$t('AGENT_MGMT.ADD.DESC')"
      />

      <form class="row" @submit.prevent="addAgent()">
        <div class="medium-12 columns">
            <input
              v-model.trim="agentName"
              type="text"
              :placeholder="$t('AGENT_MGMT.ADD.FORM.NAME.PLACEHOLDER')"
              @input="$v.agentName.$touch"
            />
            <!-- agentName's error message  -->
            <span v-if="$v.agentName.$error" class="message">
              {{ $t('ATTRIBUTES_MGMT.ADD.FORM.NAME.ERROR') }}
            </span>
        </div>
        <div class="medium-12 columns">
            <multiselect-dropdown
                    :options="roles"
                    :selected-item="agentType"
                    :multiselector-title="$t('AGENT_MGMT.ADD.FORM.AGENT_TYPE.PLACEHOLDER')"
                    :multiselector-placeholder="$t('AGENT_MGMT.ADD.FORM.AGENT_TYPE.PLACEHOLDER')"
                    :add-agent="true"
                    :has-thumbnail="false"
                    @click="selectRole"
            />
            <span v-if="$v.agentType.$error" class="message">
              {{ $t('AGENT_MGMT.ADD.FORM.AGENT_TYPE.ERROR') }}
            </span>
        </div>
        <div class="medium-12 columns" style="margin-top: 1.5rem;">
            <input
              v-model.trim="agentEmail"
              type="text"
              :placeholder="$t('AGENT_MGMT.ADD.FORM.EMAIL.PLACEHOLDER')"
              @input="$v.agentEmail.$touch"
            />
            <!-- agentEmail's error message  -->
            <span v-if="$v.agentEmail.$error" class="message">
                {{ $t('AGENT_MGMT.ADD.FORM.EMAIL.ERROR') }}
            </span>
        </div>
        <div class="modal-footer" style="margin-bottom: -3rem;" :style="{ marginLeft: left }">
          <div class="medium-12 columns" style="direction: rtl;">
            <woot-submit-button
              :disabled="
                $v.agentEmail.$invalid ||
                  $v.agentName.$invalid ||
                  uiFlags.isCreating
              "
              :button-text="$t('AGENT_MGMT.ADD.FORM.SUBMIT')"
              :loading="uiFlags.isCreating"
            />
            <button class="button clear" @click.prevent="onClose">
              {{ $t('AGENT_MGMT.ADD.CANCEL_BUTTON_TEXT') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </woot-modal>
</template>

<script>
import { required, minLength, email } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import MultiselectDropdown from 'shared/components/ui/MultiselectDropdown.vue' ;

export default {
  components: {
    MultiselectDropdown,
  },
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      agentName: '',
      agentEmail: '',
      agentType: {
          id: 2,
          name: this.$t('AGENT_MGMT.AGENT_TYPES.AGENT'),
          type: 'agent',
      },
      vertical: 'bottom',
      horizontal: 'center',
      roles: [
        {
          id: 1,
          name: this.$t('AGENT_MGMT.AGENT_TYPES.ADMINISTRATOR'),
          type: 'administrator',
        },
        {
          id: 2,
          name: this.$t('AGENT_MGMT.AGENT_TYPES.AGENT'),
          type: 'agent',
        },
      ],
      show: true,
      left: this.$root.$i18n.locale === 'fr' ? '50rem' : '53.9rem' ,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'agents/getUIFlags',
    }),
  },
  validations: {
    agentName: {
      required,
      minLength: minLength(1),
    },
    agentEmail: {
      required,
      email,
    },
    agentType: {
      required,
    },
  },

  methods: {
    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
    selectRole(role) {
        if (this.agentType && this.agentType.id === role.id) {
            if (this.agentType.type === 'agent') {
                this.agentType = {
                    id: 1,
                    name: this.$t('AGENT_MGMT.AGENT_TYPES.ADMINISTRATOR'),
                    type: 'administrator',
                };
            }
            else {
                this.agentType = {
                    id: 2,
                    name: this.$t('AGENT_MGMT.AGENT_TYPES.AGENT'),
                    type: 'agent',
                };
            }

        }
        else {
            this.agentType = role;
        }
    },
    async addAgent() {
      try {
        await this.$store.dispatch('agents/create', {
          name: this.agentName,
          email: this.agentEmail,
          role: this.agentType.type,
        });
        this.showAlert(this.$t('AGENT_MGMT.ADD.API.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (error) {
        const {
          response: { data: { error: errorResponse = '' } = {} } = {},
        } = error;
        let errorMessage = '';
        if (error.response.status === 422) {
          errorMessage = this.$t('AGENT_MGMT.ADD.API.EXIST_MESSAGE');
        } else {
          errorMessage = this.$t('AGENT_MGMT.ADD.API.ERROR_MESSAGE');
        }
        this.showAlert(errorResponse || errorMessage);
      }
    },
  },
};
</script>
<style lang="scss" scoped>

/* error message's style */
.message {
  color: #f94b4a;
  color: var(--r-400);
  display: block;
  font-size: 1.4rem;
  font-size: var(--font-size-small);
  font-weight: 400;
  margin-bottom: 1rem;
  margin-top: -1.6rem;
  width: 100%;
}
</style>
