<template>
  <div
    ref="dropdownElement"
    v-on-clickaway="onCloseDropdown"
    class="selector-wrap"
    :class="{ 'right-margin': report, 'filter-style': isConv, 'automation-width': filterQuestion || isOperatorFilter, 'operator-select': isQueryOperator, 'action-width': actionName && showActionInput,
     'full-width': !showActionInput && actionName}"
    @keyup.esc="onCloseDropdown"
  >
    <woot-button
      variant="hollow"
      color-scheme="secondary"
      class="selector-button"
      :class="{ 'rounded-button': report, 'background-gris': isConv }"
      @click.prevent="toggleDropdown"
    >
      <div class="selector-user-wrap">
        <Thumbnail
          v-if="hasValue && hasThumbnail"
          :src="selectedItem.thumbnail"
          size="24px"
          :status="selectedItem.availability_status"
          :username="selectedItem.name"
        />
        <div class="selector-name-wrap">
          <h4
            v-if="!hasValue"
            class="not-selected text-ellipsis text-block-title"
            :class="{ 'small-size': isConv }"
          >
            {{ multiselectorPlaceholder }}
          </h4>
          <h4
            v-else
            class="selector-name text-truncate text-block-title"
            :class="{ 'small-size': isConv }"
            :title="selectedItem.name"
          >
            {{ selectedName() }}
          </h4>
          <i v-if="showSearchDropdown" class="icon ion-chevron-up" />
          <i v-else class="icon ion-chevron-down" />
        </div>
      </div>
    </woot-button>
    <div
      :class="{ 'dropdown-pane--open': showSearchDropdown, 'top': isConv }"
      class="dropdown-pane"
    >
      <div v-if="!isConv && !eventName && !filterQuestion && !isOperatorFilter && !isAnswerCondition && !isQueryOperator && !actionName && !actionParam" class="dropdown__header">
        <h4 v-if="options.length === 0" class="text-block-title text-truncate">
              {{ $t('CONVERSATION.NONE') }}
        </h4>
        <h4 v-else class="text-block-title text-truncate">
          {{ multiselectorTitle }}
        </h4>
        <woot-button
          icon="dismiss"
          size="tiny"
          color-scheme="secondary"
          variant="clear"
          @click.prevent="onCloseDropdown"
        />
      </div>
      <multiselect-dropdown-items
        v-if="showSearchDropdown"
        :options="options"
        :selected-items="selectedItem"
        :has-thumbnail="hasThumbnail"
        :input-placeholder="inputPlaceholder"
        :no-search-result="noSearchResult"
        :is-agent="isAgent"
        :is-team="isTeam"
        :add-agent="addAgent"
        :edit-agent="editAgent"
        :is-attribute="isAttribute"
        :is-bot="isBot"
        :is-lang="isLang"
        :is-time="isTime"
        :is-conv-filter="isConvFilter"
        :is-operator-filter="isOperatorFilter"
        :is-answer-filter="isAnswerFilter"
        :is-contract="isContract"
        :is-filter-contract="isFilterContract"
        :is-claim="isClaim"
        :is-conv="isConv"
        :is-status="isStatus"
        :event-name="eventName"
        :filter-question="filterQuestion"
        :is-answer-condition="isAnswerCondition"
        :is-query-operator="isQueryOperator"
        :action-name="actionName"
        :action-param="actionParam"
        @click="onClickSelectItem"
      />
    </div>
  </div>
</template>

<script>
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import MultiselectDropdownItems from 'shared/components/ui/MultiselectDropdownItems';
import { mixin as clickaway } from 'vue-clickaway';
export default {
  components: {
    Thumbnail,
    MultiselectDropdownItems,
  },
  mixins: [clickaway],
  props: {
    options: {
      type: [Array, Object],
      default: () => [],
    },
    selectedItem: {
      type: [Object, Array],
      default: () => ({}),
    },
    hasThumbnail: {
      type: Boolean,
      default: true,
    },
    multiselectorTitle: {
      type: String,
      default: '',
    },
    multiselectorPlaceholder: {
      type: String,
      default: function() {
        return this.$root.$i18n.locale === 'fr' ? 'Aucun' : 'None';
      }
    },
    noSearchResult: {
      type: String,
      default: 'No results found',
    },
    inputPlaceholder: {
      type: String,
      default: 'Search',
    },
    isAgent: {
      type: Boolean,
      default: false,
    },
    isTeam: {
      type: Boolean,
      default: false,
    },
    addAgent: {
       type: Boolean,
       default: false,
    },
    editAgent: {
       type: Boolean,
       default: false,
    },
    isAttribute: {
       type: Boolean,
       default: false,
    },
    isBot: {
       type: Boolean,
       default: false,
    },
    isLang: {
       type: Boolean,
       default: false,
    },
    isTime: {
       type: Boolean,
       default: false,
    },
    isConvFilter: {
       type: Boolean,
       default: false,
    },
    isOperatorFilter: {
       type: Boolean,
       default: false,
    },
    isAnswerFilter: {
          type: Boolean,
          default: false,
    },
    report: {
          type: Boolean,
          default: false,
    },
    isContract: {
      type: Boolean,
      default: false,
    },
    isFilterContract: {
      type: Boolean,
      default: false,
    },
    all: {
      type: Boolean,
      default: false,
    },
    isClaim: {
      type: Boolean,
      default: false,
    },
    isConv: {
      type: Boolean,
      default: false,
    },
    isStatus: {
      type: Boolean,
      default: false,
    },
    eventName: {
      type: Boolean,
      default: false,
    },
    filterQuestion: {
      type: Boolean,
      default: false,
    },
    isAnswerCondition: {
      type: Boolean,
      default: false,
    },
    isQueryOperator: {
      type: Boolean,
      default: false,
    },
    actionName: {
      type: Boolean,
      default: false,
    },
    showActionInput: {
      type: Boolean,
      default: true,
    },
    close: {
      type: Boolean,
      default: false,
    },
    actionParam: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      showSearchDropdown: false,
    };
  },
  watch: {
    close() {
      if (this.close) {
        this.onCloseDropdown();
        this.$emit('close', this.close);
      }
    },
  },
  computed: {
    hasValue() {
      if (this.isAttribute || this.isLang || this.isTime || this.isConvFilter || this.isOperatorFilter || this.isAnswerFilter || this.isContract || this.isFilterContract
      || this.isClaim || this.eventName || this.filterQuestion || this.isAnswerCondition || this.isQueryOperator || this.actionName) {
          if (this.selectedItem) {
              return true;
          }
          return false;
      }
      else {
          if (this.selectedItem && this.selectedItem.id) {
              return true;
          }
          return false;
      }

    },
  },
  methods: {
    toggleDropdown() {
      if (!this.all) {
        this.showSearchDropdown = !this.showSearchDropdown;
      }
    },

    onCloseDropdown() {
      this.showSearchDropdown = false;
    },

    onClickSelectItem(value) {
      this.$emit('click', value);
      this.onCloseDropdown();
    },
    selectedName() {
       if (this.editAgent || this.actionName) {
           return `${this.selectedItem.label}`;
       }
       else if (this.isAttribute) {
           return `${this.selectedItem.option}`;
       }
       else if (this.isLang || this.isQueryOperator) {
           return `${this.selectedItem.name}`;
       }
       else if (this.isOperatorFilter) {
           return this.$t(`FILTER.OPERATOR_LABELS.${this.selectedItem.value}`);
       }
       else if(this.isClaim) {
         return `${this.selectedItem.value}`;
       }
       else if(this.eventName) {
         const translationKey = `AUTOMATION.ADD.FORM.EVENT.${this.selectedItem.key}`;
         const translatedText = this.$te(translationKey) ? this.$t(translationKey) : this.selectedItem.name;
         return translatedText ;
       }
       else if (this.filterQuestion) {
         const translationKey = `AUTOMATION.ADD.FORM.CONDITIONS.FILTER_QUESTION.${this.selectedItem.key}`;
         const translatedText = this.$te(translationKey) ? this.$t(translationKey) : this.selectedItem.name;
         return translatedText ;
       }
       else if (this.isAnswerCondition) {
         if (Array.isArray(this.selectedItem)) {
           return `${this.selectedItem[0].name}`;
         }
         else if (typeof this.selectedItem === 'object') {
           return `${this.selectedItem.name}`;
         }
       }
       else {
           return `${this.selectedItem.name}`;
       }
    }
  },
};
</script>

<style lang="scss" scoped>
.selector-wrap {
  position: relative;
  width: 100%;
  margin-right: var(--space-one);
  margin-bottom: var(--space-small);
  /*background-color: white;*/

  .selector-button {
    width: 100%;
    border: 1px solid var(--s-200);
    padding-left: var(--space-one);
    padding-right: var(--space-one);
    background-color: white;

    &:hover {
      border: 1px solid var(--color-border);
    }
  }
  .rounded-button {
    border-radius: 8px ;
    border-color: #a8a8a8 !important;
  }

  .background-gris {
    background-color: var(--color-background-light);
    height: auto;
  }

  .selector-user-wrap {
    display: flex;
  }

  .selector-name-wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-width: 0;
    align-items: center;
  }

  .not-selected {
    margin: 0 var(--space-small) 0 0;
  }

  .selector-name {
    align-items: center;
    line-height: 1.2;
    margin: 0 var(--space-small);
  }

  .dropdown-pane {
    box-sizing: border-box;
    top: 4.2rem;
    width: 100% !important;
  }
  .small-size {
    font-size: var(--font-size-mini);
  }
}

.right-margin {
  margin-right: 0 !important;
}
.filter-style {
  margin: 0 var(--space-smaller);
  width: 126px;
}
.automation-width {
  max-width: 30%;
}
.operator-select {
  position: relative;
  width: 22%;
  margin-bottom: var(--space-zero) !important;
}
.action-width {
  margin-bottom: var(--space-zero);
  margin-right: var(--space-smaller);
  max-width: 50%;
}
.full-width {
  margin-bottom: var(--space-zero);
  margin-right: var(--space-smaller);
  max-width: 100%;
}
.top {
  top: auto !important;
}
.dropdown__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--space-smaller);

  .text-block-title {
    margin: 0;
  }
}
</style>
