import Index from './Index';
import SettingsContent from '../Wrapper';
import { frontendURL } from '../../../../helper/URLHelper';
import {requireAuth} from "../../dashboard.routes";
import {FEATURE_FLAGS} from "../../../../featureFlags";

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/campaigns'),
      component: SettingsContent,
      props: {
        headerTitle: 'CAMPAIGN.ONGOING.HEADER',
        icon: 'arrow-swap',
      },
      children: [
        {
          path: '',
          redirect: 'ongoing',
        },
        {
          path: 'ongoing',
          name: 'settings_account_campaigns',
          roles: ['administrator'],
          component: { ...Index },
          beforeEnter: requireAuth,
          meta: {
            permission: FEATURE_FLAGS.CAMPAIGNS
          }
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/campaigns'),
      component: SettingsContent,
      props: {
        headerTitle: 'CAMPAIGN.ONE_OFF.HEADER',
        icon: 'sound-source',
      },
      children: [
        {
          path: 'one_off',
          name: 'one_off',
          roles: ['administrator'],
          component: { ...Index },
          beforeEnter: requireAuth,
          meta: {
            permission: FEATURE_FLAGS.CAMPAIGNS
          }
        },
      ],
    },
  ],
};
