import { HorizontalBar } from 'vue-chartjs';

const chartOptions = {
  responsive: true,
  legend: {
    display: false,
  },
  title: {
    display: false,
  },
  tooltips: {
    enabled: false,
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          offsetGridLines: false,
        },
        display: false,
        stacked: true,
      },
    ],
    yAxes: [
      {
        gridLines: {
          offsetGridLines: false,
        },
        display: false,
        stacked: true,
      },
    ],
  },
};

export default {
  extends: HorizontalBar,
  data() {
    return {
      barWidth : 0,
    };
  },
  props: {
    collection: {
      type: Object,
      default: () => {},
    },
    chartOptions: {
      type: Object,
      default: () => {},
    },
    fromSubject: {
      type: Boolean,
      default: false,
    },
    subjectsWidth: {
      type: Object,
      default: () => {},
    },
    fromCsat: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    collection() {
      if (this.fromSubject) {
        const chartElements = document.getElementsByClassName('chartjs-render-monitor');
        for (let i = 0; i < chartElements.length; i++) {
            chartElements[chartElements.length - 1].style.setProperty('height', '38px', 'important');
            const chartElement = chartElements[i];
            chartElement.style.setProperty('max-height', '38px', 'important');
            chartElement.style.setProperty('width', this.subjectsWidth[i].width + '%', 'important');
            chartElement.style.setProperty('max-width', this.subjectsWidth[i].width + '%', 'important');
            chartElement.style.setProperty('height', '38px', 'important');
          }
      }
      this.renderChart(this.collection, {
        ...chartOptions,
        ...this.chartOptions,
      });
      if (this.fromCsat) {
        this.barWidth = 0;
        // Access the X scale for information on the width of each bar
        const xScale = this.$data._chart.scales['x-axis-0'];
        // Access dataset data
        const datasets  = this.$data._chart.data.datasets;
        // Browse data and access bar coordinates
        datasets .forEach((dataset, datasetIndex) => {
          // Access the unique element in each data object
          const value = dataset.data[0];
          // X coordinates of each bar in the graph
          const xCoordinate = xScale.getPixelForValue(value, datasetIndex);
          this.barWidth = this.barWidth + xCoordinate ;
        });
        this.$emit('bar-width', this.barWidth);
      }
    },
  },
  mounted() {
    if (this.fromSubject) {
      const chartElements = document.getElementsByClassName('chartjs-render-monitor');
      for (let i = 0; i < chartElements.length; i++) {
          const chartElement = chartElements[i];
          chartElement.style.setProperty('max-height', '38px', 'important');
          chartElement.style.setProperty('width', this.subjectsWidth[i].width + '%', 'important');
          chartElement.style.setProperty('max-width', this.subjectsWidth[i].width + '%', 'important');
          chartElement.style.setProperty('height', '38px', 'important');
        }
    }
    this.renderChart(this.collection, {
      ...chartOptions,
      ...this.chartOptions,
    });
    if (this.fromCsat) {
      const xScale = this.$data._chart.scales['x-axis-0'];
      const datasets  = this.$data._chart.data.datasets;
      datasets .forEach((dataset, datasetIndex) => {
        const value = dataset.data[0];
        const xCoordinate = xScale.getPixelForValue(value, datasetIndex);
        this.barWidth = this.barWidth + xCoordinate ;
      });
      this.$emit('bar-width', this.barWidth);
    }
  },
};
