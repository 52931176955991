<template>
    <div class="row vh-100">
        <div class="columns large-6 bg-white login-section-wrapper login">
            <div class="convergence__logo ">
                <img alt="" :src="require('../../assets/images/convergence_logo_log.png')">
            </div>
            <div class="login__form">
                <form @submit.prevent="submit()">
                    <h5 class="fs-18 text-black font-inter">{{ $t('RESET_PASSWORD.TITLE') }}</h5>
                    <div class="column log-in-form">
                        <input
                                :class="{ error: $v.credentials.email.$error }"
                                v-model.trim="credentials.email"
                                type="text"
                                :placeholder="$t('RESET_PASSWORD.EMAIL.PLACEHOLDER')"
                                @input="$v.credentials.email.$touch"
                        />
                        <woot-submit-button
                                :disabled="$v.credentials.email.$invalid || resetPassword.showLoading"
                                :button-text="$t('RESET_PASSWORD.SUBMIT')"
                                :loading="resetPassword.showLoading"
                                button-class="expanded"
                                class="reset_btn"
                        />
                        <button class="back-button" @click.capture="goBack">
                            <fluent-icon icon="chevron-left" />
                            <span>{{ buttonLabel || $t('GENERAL_SETTINGS.BACK') }}</span>
                        </button>
                    </div>
                    <div>

                    </div>
                </form>
            </div>
            <div class="copyright font-inter">
                Convergence © 2022 All rights reserved
            </div>
        </div>

        <div class="columns large-6 second-wrapper text-center "
             :style="{'background-image': `url(${require('../../assets/images/background_rect.png')})`}"
        >
            <div class="display-block">
                <img class="converTable_img" alt="" :src="require('../../assets/images/conver_tables.png')">
                <div class="description_conver">
                    <h4 class="display-10 font-inter">Convergence</h4>
                    <p class=" fs-14 mt-3 font-inter">All in one product to increase your income, handle <span>high volume</span> of requests in no time and provide <span class="text-primary"> excellent customer </span> experience through different channels.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { required, minLength, email } from 'vuelidate/lib/validators';
import Auth from '../../api/auth';
import router from "../index";

export default {
    data() {
        return {
            // We need to initialize the component with any
            // properties that will be used in it
            credentials: {
                email: '',
            },
            resetPassword: {
                message: '',
                showLoading: false,
            },
            error: '',
        };
    },
    validations: {
        credentials: {
            email: {
                required,
                email,
                minLength: minLength(4),
            },
        },
    },
    methods: {
        goBack() {
                router.go(-1);
        },
        showAlert(message) {
            // Reset loading, current selected agent
            this.resetPassword.showLoading = false;
            bus.$emit('newToastMessage', message);
        },
        submit() {
            this.resetPassword.showLoading = true;
            Auth.resetPassword(this.credentials)
                .then(res => {
                    let successMessage = this.$t('RESET_PASSWORD.API.SUCCESS_MESSAGE');
                    if (res.data && res.data.message) {
                        successMessage = res.data.message;
                    }
                    this.showAlert(successMessage);
                })
                .catch(error => {
                    let errorMessage = this.$t('RESET_PASSWORD.API.ERROR_MESSAGE');
                    if (error?.response?.data?.message) {
                        errorMessage = error.response.data.message;
                    }
                    this.showAlert(errorMessage);
                });
        },
    },
};
</script>

<style scoped lang="scss">
@import "app/javascript/dashboard/assets/scss/components/_templogin.scss";
.back-button{
  margin: 1.5rem auto !important;
  span{
    margin-top: 0.14rem !important;
    font-size: 14px!important;
  }
  svg{
    height: 14px!important;
  }

}
</style>
