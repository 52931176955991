<template>
  <transition name="modal-fade">
    <div
      v-if="show"
      :class="modalClassName"
      transition="modal"
      @click="onBackDropClick"
    >
      <div :class="modalContainerClassName" @click.stop>
        <button
          class="modal--close close_btn"
          @click="close"
        >
            <svg style="margin-top: 5px" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.12559 0.87539C9.45103 1.20083 9.45103 1.72846 9.12559 2.0539L2.05452 9.12497C1.72908 9.45041 1.20145 9.45041 0.876009 9.12497C0.550572 8.79953 0.550572 8.27189 0.876009 7.94646L7.94708 0.87539C8.27251 0.549953 8.80015 0.549953 9.12559 0.87539Z" fill="#292D32"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.12461 9.12478C8.79917 9.45022 8.27154 9.45022 7.9461 9.12478L0.875032 2.05371C0.549595 1.72827 0.549595 1.20064 0.875032 0.875199C1.20047 0.549762 1.72811 0.549762 2.05354 0.875199L9.12461 7.94627C9.45005 8.2717 9.45005 8.79934 9.12461 9.12478Z" fill="#292D32"/>
            </svg>
        </button>
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    closeOnBackdropClick: {
      type: Boolean,
      default: true,
    },
    show: Boolean,
    onClose: {
      type: Function,
      required: true,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    modalType: {
      type: String,
      default: 'centered',
    },
    size: {
      type: String,
      default: '',
    },
  },
  computed: {
    modalContainerClassName() {
      let className = 'modal-container';
      if (this.fullWidth) {
        return `${className} modal-container--full-width`;
      }

      return `${className} ${this.size}`;
    },
    modalClassName() {
      const modalClassNameMap = {
        centered: '',
        'right-aligned': 'right-aligned',
      };

      return `modal-mask ${modalClassNameMap[this.modalType] || ''}`;
    },
  },
  mounted() {
    document.addEventListener('keydown', e => {
      if (this.show && e.code === 'Escape') {
        this.onClose();
      }
    });
  },
  methods: {
    close() {
      this.onClose();
    },
    onBackDropClick() {
      if (this.closeOnBackdropClick) {
        this.onClose();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.modal-container--full-width {
  align-items: center;
  border-radius: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.modal-mask.right-aligned {
  //justify-content: flex-end;
  .modal-container {
    border-radius: 0;
    height: 100%;
    //width: 48rem;
  }
}

.close_btn{
  background: #DAD8EB !important;
  border-radius: 8px !important;
  height: 38px !important;
  width: 38px !important;
}

.close_btn:hover{
  background-color: #d1cce7 !important;
  cursor: pointer !important;
}

.modal-big {
  width: 60%;
}
</style>
