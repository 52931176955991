<template>
    <div class="complaints">
      <!--<div class="no-complaints-parent">
        <span class="no-complaints"> {{ $t('SINISTER_SIDEBAR.IN_PROGRESS') }} </span>
        <span class="no-complaints-soon"> {{ $t('SINISTER_SIDEBAR.SOON') }} </span>
      </div>-->
      <div class="complaint_action">
        <span class="complaints_title">{{ $t('COMPLAINT_SIDEBAR.HISTORY') }}</span>
        <button
          v-if="claims.length"
          style="float: right; cursor: pointer"
          @click="onToggleFilters"
        >
            <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 16C0 8.45753 0 4.68629 2.34315 2.34315C4.68629 0 8.45753 0 16 0H28C35.5425 0 39.3137 0 41.6569 2.34315C44 4.68629 44 8.45753 44 16V28C44 35.5425 44 39.3137 41.6569 41.6569C39.3137 44 35.5425 44 28 44H16C8.45753 44 4.68629 44 2.34315 41.6569C0 39.3137 0 35.5425 0 28V16Z" fill="#EDECF6"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3499 12.75C13.0439 12.75 12.7548 13.0022 12.7598 13.4006L12.7599 13.41L12.7599 14.96C12.7599 15.0219 12.7866 15.1658 12.871 15.3528C12.9525 15.5334 13.0578 15.6873 13.1502 15.7797L13.1641 15.794L15.7241 18.494C15.7392 18.5099 15.7536 18.5264 15.7672 18.5436C15.9551 18.7802 16.1243 19.0839 16.2477 19.3933C16.3694 19.6984 16.4698 20.0667 16.4698 20.43V24C16.4698 24.4501 16.9962 24.7626 17.411 24.5147L18.3416 23.9109L18.3558 23.9019C18.3732 23.8911 18.4455 23.8276 18.5171 23.6899C18.5872 23.5554 18.6099 23.4394 18.6099 23.39V20.49C18.6099 20.1044 18.7039 19.7025 18.8543 19.3475C19.0024 18.9978 19.2282 18.6392 19.5382 18.3737L22.4084 15.84C22.4995 15.7406 22.6135 15.5726 22.7059 15.3694C22.8054 15.1508 22.8499 14.9573 22.8499 14.83V13.35C22.8499 13.0242 22.5756 12.75 22.2499 12.75H13.3499ZM11.2599 13.4142C11.2476 12.2151 12.1775 11.25 13.3499 11.25H22.2499C23.4041 11.25 24.3499 12.1958 24.3499 13.35V14.83C24.3499 15.2427 24.2243 15.6541 24.0713 15.9906C23.9169 16.3298 23.7045 16.6587 23.4681 16.9024C23.4546 16.9162 23.4407 16.9295 23.4262 16.9423L20.5157 19.5116C20.4287 19.5855 20.3215 19.7295 20.2355 19.9325C20.1508 20.1325 20.1099 20.3356 20.1099 20.49V23.39C20.1099 23.7406 19.9975 24.0946 19.8476 24.3826C19.7006 24.665 19.4705 24.9735 19.1523 25.1729L18.2181 25.7791L18.2021 25.7892C16.858 26.6141 14.9698 25.7076 14.9698 24V20.43C14.9698 20.3233 14.9353 20.1516 14.8545 19.9492C14.7809 19.7648 14.6885 19.6037 14.6099 19.4989L12.0818 16.8325C11.8377 16.5861 11.6408 16.2734 11.5038 15.9697C11.3681 15.6692 11.2599 15.308 11.2599 14.96V13.4142Z" fill="#483A9D"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M26.2096 12.1209C26.2755 11.712 26.6605 11.434 27.0694 11.4999C27.5463 11.5768 28.0573 11.6734 28.5214 11.7907C28.9696 11.9041 29.433 12.0509 29.7795 12.2477C31.9428 13.4675 32.75 15.7811 32.75 19.0004V25.0004C32.75 27.5743 32.2398 29.5712 30.9053 30.9057C29.5709 32.2402 27.574 32.7504 25 32.7504H19C16.426 32.7504 14.4291 32.2402 13.0947 30.9057C11.7602 29.5712 11.25 27.5743 11.25 25.0004V22.0004C11.25 21.5861 11.5858 21.2504 12 21.2504C12.4142 21.2504 12.75 21.5861 12.75 22.0004V25.0004C12.75 27.4264 13.2398 28.9295 14.1553 29.845C15.0709 30.7606 16.574 31.2504 19 31.2504H25C27.426 31.2504 28.9291 30.7606 29.8447 29.845C30.7602 28.9295 31.25 27.4264 31.25 25.0004V19.0004C31.25 15.9803 30.4975 14.3741 29.0418 13.5537L29.0392 13.5523C28.8759 13.4594 28.5748 13.3515 28.1536 13.245C27.7477 13.1423 27.2837 13.0539 26.8306 12.9808C26.4217 12.9148 26.1436 12.5299 26.2096 12.1209Z" fill="#483A9D"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M22.25 23C22.25 22.5858 22.5858 22.25 23 22.25H28C28.4142 22.25 28.75 22.5858 28.75 23C28.75 23.4142 28.4142 23.75 28 23.75H23C22.5858 23.75 22.25 23.4142 22.25 23Z" fill="#483A9D"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.25 27C20.25 26.5858 20.5858 26.25 21 26.25H28C28.4142 26.25 28.75 26.5858 28.75 27C28.75 27.4142 28.4142 27.75 28 27.75H21C20.5858 27.75 20.25 27.4142 20.25 27Z" fill="#483A9D"/>
            </svg>
        </button>
    </div>

    <span v-if="!claims.length" class="no-complaints">
      {{ $t('COMPLAINT_SIDEBAR.NO_COMPLAINTS') }}
    </span>
    <div
      v-for="(complaint, index) in visibleClaims"
      :key="index"
      class="complaint_card mt-2"
    >
      <a
        :href="`${complaint.redirectUrl}`"
        target="_blank"
        rel="noreferrer noopener nofollow"
      >
          <div class="complaint_header d-flex ">
              <div class="complaint_num d-flex">
                  <h4>N°{{ complaint.numeroTicket }}</h4>
              </div>
              <div class="type_badge pending">
                  {{ $t('COMPLAINT_SIDEBAR.IN_PROGRESS') }}
              </div>
          </div>
          <div class="complaints_body d-flex">
              <div class="end_date">
                  <svg width="15" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M5 0.9375C5.31066 0.9375 5.5625 1.18934 5.5625 1.5V3.75C5.5625 4.06066 5.31066 4.3125 5 4.3125C4.68934 4.3125 4.4375 4.06066 4.4375 3.75V1.5C4.4375 1.18934 4.68934 0.9375 5 0.9375Z"
                          fill="#FA3559" />
                      <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M11 0.9375C11.3107 0.9375 11.5625 1.18934 11.5625 1.5V3.75C11.5625 4.06066 11.3107 4.3125 11 4.3125C10.6893 4.3125 10.4375 4.06066 10.4375 3.75V1.5C10.4375 1.18934 10.6893 0.9375 11 0.9375Z"
                          fill="#FA3559" />
                      <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M1.0625 6.81738C1.0625 6.50672 1.31434 6.25488 1.625 6.25488H14.375C14.6857 6.25488 14.9375 6.50672 14.9375 6.81738C14.9375 7.12804 14.6857 7.37988 14.375 7.37988H1.625C1.31434 7.37988 1.0625 7.12804 1.0625 6.81738Z"
                          fill="#FA3559" />
                      <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M12.5 11.8125C11.1538 11.8125 10.0625 12.9038 10.0625 14.25C10.0625 15.5962 11.1538 16.6875 12.5 16.6875C13.8462 16.6875 14.9375 15.5962 14.9375 14.25C14.9375 12.9038 13.8462 11.8125 12.5 11.8125ZM8.9375 14.25C8.9375 12.2825 10.5325 10.6875 12.5 10.6875C14.4675 10.6875 16.0625 12.2825 16.0625 14.25C16.0625 16.2175 14.4675 17.8125 12.5 17.8125C10.5325 17.8125 8.9375 16.2175 8.9375 14.25Z"
                          fill="#FA3559" />
                      <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M10.8203 14.2871C10.8203 13.9764 11.0722 13.7246 11.3828 13.7246H13.6178C13.9285 13.7246 14.1803 13.9764 14.1803 14.2871C14.1803 14.5978 13.9285 14.8496 13.6178 14.8496H11.3828C11.0722 14.8496 10.8203 14.5978 10.8203 14.2871Z"
                          fill="#FA3559" />
                      <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M12.5 12.6299C12.8107 12.6299 13.0625 12.8817 13.0625 13.1924V15.4349C13.0625 15.7455 12.8107 15.9974 12.5 15.9974C12.1893 15.9974 11.9375 15.7455 11.9375 15.4349V13.1924C11.9375 12.8817 12.1893 12.6299 12.5 12.6299Z"
                          fill="#FA3559" />
                      <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M2.55282 4.039C2.07227 4.55652 1.8125 5.33623 1.8125 6.375V12.75C1.8125 13.7888 2.07227 14.5685 2.55282 15.086C3.02636 15.596 3.79471 15.9375 5 15.9375H9.36734C9.09138 15.434 8.9375 14.8557 8.9375 14.25C8.9375 12.2818 10.5318 10.6875 12.5 10.6875C13.1123 10.6875 13.6866 10.8426 14.1875 11.1135V6.375C14.1875 5.33623 13.9277 4.55652 13.4472 4.039C12.9736 3.52904 12.2053 3.1875 11 3.1875H5C3.79471 3.1875 3.02636 3.52904 2.55282 4.039ZM1.72843 3.2735C2.47364 2.47096 3.58029 2.0625 5 2.0625H11C12.4197 2.0625 13.5264 2.47096 14.2716 3.2735C15.0098 4.06848 15.3125 5.16377 15.3125 6.375V12.27C15.3125 12.5041 15.1675 12.7137 14.9485 12.7963C14.7295 12.8789 14.4822 12.8172 14.3276 12.6415C13.8814 12.1341 13.2301 11.8125 12.5 11.8125C11.1532 11.8125 10.0625 12.9032 10.0625 14.25C10.0625 14.707 10.1904 15.1375 10.4138 15.4997L10.4209 15.5116C10.5461 15.7263 10.7068 15.9162 10.889 16.069C11.0702 16.221 11.137 16.4701 11.0561 16.6923C10.9752 16.9146 10.764 17.0625 10.5275 17.0625H5C3.58029 17.0625 2.47364 16.654 1.72843 15.8515C0.990225 15.0565 0.6875 13.9612 0.6875 12.75V6.375C0.6875 5.16377 0.990225 4.06848 1.72843 3.2735Z"
                          fill="#FA3559" />
                      <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M7.24609 10.2754C7.24609 9.86118 7.58188 9.52539 7.99609 9.52539H8.00283C8.41704 9.52539 8.75283 9.86118 8.75283 10.2754C8.75283 10.6896 8.41704 11.0254 8.00283 11.0254H7.99609C7.58188 11.0254 7.24609 10.6896 7.24609 10.2754Z"
                          fill="#FA3559" />
                      <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M4.4707 10.2754C4.4707 9.86118 4.80649 9.52539 5.2207 9.52539H5.22744C5.64165 9.52539 5.97744 9.86118 5.97744 10.2754C5.97744 10.6896 5.64165 11.0254 5.22744 11.0254H5.2207C4.80649 11.0254 4.4707 10.6896 4.4707 10.2754Z"
                          fill="#FA3559" />
                      <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M4.4707 12.5254C4.4707 12.1112 4.80649 11.7754 5.2207 11.7754H5.22744C5.64165 11.7754 5.97744 12.1112 5.97744 12.5254C5.97744 12.9396 5.64165 13.2754 5.22744 13.2754H5.2207C4.80649 13.2754 4.4707 12.9396 4.4707 12.5254Z"
                          fill="#FA3559" />
                  </svg>

                  <span>
                      {{ getDateCreation(complaint.DateCreation) }}
                  </span>
              </div>
          </div>
          <div class="description">
              <p style="color: #656877 !important;">
                {{ complaint.Description }}
              </p>
          </div>
      </a>
    </div>
      <!-- Load more button -->
     <div v-if="claimsToShow < filteredClaims.length" class="d-flex justify-content-center">
       <button
          @click="loadMore"
          class="btn btn-primary mt-3"
        >
         {{ $t('COMPLAINT_SIDEBAR.LOAD_MORE') }}
      </button>
    </div>
    <woot-modal
      :show.sync="showModal"
      :on-close="closeAdvanceFiltersModal"
      size="small"
    >
      <complaints-filter
        :claims-reference="claimsReference"
        :on-close="closeAdvanceFiltersModal"
        :initial-applied-filter="appliedFilterRef"
        @applyFilter="onApplyFilter"
      >
      </complaints-filter>
    </woot-modal>
 </div>
</template>

<script>
import ComplaintsFilter from './ComplaintsFilter.vue';
export default {
   name: 'AssuranceComplaints',
   components: {
     ComplaintsFilter
   },
   data() {
     return {
       updatedClaims: [],
       showFiltersModal: false,
       appliedFilterRef: this.$t('COMPLAINT_SIDEBAR.COMPLAINT_FILTER.ALL_REF'),
       claimsToShow: 5, // Initial number of claims to display
     }
   },
   props: {
     clientAllClaims: {
       type: Array,
       default: () => [],
     },
   },
   mounted() {
     this.updateComplaints();
   },
   computed: {
     claims() {
       return this.updatedClaims;
     },
     claimsReference() {
       let claimsArray = this.claims.map((claim, index) => ({
         value: claim.numeroTicket
       }));
       claimsArray.unshift({ value: this.$t('COMPLAINT_SIDEBAR.COMPLAINT_FILTER.ALL_REF')});
       return claimsArray;
     },
     showModal() {
       return this.showFiltersModal ;
     },
     filteredClaims() {
       if (this.appliedFilterRef === this.$t('COMPLAINT_SIDEBAR.COMPLAINT_FILTER.ALL_REF')) {
         return this.claims;
       }
       else {
         const claims = [];
         this.claims.forEach(claim => {
           if (claim.numeroTicket === this.appliedFilterRef) {
             claims.push(claim);
           }
         });
         return claims;
       }
     },
     visibleClaims() {
       return this.sortedClaims.slice(0, this.claimsToShow);
     },
     // Sort claims from most recent to oldest
     sortedClaims() {
       return this.filteredClaims
         .slice()
         .sort((a, b) => new Date(b.DateCreation) - new Date(a.DateCreation));
     },
   },
   methods: {
     getDateCreation(date) {
       let dateObj = new Date(date) ;
       let day = dateObj.getUTCDate();
       let mounth = dateObj.getUTCMonth() + 1;
       let year = dateObj.getUTCFullYear();
       let creationDay = (day < 10 ? '0' : '') + day;
       let creationMounth = (mounth < 10 ? '0' : '') + mounth;
       let dateFormatee = creationDay + "/" + creationMounth + "/" + year;
       return dateFormatee;
     },
     async updateComplaints() {
       for (const complaint of this.clientAllClaims) {
         let redirectUrl = '';
         redirectUrl = await this.getRedirectUrl(complaint.numeroTicket);
         this.updatedClaims.push({
           ...complaint,
           redirectUrl: redirectUrl,
         });
       }
     },
     async getRedirectUrl(numClaim) {
       const { appLogicURL } = window.convergenceConfig;
       const response = await axios.get(`${appLogicURL}/gat/ticketUrl`, {
         params: {
           numero: numClaim,
         },
       });
       let redirectUrl = response.data.RedirectUrl;
       return redirectUrl;
     },
     onToggleFilters() {
       this.showFiltersModal = true;
     },
     closeAdvanceFiltersModal() {
       this.showFiltersModal = false;
     },
     onApplyFilter(appliedRef) {
       this.appliedFilterRef = appliedRef;
       this.closeAdvanceFiltersModal();
     },
     loadMore() {
       this.claimsToShow += 5; // Load 5 additional claims with each click
     },
   }
};
</script>

<style scoped lang="scss">
.complaints {
 padding: 8px;

 /*display: flex;
 justify-content: center;
 position: relative;
 height: inherit;
 align-items: center;*/

   .complaint_action {
     margin-top: 1rem;
     display: flex;
     justify-content: space-between;
     align-items: center;
   }

   .complaints_title {
     font-size: large;
     font-weight: 500;
     color: #676776;
     margin-left: 1rem;
   }

   .complaint_card {
       background: #FFFFFF;
       border: 0.5px solid #dad7ea;
       border-radius: 8px;
       padding: 8px;

     .description {
       margin-top: 2rem;

     }
   }

   .complaint_header {
     justify-content: space-between;
     margin-bottom: 12px;
     align-items: baseline;

     .complaint_num {
       justify-content: space-between;
       padding: 0 0 5px 0;

       h4 {
         font-style: normal !important;
         font-weight: 501 !important;
         font-size: 14.9246px !important;
         line-height: 18px !important;
         letter-spacing: 0.1em !important;
         margin-top: 4px;
       }
     }


     .type_badge {
       padding: 6px 10px;
       font-style: normal;
       font-weight: 500;
       font-size: 12.4327px;
       line-height: 15px;
     }


     .accepted {
       color: #49CF48 !important;
       background: #F4FBF5 !important;
       border: 1px solid #49CF48 !important;
       border-radius: 5px !important;
     }

     .pending {
       color: #F8AB26 !important;
       background: #FFFBF4 !important;
       border: 1px solid #F8AB26 !important;
       border-radius: 5px !important;
     }
   }

   .complaints_body {

       .end_date {
           display: flex;
           margin-right: 1rem;
           span {
               font-style: normal;
               font-weight: 400;
               font-size: 13px;
               line-height: 14px;
               color: #FA3559;
               margin-top: 4px;
           }

           svg {
               opacity: .8;
               margin-right: 2px;
           }
       }

       .start_date {
           display: flex;

           span {
               font-style: normal;
               font-weight: 400;
               font-size: 12px;
               line-height: 14px;
               color: #49cf48;
               margin-top: 4px;
           }

           svg {
               opacity: .8;
               margin-right: 2px;
           }
       }
   }
}
.no-complaints-parent {
 display: flex;
 flex-direction: column;
 align-items: center;
}
.no-complaints {
  /*display: flex;
  justify-content: space-around;
  font-size: medium;
  font-weight: 500;
  color: #676776;*/

 display: flex;
 justify-content: space-around;
 margin-top: 50%;
 font-size: large;
 font-weight: 500;
 color: #676776;
}
.no-complaints-soon {
 display: flex;
 justify-content: space-around;
 font-size: medium;
 font-weight: 500;
 color: #676776;
}
.modal-container {
 width: 50rem !important;
}
</style>
