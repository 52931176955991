<template>
  <footer v-if="isFooterVisible" class="footer">
    <div class="left-aligned-wrap">
      <div class="page-meta">
        <strong>{{ firstIndex }}</strong>
        - <strong>{{ lastIndex }}</strong> {{ $t('SETTINGS.OF') }}
        <strong>{{ totalCount }}</strong> {{ $t('SETTINGS.ITEMS') }}
      </div>
    </div>
    <div class="right-aligned-wrap">
      <div
        v-if="totalCount"
        class="primary button-group pagination-button-group"
      >
          <woot-button
            size="small"
            variant="smooth"
            color-scheme="secondary"
            :is-disabled="hasPrevPage"
            @click="onPrevPage"
          >
              <fluent-icon icon="chevron-left" size="18" />
          </woot-button>
          <woot-button
            v-for="page in pageCount"
            :key="page"
            size="small"
            variant="smooth"
            color-scheme="secondary"
            :class="{ active: currentPage === page }"
            @click.prevent="onPageChange(page)"
          >
             {{ page }}
          </woot-button>
        <woot-button
            size="small"
            variant="smooth"
            color-scheme="secondary"
            :is-disabled="hasNextPage"
            @click="onNextPage"
        >
           <fluent-icon icon="chevron-right" size="18" />
        </woot-button>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  components: {},
    data() {
        return {
            of: this.$root.$i18n.locale === 'fr' ? 'de' : 'of',
            items: this.$root.$i18n.locale === 'fr' ? 'éléments' : 'items',
        };
    },
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    totalCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    isFooterVisible() {
      return this.totalCount && !(this.firstIndex > this.totalCount);
    },
    firstIndex() {
      return this.pageSize * (this.currentPage - 1) + 1;
    },
    lastIndex() {
      return Math.min(this.totalCount, this.pageSize * this.currentPage);
    },
    searchButtonClass() {
      return this.searchQuery !== '' ? 'show' : '';
    },
    hasLastPage() {
      return !!Math.ceil(this.totalCount / this.pageSize);
    },
    hasFirstPage() {
      return this.currentPage === 1;
    },
    hasNextPage() {
      return this.currentPage === Math.ceil(this.totalCount / this.pageSize);
    },
    hasPrevPage() {
      return this.currentPage === 1;
    },
    pageCount() {
      return Math.ceil(this.totalCount / this.pageSize);
    },
  },
  methods: {
    onNextPage() {
      if (this.hasNextPage) {
        return;
      }
      const newPage = this.currentPage + 1;
      this.onPageChange(newPage);
    },
    onPrevPage() {
      if (this.hasPrevPage) {
        return;
      }
      const newPage = this.currentPage - 1;
      this.onPageChange(newPage);
    },
    onFirstPage() {
      if (this.hasFirstPage) {
        return;
      }
      const newPage = 1;
      this.onPageChange(newPage);
    },
    onLastPage() {
      if (this.hasLastPage) {
        return;
      }
      const newPage = Math.ceil(this.totalCount / this.pageSize);
      this.onPageChange(newPage);
    },
    onPageChange(page) {
      this.$emit('page-change', page);
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--space-normal);
}
.page-meta {
  font-size: var(--font-size-mini);
}

.goto-first,
.goto-last {
  i:last-child {
    margin-left: var(--space-minus-smaller);
  }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination .woot-button {
  margin: 0 5px;
}

.pagination .woot-button.active {
  font-weight: bold;
}
</style>
