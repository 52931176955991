<template>
    <div class="preferencesSection">
        <div class="d-flex mt-2px">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.25 6C15.4926 6 16.5 4.99264 16.5 3.75C16.5 2.50736 15.4926 1.5 14.25 1.5C13.0074 1.5 12 2.50736 12 3.75C12 4.99264 13.0074 6 14.25 6Z" stroke="#dfdfdf" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.25 9.75H9" stroke="#dfdfdf" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.25 12.75H12" stroke="#dfdfdf" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.5 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 15 3 16.5 6.75 16.5H11.25C15 16.5 16.5 15 16.5 11.25V7.5" stroke="#dfdfdf" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span class="typoNumbers orangeNumber" style="color: lightgrey">
                02
            </span>
        </div>

        <svg width="1" height="24" viewBox="0 0 1 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="0.5" y1="0.5" x2="0.499999" y2="22.8333" stroke="#A8A8A8" stroke-linecap="round" />
        </svg>

        <div class="d-flex mt-2px">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 9C11.0711 9 12.75 7.32107 12.75 5.25C12.75 3.17893 11.0711 1.5 9 1.5C6.92893 1.5 5.25 3.17893 5.25 5.25C5.25 7.32107 6.92893 9 9 9Z" stroke="#dfdfdf" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15.4425 16.5C15.4425 13.5975 12.555 11.25 8.99999 11.25C5.44499 11.25 2.5575 13.5975 2.5575 16.5" stroke="#dfdfdf" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <span class="typoNumbers redNumber" style="color: lightgrey">
                01
            </span>
        </div>

        <svg width="1" height="24" viewBox="0 0 1 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="0.5" y1="0.5" x2="0.499999" y2="22.8333" stroke="#A8A8A8" stroke-linecap="round" />
        </svg>

        <div class="d-flex mt-2px">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.81 6.43503V11.565C15.81 12.405 15.36 13.185 14.6325 13.6125L10.1775 16.185C9.44999 16.605 8.55 16.605 7.815 16.185L3.36 13.6125C2.6325 13.1925 2.1825 12.4125 2.1825 11.565V6.43503C2.1825 5.59503 2.6325 4.81499 3.36 4.38749L7.815 1.815C8.5425 1.395 9.44249 1.395 10.1775 1.815L14.6325 4.38749C15.36 4.81499 15.81 5.58753 15.81 6.43503Z" stroke="#dfdfdf" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8.99994 8.24998C9.96506 8.24998 10.7474 7.46759 10.7474 6.50247C10.7474 5.53735 9.96506 4.755 8.99994 4.755C8.03482 4.755 7.25244 5.53735 7.25244 6.50247C7.25244 7.46759 8.03482 8.24998 8.99994 8.24998Z" stroke="#dfdfdf" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 12.495C12 11.145 10.6575 10.05 9 10.05C7.3425 10.05 6 11.145 6 12.495" stroke="#dfdfdf" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <span class="typoNumbers greenNumber" style="color: lightgrey">
                01
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: "AssurancePreferances",
}
</script>

<style scoped lang="scss">
.preferencesSection {
  background: #FFFFFF;
  border-radius: 8px ;
  display: flex;
  justify-content: space-between;
  padding: 8px 26px;
  margin: 10px 12px ;
}

.mt-2px {
  margin-top: 2px;
}

.typoNumbers {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  margin-top: 3px;
  margin-left: 5px;
}

.greenNumber {
  color: #49CF48;
}

.redNumber {
  color: #FA3559;
}

.orangeNumber {
  color: #F8AB26;
}

.primaryNumber {
  color: #483A9D;
}
</style>
