import AppContainer from './Dashboard';
import settings from './settings/settings.routes';
import conversation from './conversation/conversation.routes';
import { routes as searchRoutes } from '../../modules/search/search.routes';
import { routes as contactRoutes } from './contacts/routes';
import { routes as notificationRoutes } from './notifications/routes';
import { frontendURL } from '../../helper/URLHelper';
import helpcenterRoutes from './helpcenter/helpcenter.routes';
import store from '../../store/index';

const Suspended = () => import('./suspended/Index');

/* check if the user has permission to view the feature corresponding to root */
 export async function requireAuth(to, from, next) {
   await store.dispatch('accounts/get');
   await store.dispatch("setActiveAccount", {
     accountId: to.params.accountId,
   });
   let account = store.getters["accounts/getAccount"](to.params.accountId);
   let currentUser = store.getters.getCurrentUser ;
   if (currentUser.type === 'SuperAdmin') {
     return next();
   }
  else {
     if (Object.keys(account).length > 0) {
       let features = account.features ;
       let isFeatureAccessible = features[to.meta.permission] || false ;
       if (isFeatureAccessible) {
         return next();
       } else {
         return next(frontendURL(`accounts/:account_id/dashboard`));
       }
     }
  }

 }

export default {
  routes: [
    ...helpcenterRoutes.routes,
    {
      path: frontendURL('accounts/:account_id'),
      component: AppContainer,
      children: [
        ...conversation.routes,
        ...settings.routes,
        ...contactRoutes,
        ...searchRoutes,
        ...notificationRoutes,
      ],
    },
    {
      path: frontendURL('accounts/:accountId/suspended'),
      name: 'account_suspended',
      roles: ['administrator', 'agent'],
      component: Suspended,
    },
  ],
};
