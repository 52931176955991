<template>
  <div class="view-box fill-height">
    <div class="user-info d-flex justify-space-between">
      <div class="d-flex">
        <Thumbnail
          :src="currentContact.thumbnail"
          size="49px"
          :username="currentContact.name"
          :status="currentContact.availability_status"
        />
        <div style="margin-left: 7px">
          <div class="client_name">{{ currentContact.name }}</div>
          <div class="client_email" v-if="currentContact.email">
            <svg
              style="margin-bottom: -3px;"
              width="14"
              height="14"
              fill="none"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              class="icon--font mail"
            >
              <path
                d="M5.25 4h13.5a3.25 3.25 0 0 1 3.245 3.066L22 7.25v9.5a3.25 3.25 0 0 1-3.066 3.245L18.75 20H5.25a3.25 3.25 0 0 1-3.245-3.066L2 16.75v-9.5a3.25 3.25 0 0 1 3.066-3.245L5.25 4h13.5-13.5ZM20.5 9.373l-8.15 4.29a.75.75 0 0 1-.603.043l-.096-.042L3.5 9.374v7.376a1.75 1.75 0 0 0 1.606 1.744l.144.006h13.5a1.75 1.75 0 0 0 1.744-1.607l.006-.143V9.373ZM18.75 5.5H5.25a1.75 1.75 0 0 0-1.744 1.606L3.5 7.25v.429l8.5 4.473 8.5-4.474V7.25a1.75 1.75 0 0 0-1.607-1.744L18.75 5.5Z"
                fill="currentColor"
              ></path>
            </svg>
            {{ currentContact.email }}
          </div>
          <div class="client_email" v-if="!currentContact.email">
            <svg
              style="margin-bottom: -3px;"
              width="14"
              height="14"
              fill="none"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              class="icon--font mail"
            >
              <path
                d="M5.25 4h13.5a3.25 3.25 0 0 1 3.245 3.066L22 7.25v9.5a3.25 3.25 0 0 1-3.066 3.245L18.75 20H5.25a3.25 3.25 0 0 1-3.245-3.066L2 16.75v-9.5a3.25 3.25 0 0 1 3.066-3.245L5.25 4h13.5-13.5ZM20.5 9.373l-8.15 4.29a.75.75 0 0 1-.603.043l-.096-.042L3.5 9.374v7.376a1.75 1.75 0 0 0 1.606 1.744l.144.006h13.5a1.75 1.75 0 0 0 1.744-1.607l.006-.143V9.373ZM18.75 5.5H5.25a1.75 1.75 0 0 0-1.744 1.606L3.5 7.25v.429l8.5 4.473 8.5-4.474V7.25a1.75 1.75 0 0 0-1.607-1.744L18.75 5.5Z"
                fill="currentColor"
              ></path>
            </svg>
            Not Available
          </div>
          <div class="client_email" v-if="!currentContact.phone_number">
            <svg
              width="14"
              height="14"
              fill="none"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              class="icon--font call"
            >
              <path
                d="m7.056 2.418 1.167-.351a2.75 2.75 0 0 1 3.302 1.505l.902 2.006a2.75 2.75 0 0 1-.633 3.139L10.3 10.11a.25.25 0 0 0-.078.155c-.044.397.225 1.17.845 2.245.451.781.86 1.33 1.207 1.637.242.215.375.261.432.245l2.01-.615a2.75 2.75 0 0 1 3.034 1.02l1.281 1.776a2.75 2.75 0 0 1-.339 3.605l-.886.84a3.75 3.75 0 0 1-3.587.889c-2.754-.769-5.223-3.093-7.435-6.924-2.215-3.836-2.992-7.14-2.276-9.913a3.75 3.75 0 0 1 2.548-2.652Zm.433 1.437a2.25 2.25 0 0 0-1.529 1.59c-.602 2.332.087 5.261 2.123 8.788 2.033 3.522 4.222 5.582 6.54 6.23a2.25 2.25 0 0 0 2.151-.534l.887-.84a1.25 1.25 0 0 0 .154-1.639l-1.28-1.775a1.25 1.25 0 0 0-1.38-.464l-2.015.617c-1.17.348-2.232-.593-3.372-2.568C9 11.93 8.642 10.9 8.731 10.099c.047-.416.24-.8.546-1.086l1.494-1.393a1.25 1.25 0 0 0 .288-1.427l-.902-2.006a1.25 1.25 0 0 0-1.5-.684l-1.168.352Z"
                fill="currentColor"
              ></path>
            </svg>
            Not Available
          </div>
          <div class="client_phone" v-if="currentContact.phone_number">
            <svg
              width="14"
              height="14"
              fill="none"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              class="icon--font call"
            >
              <path
                d="m7.056 2.418 1.167-.351a2.75 2.75 0 0 1 3.302 1.505l.902 2.006a2.75 2.75 0 0 1-.633 3.139L10.3 10.11a.25.25 0 0 0-.078.155c-.044.397.225 1.17.845 2.245.451.781.86 1.33 1.207 1.637.242.215.375.261.432.245l2.01-.615a2.75 2.75 0 0 1 3.034 1.02l1.281 1.776a2.75 2.75 0 0 1-.339 3.605l-.886.84a3.75 3.75 0 0 1-3.587.889c-2.754-.769-5.223-3.093-7.435-6.924-2.215-3.836-2.992-7.14-2.276-9.913a3.75 3.75 0 0 1 2.548-2.652Zm.433 1.437a2.25 2.25 0 0 0-1.529 1.59c-.602 2.332.087 5.261 2.123 8.788 2.033 3.522 4.222 5.582 6.54 6.23a2.25 2.25 0 0 0 2.151-.534l.887-.84a1.25 1.25 0 0 0 .154-1.639l-1.28-1.775a1.25 1.25 0 0 0-1.38-.464l-2.015.617c-1.17.348-2.232-.593-3.372-2.568C9 11.93 8.642 10.9 8.731 10.099c.047-.416.24-.8.546-1.086l1.494-1.393a1.25 1.25 0 0 0 .288-1.427l-.902-2.006a1.25 1.25 0 0 0-1.5-.684l-1.168.352Z"
                fill="currentColor"
              ></path>
            </svg>
            {{ currentContact.phone_number }}
          </div>
        </div>
      </div>
      <div class="d-flex">
        <svg
          v-if="!currentChat.muted"
          @click="mute"
          class="cursor-pointer custom-margin"
          width="21"
          height="21"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 10V14C2 16 3 17 5 17H6.43C6.8 17 7.17 17.11 7.49 17.3L10.41 19.13C12.93 20.71 15 19.56 15 16.59V7.41003C15 4.43003 12.93 3.29003 10.41 4.87003L7.49 6.70003C7.17 6.89003 6.8 7.00003 6.43 7.00003H5C3 7.00003 2 8.00003 2 10Z"
            stroke="black"
            stroke-width="1.5"
          />
          <path
            d="M18 8C19.78 10.37 19.78 13.63 18 16"
            stroke="black"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M19.8301 5.5C22.7201 9.35 22.7201 14.65 19.8301 18.5"
            stroke="black"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <svg
          v-if="currentChat.muted"
          @click="unmute"
          class="cursor-pointer custom-margin"
          width="21"
          height="21"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 8.37V7.41C15 4.43 12.93 3.29 10.41 4.87L7.49 6.7C7.17 6.89 6.8 7 6.43 7H5C3 7 2 8 2 10V14C2 16 3 17 5 17H7"
            stroke="#292D32"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10.41 19.13C12.93 20.71 15 19.56 15 16.59V12.95"
            stroke="#292D32"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M18.81 9.42C19.71 11.57 19.44 14.08 18 16"
            stroke="#292D32"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M21.15 7.8C22.62 11.29 22.18 15.37 19.83 18.5"
            stroke="#292D32"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M22 2L2 22"
            stroke="#292D32"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <svg
          @click="toggleEmailActionsModal"
          v-tooltip="$t('CONTACT_PANEL.SEND_TRANSCRIPT')"
          class="cursor-pointer custom-transcriptm mr-3"
          width="21"
          height="21"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.93217 3.93217C5.15414 2.7102 6.97603 2.25 9.3 2.25H11.1C11.5142 2.25 11.85 2.58579 11.85 3C11.85 3.41421 11.5142 3.75 11.1 3.75H9.3C7.12397 3.75 5.79586 4.1898 4.99283 4.99283C4.1898 5.79586 3.75 7.12397 3.75 9.3V14.7C3.75 16.876 4.1898 18.2041 4.99283 19.0072C5.79586 19.8102 7.12397 20.25 9.3 20.25H14.7C16.876 20.25 18.2041 19.8102 19.0072 19.0072C19.8102 18.2041 20.25 16.876 20.25 14.7V12.9C20.25 12.4858 20.5858 12.15 21 12.15C21.4142 12.15 21.75 12.4858 21.75 12.9V14.7C21.75 17.024 21.2898 18.8459 20.0678 20.0678C18.8459 21.2898 17.024 21.75 14.7 21.75H9.3C6.97603 21.75 5.15414 21.2898 3.93217 20.0678C2.7102 18.8459 2.25 17.024 2.25 14.7V9.3C2.25 6.97603 2.7102 5.15414 3.93217 3.93217Z"
            fill="#292D32"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.3514 2.70082C17.0391 2.97295 17.0065 3.4467 17.2786 3.75899L19.8387 6.6969L16.9008 9.25694C16.5885 9.52906 16.5559 10.0028 16.828 10.3151C17.1002 10.6274 17.5739 10.66 17.8862 10.3878L21.955 6.84235L18.4095 2.77355C18.1374 2.46126 17.6637 2.4287 17.3514 2.70082Z"
            fill="#292D32"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.4168 16.9536C7.67036 11.688 12.0919 6.91623 18.223 7.33762L20.8454 7.51785C21.2587 7.54625 21.6167 7.23428 21.6451 6.82104C21.6735 6.4078 21.3615 6.04978 20.9483 6.02138L18.3258 5.84115C11.3447 5.36134 6.03738 10.8557 6.93164 17.1642C6.98978 17.5743 7.36937 17.8596 7.77949 17.8015C8.1896 17.7433 8.47493 17.3637 8.4168 16.9536Z"
            fill="#292D32"
          />
        </svg>
        <resolve-action
          :conversation-id="currentChat.id"
          :status="currentChat.status"
        />
        <email-transcript-modal
          v-if="showEmailActionsModal"
          :show="showEmailActionsModal"
          :current-chat="currentChat"
          @cancel="toggleEmailActionsModal"
        />
      </div>
    </div>
    <div class="sidebar-toggle__wrap">
      <woot-button
        variant="smooth"
        size="tiny"
        color-scheme="secondary"
        class="sidebar-toggle--button"
        :icon="isRightOrLeftIcon"
        @click="onToggleContactPanel"
      />
    </div>
    <ul class="conversation-panel">
      <transition name="slide-up">
        <li class="spinner--container">
          <span v-if="shouldShowSpinner" class="spinner message" />
        </li>
      </transition>
      <message
        v-for="message in getReadMessages"
        :key="message.id"
        class="message--read"
        data-clarity-mask="True"
        :data="message"
        :is-a-tweet="isATweet"
        :is-a-whatsapp-channel="isAWhatsAppChannel"
        :has-instagram-story="hasInstagramStory"
        :is-web-widget-inbox="isAWebWidgetInbox"
      />
      <li v-show="getUnreadCount != 0" class="unread--toast">
        <span class="text-uppercase">
          {{ getUnreadCount }}
          {{
            getUnreadCount > 1
              ? $t('CONVERSATION.UNREAD_MESSAGES')
              : $t('CONVERSATION.UNREAD_MESSAGE')
          }}
        </span>
      </li>
      <message
        v-for="message in getUnReadMessages"
        :key="message.id"
        class="message--unread"
        data-clarity-mask="True"
        :data="message"
        :is-a-tweet="isATweet"
        :is-a-whatsapp-channel="isAWhatsAppChannel"
        :has-instagram-story="hasInstagramStory"
        :is-web-widget-inbox="isAWebWidgetInbox"
      />
    </ul>
    <div
      class="conversation-footer"
      :class="{ 'modal-mask': isPopoutReplyBox }"
    >
      <div v-if="isAnyoneTyping" class="typing-indicator-wrap">
        <div class="typing-indicator">
          {{ typingUserNames }}
          <img
            class="gif"
            src="~dashboard/assets/images/typing.gif"
            alt="Someone is typing"
          />
        </div>
      </div>
      <reply-box
        :conversation-id="currentChat.id"
        :is-a-tweet="isATweet"
        :selected-tweet="selectedTweet"
        :popout-reply-box.sync="isPopoutReplyBox"
        @click="showPopoutReplyBox"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ReplyBox from './ReplyBox';
import Message from './Message';
import conversationMixin, {
  filterDuplicateSourceMessages,
} from '../../../mixins/conversations';
import Banner from 'dashboard/components/ui/Banner.vue';
import { getTypingUsersText } from '../../../helper/commons';
import { BUS_EVENTS } from 'shared/constants/busEvents';
import { REPLY_POLICY } from 'shared/constants/links';
import inboxMixin from 'shared/mixins/inboxMixin';
import { calculateScrollTop } from './helpers/scrollTopCalculationHelper';
import { isEscape } from 'shared/helpers/KeyboardHelpers';
import eventListenerMixins from 'shared/mixins/eventListenerMixins';
import UserBanner from './WhatsappTemplates/UserBanner';
import Thumbnail from '../Thumbnail';
import ResolveAction from '../../buttons/ResolveAction';
import EmailTranscriptModal from './EmailTranscriptModal';

export default {
  components: {
    ResolveAction,
    Thumbnail,
    UserBanner,
    Message,
    ReplyBox,
    Banner,
    EmailTranscriptModal,
  },
  mixins: [conversationMixin, inboxMixin, eventListenerMixins],
  props: {
    isContactPanelOpen: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isLoadingPrevious: true,
      heightBeforeLoad: null,
      conversationPanel: null,
      selectedTweetId: null,
      isPopoutReplyBox: false,
      contactName: 'Amine Machfer',
      showEmailActionsModal: false,
    };
  },

  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      allConversations: 'getAllConversations',
      inboxesList: 'inboxes/getInboxes',
      listLoadingStatus: 'getAllMessagesLoaded',
      getUnreadCount: 'getUnreadCount',
      loadingChatList: 'getChatListLoadingStatus',
      conversationLastSeen: 'getConversationLastSeen',
    }),
    currentContact() {
      return this.$store.getters['contacts/getContact'](
        this.currentChat.meta.sender.id
      );
    },
    inboxId() {
      return this.currentChat.inbox_id;
    },
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.inboxId);
    },
    hasSelectedTweetId() {
      return !!this.selectedTweetId;
    },
    tweetBannerText() {
      return !this.selectedTweetId
        ? this.$t('CONVERSATION.SELECT_A_TWEET_TO_REPLY')
        : `
          ${this.$t('CONVERSATION.REPLYING_TO')}
          ${this.selectedTweet.content}` || '';
    },
    typingUsersList() {
      const userList = this.$store.getters[
        'conversationTypingStatus/getUserList'
      ](this.currentChat.id);
      return userList;
    },
    isAnyoneTyping() {
      const userList = this.typingUsersList;
      return userList.length !== 0;
    },
    typingUserNames() {
      const userList = this.typingUsersList;

      if (this.isAnyoneTyping) {
        const userListAsName = getTypingUsersText(userList);
        return userListAsName;
      }

      return '';
    },
    getMessages() {
      const messages = this.currentChat.messages || [];
      if (this.isAWhatsAppChannel) {
        return filterDuplicateSourceMessages(messages);
      }
      return messages;
    },
    getReadMessages() {
      return this.readMessages(
        this.getMessages,
        this.currentChat.agent_last_seen_at
      );
    },
    getUnReadMessages() {
      return this.unReadMessages(
        this.getMessages,
        this.currentChat.agent_last_seen_at
      );
    },
    shouldShowSpinner() {
      return (
        (this.currentChat && this.currentChat.dataFetched === undefined) ||
        (!this.listLoadingStatus && this.isLoadingPrevious)
      );
    },

    shouldLoadMoreChats() {
      return !this.listLoadingStatus && !this.isLoadingPrevious;
    },

    conversationType() {
      const { additional_attributes: additionalAttributes } = this.currentChat;
      const type = additionalAttributes ? additionalAttributes.type : '';
      return type || '';
    },

    isATweet() {
      return this.conversationType === 'tweet';
    },

    hasInstagramStory() {
      return this.conversationType === 'instagram_direct_message';
    },

    selectedTweet() {
      if (this.selectedTweetId) {
        const { messages = [] } = this.currentChat;
        const [selectedMessage] = messages.filter(
          message => message.id === this.selectedTweetId
        );
        return selectedMessage || {};
      }
      return '';
    },
    isRightOrLeftIcon() {
      if (this.isContactPanelOpen) {
        return 'arrow-chevron-right';
      }
      return 'arrow-chevron-left';
    },
    getLastSeenAt() {
      if (this.conversationLastSeen) return this.conversationLastSeen;
      const { contact_last_seen_at: contactLastSeenAt } = this.currentChat;
      return contactLastSeenAt;
    },

    replyWindowBannerMessage() {
      if (this.isAWhatsAppChannel) {
        return this.$t('CONVERSATION.TWILIO_WHATSAPP_CAN_REPLY');
      }
      if (this.isAPIInbox) {
        const { additional_attributes: additionalAttributes = {} } = this.inbox;
        if (additionalAttributes) {
          const {
            agent_reply_time_window_message: agentReplyTimeWindowMessage,
          } = additionalAttributes;
          return agentReplyTimeWindowMessage;
        }
        return '';
      }
      return this.$t('CONVERSATION.CANNOT_REPLY');
    },
    replyWindowLink() {
      if (this.isAWhatsAppChannel) {
        return REPLY_POLICY.FACEBOOK;
      }
      if (!this.isAPIInbox) {
        return REPLY_POLICY.TWILIO_WHATSAPP;
      }
      return '';
    },
    replyWindowLinkText() {
      if (this.isAWhatsAppChannel) {
        return this.$t('CONVERSATION.24_HOURS_WINDOW');
      }
      if (!this.isAPIInbox) {
        return this.$t('CONVERSATION.TWILIO_WHATSAPP_24_HOURS_WINDOW');
      }
      return '';
    },
    unreadMessageCount() {
      return this.currentChat.unread_count;
    },
  },

  watch: {
    currentChat(newChat, oldChat) {
      if (newChat.id === oldChat.id) {
        return;
      }
      this.fetchAllAttachmentsFromCurrentChat();
      this.selectedTweetId = null;
    },
  },

  created() {
    bus.$on(BUS_EVENTS.SCROLL_TO_MESSAGE, this.onScrollToMessage);
    bus.$on(BUS_EVENTS.SET_TWEET_REPLY, this.setSelectedTweet);
  },

  mounted() {
    this.addScrollListener();
    this.fetchAllAttachmentsFromCurrentChat();
  },

  beforeDestroy() {
    this.removeBusListeners();
    this.removeScrollListener();
  },

  methods: {
    fetchAllAttachmentsFromCurrentChat() {
      this.$store.dispatch('fetchAllAttachments', this.currentChat.id);
    },
    toggleEmailActionsModal() {
      this.showEmailActionsModal = !this.showEmailActionsModal;
    },
    removeBusListeners() {
      bus.$off(BUS_EVENTS.SCROLL_TO_MESSAGE, this.onScrollToMessage);
      bus.$off(BUS_EVENTS.SET_TWEET_REPLY, this.setSelectedTweet);
    },
    mute() {
      this.$store.dispatch('muteConversation', this.currentChat.id);
      this.showAlert(this.$t('CONTACT_PANEL.MUTED_SUCCESS'));
    },
    unmute() {
      this.$store.dispatch('unmuteConversation', this.currentChat.id);
      this.showAlert(this.$t('CONTACT_PANEL.UNMUTED_SUCCESS'));
    },
    setSelectedTweet(tweetId) {
      this.selectedTweetId = tweetId;
    },
    onScrollToMessage({ messageId = '' } = {}) {
      this.$nextTick(() => {
          const messageElement = document.getElementById('message' + messageId);
          if (messageElement) {
              messageElement.scrollIntoView({ behavior: 'smooth' });
              this.fetchPreviousMessages();
          }
          else {
              this.scrollToBottom();
          }
      });
      this.makeMessagesRead();
    },
    showPopoutReplyBox() {
      this.isPopoutReplyBox = !this.isPopoutReplyBox;
    },
    closePopoutReplyBox() {
      this.isPopoutReplyBox = false;
    },
    handleKeyEvents(e) {
      if (isEscape(e)) {
        this.closePopoutReplyBox();
      }
    },
    addScrollListener() {
      this.conversationPanel = this.$el.querySelector('.conversation-panel');
      this.setScrollParams();
      this.conversationPanel.addEventListener('scroll', this.handleScroll);
      this.$nextTick(() => this.scrollToBottom());
      this.isLoadingPrevious = false;
    },
    removeScrollListener() {
      this.conversationPanel.removeEventListener('scroll', this.handleScroll);
    },
    scrollToBottom() {
      let relevantMessages = [];
      if (this.getUnreadCount > 0) {
        // capturing only the unread messages
        relevantMessages = this.conversationPanel.querySelectorAll(
          '.message--unread'
        );
      } else {
        // capturing last message from the messages list
        relevantMessages = Array.from(
          this.conversationPanel.querySelectorAll('.message--read')
        ).slice(-1);
      }
      this.conversationPanel.scrollTop = calculateScrollTop(
        this.conversationPanel.scrollHeight,
        this.$el.scrollHeight,
        relevantMessages
      );
    },
    onToggleContactPanel() {
      this.$emit('contact-panel-toggle');
    },
    setScrollParams() {
      this.heightBeforeLoad = this.conversationPanel.scrollHeight;
      this.scrollTopBeforeLoad = this.conversationPanel.scrollTop;
    },

      async fetchPreviousMessages(scrollTop = 0) {
          this.setScrollParams();
          const shouldLoadMoreMessages =
              this.currentChat.dataFetched === true &&
              !this.listLoadingStatus &&
              !this.isLoadingPrevious;

          if (
              scrollTop < 100 &&
              !this.isLoadingPrevious &&
              shouldLoadMoreMessages
          ) {
              this.isLoadingPrevious = true;
              try {
                  await this.$store.dispatch('fetchPreviousMessages', {
                      conversationId: this.currentChat.id,
                      before: this.currentChat.messages[0].id,
                  });
                  const heightDifference =
                      this.conversationPanel.scrollHeight - this.heightBeforeLoad;
                  this.conversationPanel.scrollTop =
                      this.scrollTopBeforeLoad + heightDifference;
                  this.setScrollParams();
              } catch (error) {
                  // Ignore Error
              } finally {
                  this.isLoadingPrevious = false;
              }
          }
      },

    /*handleScroll(e) {
      this.setScrollParams();

      const dataFetchCheck =
        this.getMessages.dataFetched === true && this.shouldLoadMoreChats;
      if (
        e.target.scrollTop < 100 &&
        !this.isLoadingPrevious &&
        dataFetchCheck
      ) {
        this.isLoadingPrevious = true;
        this.$store
          .dispatch('fetchPreviousMessages', {
            conversationId: this.currentChat.id,
            before: this.getMessages.messages[0].id,
          })
          .then(() => {
            const heightDifference =
              this.conversationPanel.scrollHeight - this.heightBeforeLoad;
            this.conversationPanel.scrollTop =
              this.scrollTopBeforeLoad + heightDifference;
            this.isLoadingPrevious = false;
            this.setScrollParams();
          });
      }
    },*/

    handleScroll(e) {
      bus.$emit(BUS_EVENTS.ON_MESSAGE_LIST_SCROLL);
      this.fetchPreviousMessages(e.target.scrollTop);
    },

    makeMessagesRead() {
      this.$store.dispatch('markMessagesRead', { id: this.currentChat.id });
    },
    removeTweetSelection() {
      this.selectedTweetId = null;
    },
  },
};
</script>

<style scoped lang="scss">
.spinner--container {
  min-height: var(--space-jumbo);
}

.view-box.fill-height {
  height: auto;
  flex-grow: 1;
  min-width: 0;
}

.modal-mask {
  &::v-deep {
    .ProseMirror-woot-style {
      max-height: 40rem;
    }

    .reply-box {
      border: 1px solid var(--color-border);
      max-width: 120rem;
      width: 70%;
    }

    .reply-box .reply-box__top {
      position: relative;
      min-height: 44rem;
    }

    .reply-box__top .input {
      min-height: 44rem;
    }

    .emoji-dialog {
      position: fixed;
      left: unset;
      position: absolute;

      &::before {
        transform: rotate(0deg);
        left: var(--space-half);
        bottom: var(--space-minus-slab);
      }
    }
  }
}
.sidebar-toggle__wrap {
  display: flex;
  justify-content: flex-end;

  .sidebar-toggle--button {
    position: fixed;

    top: var(--space-mega);
    z-index: var(--z-index-low);

    background: var(--white);

    padding: inherit 0;
    border-top-left-radius: calc(
      var(--space-medium) + 1px
    ); /* 100px of height + 10px of border */
    border-bottom-left-radius: calc(
      var(--space-medium) + 1px
    ); /* 100px of height + 10px of border */
    border: 1px solid var(--color-border-light);
    border-right: 0;
    box-sizing: border-box;
  }
}

.custom-margin {
  margin-top: 1.6rem;
  margin-right: 1.5rem;
}

.custom-transcriptm {
  margin-top: 1.6rem;
}
</style>
