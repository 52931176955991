<template>
  <div class="column content-box">
    <woot-modal-header
      :header-title="$t('LABEL_MGMT.ADD.TITLE')"
      :header-content="$t('LABEL_MGMT.ADD.DESC')"
    />
    <form class="row" style="overflow: auto;" @submit.prevent="addLabel">
      <woot-input
        v-model.trim="title"
        :class="{ error: $v.title.$error }"
        class="medium-12 columns"
        :placeholder="$t('LABEL_MGMT.FORM.NAME.PLACEHOLDER')"
        :error="getLabelTitleErrorMessage"
        data-testid="label-title"
        @input="$v.title.$touch"
        @focus="closePicker"
        :maxlength="50"
      />

      <woot-input
        v-model.trim="description"
        :class="{ error: $v.description.$error }"
        class="medium-12 columns"
        :placeholder="$t('LABEL_MGMT.FORM.DESCRIPTION.PLACEHOLDER')"
        data-testid="label-description"
        @input="$v.description.$touch"
        @focus="closePicker"
        :maxlength="200"
      />

      <div class="medium-12">
        <label>
          {{ $t('LABEL_MGMT.FORM.COLOR.LABEL') }}
          <woot-color-picker v-model="color" :is-picker-close="isPickerClose" @update-picker="updatePicker" />
        </label>
      </div>
      <div class="medium-12">
        <input v-model="showOnSidebar" type="checkbox" :value="true" />
        <label for="conversation_creation">
          {{ $t('LABEL_MGMT.FORM.SHOW_ON_SIDEBAR.LABEL') }}
        </label>
      </div>
    </form>
    <div class="medium-12 columns" :style="{'direction': 'rtl'}" style="margin-bottom: 1rem; margin-right: 1rem;" >
      <woot-button
        :is-disabled="$v.title.$invalid || uiFlags.isCreating"
        :is-loading="uiFlags.isCreating"
        data-testid="label-submit"
        @click.prevent="addLabel"
      >
        {{ $t('LABEL_MGMT.FORM.CREATE') }}
      </woot-button>
      <woot-button class="button clear" @click.prevent="onClose">
        {{ $t('LABEL_MGMT.FORM.CANCEL') }}
      </woot-button>
    </div>
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import validationMixin from './validationMixin';
import { mapGetters } from 'vuex';
import validations from './validations';
import { getRandomColor } from 'dashboard/helper/labelColor';

export default {
  mixins: [alertMixin, validationMixin],
  data() {
    return {
      color: '#000',
      description: '',
      title: '',
      showOnSidebar: true,
      isPickerClose: false,
    };
  },
  validations,
  computed: {
    ...mapGetters({
      uiFlags: 'labels/getUIFlags',
    }),
  },
  mounted() {
    this.color = getRandomColor();
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    async addLabel() {
      try {
        await this.$store.dispatch('labels/create', {
          color: this.color,
          description: this.description,
          title: this.title,
          show_on_sidebar: this.showOnSidebar,
        });
        this.$emit('add');
        this.showAlert(this.$t('LABEL_MGMT.ADD.API.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (error) {
        const errorMessage =
          error.message || this.$t('LABEL_MGMT.ADD.API.ERROR_MESSAGE');
        this.showAlert(errorMessage);
      }
    },
    closePicker() {
      this.isPickerClose = true;
    },
    updatePicker(val) {
      this.isPickerClose = val;
    }
  },
};
</script>
