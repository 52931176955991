<template>
    <div class="ongoingOps">
        <div class="d-flex mt-2px">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 9.15039H11.25" stroke="#483A9D" stroke-miterlimit="10" stroke-linecap="round"
                    stroke-linejoin="round" />
                <path d="M6 12.1504H9.285" stroke="#483A9D" stroke-miterlimit="10" stroke-linecap="round"
                    stroke-linejoin="round" />
                <path
                    d="M7.5 4.5H10.5C12 4.5 12 3.75 12 3C12 1.5 11.25 1.5 10.5 1.5H7.5C6.75 1.5 6 1.5 6 3C6 4.5 6.75 4.5 7.5 4.5Z"
                    stroke="#483A9D" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path
                    d="M12 3.01465C14.4975 3.14965 15.75 4.07215 15.75 7.49965V11.9996C15.75 14.9996 15 16.4996 11.25 16.4996H6.75C3 16.4996 2.25 14.9996 2.25 11.9996V7.49965C2.25 4.07965 3.5025 3.14965 6 3.01465"
                    stroke="#483A9D" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

            <span v-if="clientContractsCount < 10" class="typoNumbers primaryNumber">
                0{{ clientContractsCount }}
            </span>
            <span v-else class="typoNumbers primaryNumber">
                {{ clientContractsCount }}
            </span>
        </div>

        <svg width="1" height="24" viewBox="0 0 1 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="0.5" y1="0.5" x2="0.499999" y2="22.8333" stroke="#A8A8A8" stroke-linecap="round" />
        </svg>

        <div class="d-flex mt-2px">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.5 17.0625H1.5C1.1925 17.0625 0.9375 16.8075 0.9375 16.5C0.9375 16.1925 1.1925 15.9375 1.5 15.9375H16.5C16.8075 15.9375 17.0625 16.1925 17.0625 16.5C17.0625 16.8075 16.8075 17.0625 16.5 17.0625Z" fill="#F8AB26"/>
                <path d="M15.75 17.0625H2.25C1.9425 17.0625 1.6875 16.8075 1.6875 16.5V11.25C1.6875 7.215 4.965 3.9375 9 3.9375C13.035 3.9375 16.3125 7.215 16.3125 11.25V16.5C16.3125 16.8075 16.0575 17.0625 15.75 17.0625ZM2.8125 15.9375H15.1875V11.25C15.1875 7.8375 12.4125 5.0625 9 5.0625C5.5875 5.0625 2.8125 7.8375 2.8125 11.25V15.9375Z" fill="#F8AB26"/>
                <path d="M9 2.8125C8.6925 2.8125 8.4375 2.5575 8.4375 2.25V1.5C8.4375 1.1925 8.6925 0.9375 9 0.9375C9.3075 0.9375 9.5625 1.1925 9.5625 1.5V2.25C9.5625 2.5575 9.3075 2.8125 9 2.8125Z" fill="#F8AB26"/>
                <path d="M3.75008 4.31258C3.60758 4.31258 3.46508 4.26008 3.35258 4.14758L2.60258 3.39758C2.38508 3.18008 2.38508 2.82008 2.60258 2.60258C2.82008 2.38508 3.18008 2.38508 3.39758 2.60258L4.14758 3.35258C4.36508 3.57008 4.36508 3.93008 4.14758 4.14758C4.03508 4.26008 3.89258 4.31258 3.75008 4.31258Z" fill="#F8AB26"/>
                <path d="M14.2501 4.31258C14.1076 4.31258 13.9651 4.26008 13.8526 4.14758C13.6351 3.93008 13.6351 3.57008 13.8526 3.35258L14.6026 2.60258C14.8201 2.38508 15.1801 2.38508 15.3976 2.60258C15.6151 2.82008 15.6151 3.18008 15.3976 3.39758L14.6476 4.14758C14.5351 4.26008 14.3926 4.31258 14.2501 4.31258Z" fill="#F8AB26"/>
            </svg>


            <span v-if="clientSinistersCount <10" class="typoNumbers orangeNumber">
                0{{ clientSinistersCount }}
            </span>
            <span v-else class="typoNumbers orangeNumber">
                {{ clientSinistersCount }}
            </span>
        </div>
        <svg width="1" height="24" viewBox="0 0 1 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="0.5" y1="0.5" x2="0.499999" y2="22.8333" stroke="#A8A8A8" stroke-linecap="round" />
        </svg>

        <div class="d-flex mt-2px">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M3.15486 0.98335C3.62242 0.641235 4.20532 0.424805 4.79015 0.424805H7.64015C7.91947 0.424805 8.25269 0.470166 8.56343 0.558947C8.84901 0.640541 9.18593 0.778457 9.43369 1.01126L11.7345 2.79252L11.0458 3.68209L8.69261 1.86026L8.6674 1.83505C8.61741 1.78507 8.4824 1.70581 8.25436 1.64066C8.0401 1.57944 7.81082 1.5498 7.64015 1.5498H4.79015C4.47497 1.5498 4.12038 1.67087 3.81918 1.89126C3.51839 2.11135 3.3103 2.40095 3.23585 2.69873L3.23089 2.71856L1.42228 8.21977L1.41988 8.22649C1.28789 8.59605 1.35398 8.88372 1.49299 9.07238C1.63465 9.26463 1.90483 9.4248 2.31515 9.4248H5.31515C6.09766 9.4248 6.74678 10.0934 6.62199 10.9669L6.62095 10.9741L6.24314 13.3918L6.23925 13.4093C6.15625 13.7828 6.4215 14.2632 6.84302 14.4037L6.8529 14.407L6.86265 14.4106C7.01488 14.4677 7.21494 14.4664 7.41979 14.396C7.62842 14.3243 7.77895 14.2025 7.84712 14.1003L7.8483 14.0985L10.9233 9.52352L11.857 10.1511L8.78317 14.7243C8.78296 14.7246 8.78276 14.7249 8.78255 14.7253C8.55066 15.0725 8.17652 15.3255 7.7855 15.4599C7.39323 15.5947 6.92235 15.6314 6.47746 15.4676C5.56039 15.1563 4.93308 14.1516 5.13689 13.1844L5.50871 10.8048C5.52287 10.7007 5.49229 10.6432 5.46524 10.6126C5.43386 10.577 5.38253 10.5498 5.31515 10.5498H2.31515C1.60046 10.5498 0.970643 10.26 0.587302 9.73973C0.202666 9.21772 0.117993 8.53337 0.357876 7.85526L2.14986 2.40463C2.30424 1.81195 2.69241 1.32173 3.15486 0.98335Z"
                    fill="#FA3559" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M12.2026 2.54458C12.1286 2.61292 12.0273 2.77588 12.0273 3.2375V10.5875C12.0273 11.0491 12.1286 11.2121 12.2026 11.2804C12.2877 11.359 12.483 11.45 12.9648 11.45H13.7148C14.1967 11.45 14.392 11.359 14.4771 11.2804C14.5511 11.2121 14.6523 11.0491 14.6523 10.5875V3.2375C14.6523 2.77588 14.5511 2.61292 14.4771 2.54458C14.392 2.46603 14.1967 2.375 13.7148 2.375H12.9648C12.483 2.375 12.2877 2.46603 12.2026 2.54458ZM11.4396 1.71792C11.842 1.34647 12.3967 1.25 12.9648 1.25H13.7148C14.283 1.25 14.8377 1.34647 15.2401 1.71792C15.6536 2.09958 15.7773 2.64912 15.7773 3.2375V10.5875C15.7773 11.1759 15.6536 11.7254 15.2401 12.1071C14.8377 12.4785 14.283 12.575 13.7148 12.575H12.9648C12.3967 12.575 11.842 12.4785 11.4396 12.1071C11.0261 11.7254 10.9023 11.1759 10.9023 10.5875V3.2375C10.9023 2.64912 11.0261 2.09958 11.4396 1.71792Z"
                    fill="#FA3559" />
            </svg>

            <span v-if="clientClaimsCount <10" class="typoNumbers redNumber ">
                0{{ clientClaimsCount }}
            </span>
            <span v-else class="typoNumbers redNumber ">
                {{ clientClaimsCount }}
            </span>
        </div>

        <svg width="1" height="24" viewBox="0 0 1 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="0.5" y1="0.5" x2="0.499999" y2="22.8333" stroke="#A8A8A8" stroke-linecap="round" />
        </svg>

        <div class="d-flex mt-2px">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 17.1075C8.4825 17.1075 7.995 16.845 7.65 16.3875L6.525 14.8875C6.5025 14.8575 6.4125 14.8125 6.3675 14.8125H6C2.8725 14.8125 0.9375 13.965 0.9375 9.75V6C0.9375 2.685 2.685 0.9375 6 0.9375H10.5C10.8075 0.9375 11.0625 1.1925 11.0625 1.5C11.0625 1.8075 10.8075 2.0625 10.5 2.0625H6C3.315 2.0625 2.0625 3.315 2.0625 6V9.75C2.0625 13.14 3.225 13.6875 6 13.6875H6.375C6.7575 13.6875 7.1925 13.905 7.425 14.2125L8.55 15.7125C8.8125 16.0575 9.1875 16.0575 9.45 15.7125L10.575 14.2125C10.8225 13.8825 11.2125 13.6875 11.625 13.6875H12C14.685 13.6875 15.9375 12.435 15.9375 9.75V7.5C15.9375 7.1925 16.1925 6.9375 16.5 6.9375C16.8075 6.9375 17.0625 7.1925 17.0625 7.5V9.75C17.0625 13.065 15.315 14.8125 12 14.8125H11.625C11.565 14.8125 11.5125 14.8425 11.475 14.8875L10.35 16.3875C10.005 16.845 9.5175 17.1075 9 17.1075Z" fill="#483A9D"/>
                <path d="M9 9C8.58 9 8.25 8.6625 8.25 8.25C8.25 7.8375 8.5875 7.5 9 7.5C9.4125 7.5 9.75 7.8375 9.75 8.25C9.75 8.6625 9.42 9 9 9Z" fill="#483A9D"/>
                <path d="M12 9C11.58 9 11.25 8.6625 11.25 8.25C11.25 7.8375 11.5875 7.5 12 7.5C12.4125 7.5 12.75 7.8375 12.75 8.25C12.75 8.6625 12.42 9 12 9Z" fill="#483A9D"/>
                <path d="M6 9C5.58 9 5.25 8.6625 5.25 8.25C5.25 7.8375 5.5875 7.5 6 7.5C6.4125 7.5 6.75 7.8375 6.75 8.25C6.75 8.6625 6.42 9 6 9Z" fill="#483A9D"/>
                <path d="M14.625 5.8125C13.2825 5.8125 12.1875 4.7175 12.1875 3.375C12.1875 2.0325 13.2825 0.9375 14.625 0.9375C15.9675 0.9375 17.0625 2.0325 17.0625 3.375C17.0625 4.7175 15.9675 5.8125 14.625 5.8125ZM14.625 2.0625C13.905 2.0625 13.3125 2.655 13.3125 3.375C13.3125 4.095 13.905 4.6875 14.625 4.6875C15.345 4.6875 15.9375 4.095 15.9375 3.375C15.9375 2.655 15.345 2.0625 14.625 2.0625Z" fill="#483A9D"/>
            </svg>

            <span v-if="conversationCount <10" class="typoNumbers primaryNumber">
                0{{ conversationCount }}
            </span>
            <span v-else class="typoNumbers primaryNumber">
                {{ conversationCount }}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: "OngoingOperations",
    props: {
      clientContractsCount: {
        type: Number,
        default: 0,
      },
      clientSinistersCount: {
        type: Number,
        default: 0,
      },
      clientClaimsCount: {
        type: Number,
        default: 0,
      },
      conversationCount: {
        type: Number,
        default: 0,
      }
    }
}
</script>

<style scoped lang="scss">
.ongoingOps {
    background: #FFFFFF;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    padding: 8px 26px;
    margin: 10px 12px;
}

.mt-2px {
    margin-top: 2px;
}


.typoNumbers {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    margin-top: 3px;
    margin-left: 5px;
}

.greenNumber {
    color: #49CF48;
}

.redNumber {
    color: #FA3559;
}

.orangeNumber {
    color: #F8AB26;
}

.primaryNumber {
    color: #483A9D;
}</style>
