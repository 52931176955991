<template>
  <div class="macro button secondary clear ">
    <span class="text-truncate">{{ macro.name }}</span>
    <div class="macros-actions">
      <woot-button
        v-tooltip.left-start="$t('MACROS.EXECUTE.PREVIEW')"
        size="tiny"
        variant="smooth"
        color-scheme="secondary"
        icon="info"
        class="margin-right-smaller"
        @click="toggleMacroPreview(macro)"
      />
      <woot-button
        v-tooltip.left-start="$t('MACROS.EXECUTE.BUTTON_TOOLTIP')"
        size="tiny"
        variant="smooth"
        color-scheme="secondary"
        icon="play-circle"
        :is-loading="isExecuting"
        @click="executeMacro(macro)"
      />
    </div>
    <transition name="menu-slide">
      <macro-preview
        v-if="showPreview"
        v-on-clickaway="closeMacroPreview"
        :macro="macro"
      />
    </transition>
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import { mixin as clickaway } from 'vue-clickaway';
import MacroPreview from './MacroPreview';
import { CONVERSATION_EVENTS } from '../../../../helper/AnalyticsHelper/events';
import {mapGetters} from "vuex";
export default {
  components: {
    MacroPreview,
  },
  mixins: [alertMixin, clickaway],
  props: {
    macro: {
      type: Object,
      required: true,
    },
    conversationId: {
      type: [Number, String],
      required: true,
    },
    inboxId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      isExecuting: false,
      showPreview: false,
      selectedAgents: [],
    };
  },
  computed: {
      ...mapGetters({
          currentUser: 'getCurrentUser', //to get current user details
      }),
  },
  methods: {
    async executeMacro(macro) {
        let agentList = [];
        if (this.currentUser.role=== 'administrator') {
            await this.getInboxMembers();
            agentList = this.selectedAgents.map(el => el.id);
            let agentsToAdd = []; // Un tableau pour stocker les agents à ajouter
            for (let i=0 ; i<macro.actions.length ; i++) {
                if (macro.actions[i].action_name ===  "assign_agent") {
                    let agentExists = false; // Reset variable at each iteration of the inner loop
                    for (let j=0; j<agentList.length; j++) {
                        if (agentList[j] === macro.actions[i].action_params[0]) {
                            agentExists = true;
                            break; // Exit the inner loop as soon as a match is found
                        }
                    }
                    if (!agentExists) {
                        agentsToAdd.push(macro.actions[i].action_params[0]);
                    }
                }
            }
            // Add agents from the agentsToAdd table to agentList
            agentList.push(...agentsToAdd);
        }
        try {
        this.isExecuting = true;
        if (this.currentUser.role=== 'administrator') {
            await this.$store.dispatch('inboxMembers/create', {
                inboxId: this.inboxId,
                agentList,
            });
        }
        await this.$store.dispatch('macros/execute', {
          macroId: macro.id,
          conversationIds: [this.conversationId],
        });
        this.$track(CONVERSATION_EVENTS.EXECUTED_A_MACRO);
        this.showAlert(this.$t('MACROS.EXECUTE.EXECUTED_SUCCESSFULLY'));
      } catch (error) {
        this.showAlert(this.$t('MACROS.ERROR'));
      } finally {
        this.isExecuting = false;
      }
    },
      /* to get inbox members */
      async getInboxMembers() {
        const response = await this.$store.dispatch('inboxMembers/get', {
            inboxId: this.inboxId,
        });
        const {
            data: { payload: inboxMembers },
        } = response;
        this.selectedAgents = inboxMembers ;
    },
    toggleMacroPreview() {
      this.showPreview = !this.showPreview;
    },
    closeMacroPreview() {
      this.showPreview = false;
    },
  },
};
</script>

<style scoped lang="scss">
.macro {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: var(--space-normal);

  .macros-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
</style>
