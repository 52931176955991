<template>
    <div class="sinister">
      <!--<div class="no-sinisters-parent">
        <span class="no-sinisters"> {{ $t('SINISTER_SIDEBAR.IN_PROGRESS') }} </span>
        <span class="no-sinisters-soon"> {{ $t('SINISTER_SIDEBAR.SOON') }} </span>
      </div>-->
      <div class="sinister_title">
        {{ $t('SINISTER_SIDEBAR.TITLE') }}
    </div>
    <div>
        <span v-if="!sinisters.length" class="no-sinisters">
          {{ $t('SINISTER_SIDEBAR.NO_SINISTERS') }}
        </span>
        <div
          class="sinister_card mt-2"
          v-for="(sinister, index) in sinisters"
          :key="index"
        >
          <a
            :href="`${sinister.redirectUrl}`"
            target="_blank"
            rel="noreferrer noopener nofollow"
          >
              <div class="sinister_body">
              <div class="sinister_num d-flex">
                  <h4>N°{{ sinister.numSinistre }}</h4>
              </div>
              <div class="police_number d-flex" style="margin-top: 15px;">
                  <h4>{{ $t('SINISTER_SIDEBAR.REIMBURSEMENT') }} :</h4>
                  <p >{{ sinister.cumulRemboursement }}</p>
              </div>
              <div class="date_block">
                  <div class="dates d-flex">
                      <h3>{{ $t('SINISTER_SIDEBAR.DATE') }} :</h3>
                      <div class="date_debut">
                          <svg width="15" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M6 4.3125C5.6925 4.3125 5.4375 4.0575 5.4375 3.75V1.5C5.4375 1.1925 5.6925 0.9375 6 0.9375C6.3075 0.9375 6.5625 1.1925 6.5625 1.5V3.75C6.5625 4.0575 6.3075 4.3125 6 4.3125Z" fill="#483A9D"/>
                              <path d="M12 4.3125C11.6925 4.3125 11.4375 4.0575 11.4375 3.75V1.5C11.4375 1.1925 11.6925 0.9375 12 0.9375C12.3075 0.9375 12.5625 1.1925 12.5625 1.5V3.75C12.5625 4.0575 12.3075 4.3125 12 4.3125Z" fill="#483A9D"/>
                              <path d="M15.375 7.37988H2.625C2.3175 7.37988 2.0625 7.12488 2.0625 6.81738C2.0625 6.50988 2.3175 6.25488 2.625 6.25488H15.375C15.6825 6.25488 15.9375 6.50988 15.9375 6.81738C15.9375 7.12488 15.6825 7.37988 15.375 7.37988Z" fill="#483A9D"/>
                              <path d="M12 17.0625H6C3.2625 17.0625 1.6875 15.4875 1.6875 12.75V6.375C1.6875 3.6375 3.2625 2.0625 6 2.0625H12C14.7375 2.0625 16.3125 3.6375 16.3125 6.375V12.75C16.3125 15.4875 14.7375 17.0625 12 17.0625ZM6 3.1875C3.855 3.1875 2.8125 4.23 2.8125 6.375V12.75C2.8125 14.895 3.855 15.9375 6 15.9375H12C14.145 15.9375 15.1875 14.895 15.1875 12.75V6.375C15.1875 4.23 14.145 3.1875 12 3.1875H6Z" fill="#483A9D"/>
                              <path d="M6.375 10.875C6.2775 10.875 6.18 10.8525 6.09 10.815C6 10.7775 5.91751 10.725 5.84251 10.6575C5.77501 10.5825 5.72249 10.5 5.68499 10.41C5.64749 10.32 5.625 10.2225 5.625 10.125C5.625 9.93001 5.70751 9.73502 5.84251 9.59252C5.91751 9.52502 6 9.4725 6.09 9.435C6.225 9.375 6.37501 9.36 6.52501 9.39C6.57001 9.3975 6.615 9.4125 6.66 9.435C6.705 9.45 6.75 9.47252 6.795 9.50252C6.8325 9.53252 6.86999 9.56252 6.90749 9.59252C6.93749 9.63002 6.97499 9.66751 6.99749 9.70501C7.02749 9.75001 7.05001 9.79501 7.06501 9.84001C7.08751 9.885 7.10251 9.93 7.11001 9.975C7.11751 10.0275 7.125 10.0725 7.125 10.125C7.125 10.32 7.04249 10.515 6.90749 10.6575C6.76499 10.7925 6.57 10.875 6.375 10.875Z" fill="#483A9D"/>
                              <path d="M9 10.8749C8.805 10.8749 8.61001 10.7924 8.46751 10.6574C8.43751 10.6199 8.40751 10.5824 8.37751 10.5449C8.34751 10.4999 8.32499 10.4549 8.30999 10.4099C8.28749 10.3649 8.27249 10.3199 8.26499 10.2749C8.25749 10.2224 8.25 10.1774 8.25 10.1249C8.25 10.0274 8.27249 9.9299 8.30999 9.8399C8.34749 9.7499 8.40001 9.66741 8.46751 9.59241C8.67751 9.38241 9.015 9.31489 9.285 9.43489C9.3825 9.47239 9.45749 9.52491 9.53249 9.59241C9.66749 9.73491 9.75 9.9299 9.75 10.1249C9.75 10.1774 9.74251 10.2224 9.73501 10.2749C9.72751 10.3199 9.71251 10.3649 9.69001 10.4099C9.67501 10.4549 9.65249 10.4999 9.62249 10.5449C9.59249 10.5824 9.56249 10.6199 9.53249 10.6574C9.45749 10.7249 9.3825 10.7774 9.285 10.8149C9.195 10.8524 9.0975 10.8749 9 10.8749Z" fill="#483A9D"/>
                              <path d="M6.375 13.4999C6.2775 13.4999 6.18 13.4774 6.09 13.4399C6 13.4024 5.91751 13.3499 5.84251 13.2824C5.77501 13.2074 5.72249 13.1324 5.68499 13.0349C5.64749 12.9449 5.625 12.8474 5.625 12.7499C5.625 12.5549 5.70751 12.3599 5.84251 12.2174C5.91751 12.1499 6 12.0974 6.09 12.0599C6.3675 11.9399 6.69749 12.0074 6.90749 12.2174C6.93749 12.2549 6.97499 12.2924 6.99749 12.3299C7.02749 12.3749 7.05001 12.4199 7.06501 12.4649C7.08751 12.5099 7.10251 12.5549 7.11001 12.6074C7.11751 12.6524 7.125 12.7049 7.125 12.7499C7.125 12.9449 7.04249 13.1399 6.90749 13.2824C6.76499 13.4174 6.57 13.4999 6.375 13.4999Z" fill="#483A9D"/>
                          </svg>
                          <span>{{ sinister.dateSurvenance }}</span>
                      </div>
                  </div>
              </div>
          </div>
          </a>
      </div>
    </div>
    </div>
</template>

<script>
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper';
import 'hooper/dist/hooper.css';
export default {
    name: 'Sinisters',
    components: {
        Hooper,
        Slide,
    },
    data() {
      return {
        updatedSinisters: []
      }
    },
    props: {
      clientAllSinisters: {
        type: Array,
        default: () => [],
      },
    },
    mounted() {
      this.updateSinisters();
    },
    computed: {
        sinisters() {
          return this.updatedSinisters;
        }
    },
    methods: {
      async updateSinisters() {
        for (const sinister of this.clientAllSinisters) {
            let redirectUrl = '';
            redirectUrl = await this.getRedirectUrl(sinister.numSinistre);
            this.updatedSinisters.push({
              ...sinister,
              redirectUrl: redirectUrl,
            });
        }
      },
      async getRedirectUrl(numSinister) {
        const { appLogicURL } = window.convergenceConfig;
        const response = await axios.get(`${appLogicURL}/gat/ticketUrl`, {
          params: {
            numero: numSinister,
          },
        });
        let redirectUrl = response.data.RedirectUrl;
        return redirectUrl;
      },
    }
};
</script>

<style scoped lang="scss">
.hooper-slide {
    margin-right: 13px !important;
    background: #FFFFFF !important;
    border-radius: 8px !important;
    width: fit-content !important;
}

.avatar {
    vertical-align: middle;
    width: 40px;
    height: 40px;
    border-radius: 10px;
}

.hooper-navigation {
    .hooper-slide {
        .is-disabled {
            display: none !important;
        }
    }
}

.sinister {
    padding: 8px;

    /*display: flex;
    justify-content: center;
    position: relative;
    height: inherit;
    align-items: center;*/

    .sinister_title {
      font-size: large;
      font-weight: 500;
      color: #676776;
      margin-left: 1rem;
      margin-top: 2rem;
      /*margin-bottom: 1rem;*/
    }

    .sinister_card {
        background: #FFFFFF;
        border: 0.5px solid #dad7ea;
        border-radius: 8px;
        padding: 12px;

        .sinister_body {

            .sinister_num {
                justify-content: space-between;
                padding: 0 0 5px 0;
                border-bottom: 1px solid #A49DCF;

                h4 {
                  font-style: normal !important;
                  font-weight: 501 !important;
                  font-size: 14.9246px !important;
                  line-height: 18px !important;
                  letter-spacing: 0.1em !important;
                  margin-top: 4px;
                }
            }

          .police_number {
            justify-content: space-between;

            h4 {
              font-style: normal !important;
              font-weight: 500 !important;
              font-size: 13.4687px !important;
              line-height: 16px !important;
              color: #656877 !important;
            }

            p {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 18px;
              color: #a9a0cf;
            }
          }

          .date_block {
            margin-top: 1rem;
            h3 {
              font-style: normal !important;
              font-weight: 571 !important;
              font-size: 13.4687px !important;
              line-height: 16px !important;
              color: #483A9D !important;
            }

            .dates {
              justify-content: space-between;
              margin-top: 2px;

              .date_debut {
                display: flex;

                span {
                  margin-left: 5px;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 13px;
                  line-height: 14px;
                  color: #656877;
                  margin-top: 3px;
                }

                svg {
                  opacity: .6;
                }
              }
            }
          }
        }
    }
}
.no-sinisters-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.no-sinisters {
  /*display: flex;
  justify-content: space-around;
  font-size: medium;
  font-weight: 500;
  color: #676776;*/

  display: flex;
  justify-content: space-around;
  margin-top: 50%;
  font-size: large;
  font-weight: 500;
  color: #676776;
}
.no-sinisters-soon {
  display: flex;
  justify-content: space-around;
  font-size: medium;
  font-weight: 500;
  color: #676776;
}
</style>
