<template>
    <div class="row vh-100">
        <div class="columns large-6 bg-white login-section-wrapper login">
            <div class="convergence__logo ">
                <img alt="" :src="require('../../assets/images/convergence_logo_log.png')">
            </div>
            <div class="login__form">
                <form @submit.prevent="login()">
                    <h5 class="fs-18 text-black font-inter">{{ $t('SET_NEW_PASSWORD.TITLE') }}</h5>
                    <div class="column log-in-form">
                        <input
                                v-model.trim="credentials.password"
                                type="password"
                                :placeholder="$t('SET_NEW_PASSWORD.PASSWORD.PLACEHOLDER')"
                                @input="$v.credentials.password.$touch"
                        />
                        <input
                                v-model.trim="credentials.confirmPassword"
                                type="password"
                                :placeholder="$t('SET_NEW_PASSWORD.CONFIRM_PASSWORD.PLACEHOLDER')"
                                @input="$v.credentials.confirmPassword.$touch"
                        />
                        <woot-submit-button
                                :disabled="
                                          $v.credentials.password.$invalid ||
                                          $v.credentials.confirmPassword.$invalid ||
                                          newPasswordAPI.showLoading
                                        "
                                :button-text="$t('SET_NEW_PASSWORD.SUBMIT')"
                                :loading="newPasswordAPI.showLoading"
                                button-class="expanded"
                                class="login_btn"
                        />
                    </div>
                </form>
            </div>
            <div class="copyright font-inter">
                Convergence © 2022 All rights reserved
            </div>
        </div>

        <div class="columns large-6 second-wrapper text-center "
             :style="{'background-image': `url(${require('../../assets/images/bg_conver.png')})`}"
        >
            <div class="description_conver">
                <h4 class="display-10 font-inter">Convergence</h4>
                <p class="text-gray-500 fs-14 mt-3 font-inter">Lorem Ipsum is simply dummy text of the printing and typesetting industry.  of type and scrambled it to make a type speci</p>
            </div>
        </div>
    </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import Auth from '../../api/auth';

import WootSubmitButton from '../../components/buttons/FormSubmitButton';
import { DEFAULT_REDIRECT_URL } from 'dashboard/constants/globals';

export default {
    components: {
        WootSubmitButton,
    },
    props: {
        resetPasswordToken: { type: String, default: '' },
        redirectUrl: { type: String, default: '' },
        config: { type: String, default: '' },
    },
    data() {
        return {
            // We need to initialize the component with any
            // properties that will be used in it
            credentials: {
                confirmPassword: '',
                password: '',
            },
            newPasswordAPI: {
                message: '',
                showLoading: false,
            },
            error: '',
        };
    },
    mounted() {
        // If url opened without token
        // redirect to login
        if (!this.resetPasswordToken) {
            window.location = DEFAULT_REDIRECT_URL;
        }
    },
    validations: {
        credentials: {
            password: {
                required,
                minLength: minLength(6),
            },
            confirmPassword: {
                required,
                minLength: minLength(6),
                isEqPassword(value) {
                    if (value !== this.credentials.password) {
                        return false;
                    }
                    return true;
                },
            },
        },
    },
    methods: {
        showAlert(message) {
            // Reset loading, current selected agent
            this.newPasswordAPI.showLoading = false;
            bus.$emit('newToastMessage', message);
        },
        login() {
            this.newPasswordAPI.showLoading = true;
            const credentials = {
                confirmPassword: this.credentials.confirmPassword,
                password: this.credentials.password,
                resetPasswordToken: this.resetPasswordToken,
            };
            Auth.setNewPassword(credentials)
                .then(res => {
                    if (res.status === 200) {
                        window.location = DEFAULT_REDIRECT_URL;
                    }
                })
                .catch(error => {
                    let errorMessage = this.$t('SET_NEW_PASSWORD.API.ERROR_MESSAGE');
                    if (error?.data?.message) {
                        errorMessage = error.data.message;
                    }
                    this.showAlert(errorMessage);
                });
        },
    },
};
</script>

<style scoped lang="scss">
@import "app/javascript/dashboard/assets/scss/components/_templogin.scss";
</style>
