<template>
  <div class="agent-table-container">
      <div>
          <p v-if="!agentMetrics.length">
              {{ $t('AGENT_MGMT.LIST.404') }}
          </p>
          <woot-loading-state
                  v-else-if="isLoading"
                  :message="$t('OVERVIEW_REPORTS.AGENT_CONVERSATIONS.LOADING_MESSAGE')"
          />
          <table v-else class="table table-hover mb-4">
              <tbody>
              <tr>
                  <th>{{ $t('OVERVIEW_REPORTS.AGENT_CONVERSATIONS.TABLE_HEADER.AGENT') }}</th>
                  <th>{{ $t('OVERVIEW_REPORTS.AGENT_CONVERSATIONS.TABLE_HEADER.RESOLVED') }}</th>
                  <th>{{ $t('OVERVIEW_REPORTS.AGENT_CONVERSATIONS.TABLE_HEADER.OPEN') }}</th>
                  <th>{{ $t('OVERVIEW_REPORTS.AGENT_CONVERSATIONS.TABLE_HEADER.UNATTENDED') }}</th>
              </tr>
              <tr v-for="(agent, index) in currentAgents" :key="agent.email" style="background: #F9F9F9;">
                  <td class="d-flex">
                      <thumbnail
                              :src="agent.thumbnail"
                              class="columns"
                              :username="agent.name"
                              size="30px"
                              :status="agent.availability"
                      />
                      <span class="ml-3 mt-2">{{ agent.name }}</span>
                  </td>
                  <td v-if="agentMetrics.length > 0">
                      {{ resolvedConversations[agent.id] }}
                  </td>
                  <td v-else>
                      0
                  </td>
                  <td>
                      {{ agent.metric.open }}
                  </td>
                  <td>
                      {{ agent.metric.unattended }}
                  </td>

              </tr>
              </tbody>
          </table>
      </div>
    <div v-if="agentMetrics.length > 0" class="table-pagination">
      <table-footer
                :current-page="currentPage"
                :total-count="agentMetrics.length"
                :page-size="3"
                @page-change="onPageNumberChange"
      />
    </div>
  </div>
</template>

<script>
import { VeTable, VePagination } from 'vue-easytable';
import Spinner from 'shared/components/Spinner.vue';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import rtlMixin from 'shared/mixins/rtlMixin';
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import TableFooter from 'dashboard/components/widgets/TableFooter';
import filterQueryGenerator from "../../../../../../helper/filterQueryGenerator";
import ConversationApi from '../../../../../../../dashboard/api/inbox/conversation';
import Vue from "vue";

export default {
  name: 'AgentTable',
  components: {
    EmptyState,
    Spinner,
    VeTable,
    VePagination,
    TableFooter,
    Thumbnail,
  },
  mixins: [rtlMixin],
  data() {
      return {
          currentPage: 1,
          resolvedConversations: {},
      }
  },
  props: {
    agents: {
      type: Array,
      default: () => [],
    },
    agentMetrics: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    pageIndex: {
      type: Number,
      default: 1,
    },
  },
  async mounted() {
    for (const agent of this.agentMetrics) {
      let val = await this.getResolvedConv(agent.id);
      Vue.set(this.resolvedConversations, agent.id, val);
    }
  },
  async updated() {
    for (const agent of this.agentMetrics) {
      let val = await this.getResolvedConv(agent.id);
      Vue.set(this.resolvedConversations, agent.id, val);
    }
  },
  computed: {
     currentAgents() {
          return this.agentMetrics.slice(
              (this.currentPage - 1) * 3,
              ((this.currentPage - 1) * 3) + 3
          )
    },
    tableData() {
      return this.agentMetrics.map(agent => {
        const agentInformation = this.getAgentInformation(agent.id) ;
        return {
          agent: agentInformation.name,
          email: agentInformation.email,
          thumbnail: agentInformation.thumbnail,
          open: agent.metric.open || 0,
          unattended: agent.metric.unattended || 0,
          status: agentInformation.availability_status,
        };
      });
    },
    columns() {
      return [
        {
          field: 'agent',
          key: 'agent',
          title: this.$t(
            'OVERVIEW_REPORTS.AGENT_CONVERSATIONS.TABLE_HEADER.AGENT'
          ),
          fixed: 'left',
          align: this.isRTLView ? 'right' : 'left',
          width: 25,
          renderBodyCell: ({ row }) => (
            <div class="row-user-block">
              <Thumbnail
                src={row.thumbnail}
                size="32px"
                username={row.agent}
                status={row.status}
              />
              <div class="user-block">
                <h6 class="title text-truncate">{row.agent}</h6>
                <span class="sub-title">{row.email}</span>
              </div>
            </div>
          ),
        },
        {
          field: 'open',
          key: 'open',
          title: this.$t(
            'OVERVIEW_REPORTS.AGENT_CONVERSATIONS.TABLE_HEADER.OPEN'
          ),
          align: this.isRTLView ? 'right' : 'left',
          width: 10,
        },
        {
          field: 'unattended',
          key: 'unattended',
          title: this.$t(
            'OVERVIEW_REPORTS.AGENT_CONVERSATIONS.TABLE_HEADER.UNATTENDED'
          ),
          align: this.isRTLView ? 'right' : 'left',
          width: 10,
        },
      ];
    },
  },
  methods: {
    async sleep(ms) {
          return new Promise(resolve => setTimeout(resolve, ms));
    },
    onPageNumberChange(pageIndex) {
      this.currentPage = pageIndex;
      /*this.$emit('page-change', pageIndex);*/
    },
    getAgentInformation(id) {
      return this.agents.find(agent => agent.id === Number(id));
    },
    /* number of resolved conversations by agent */
    async getResolvedConv(id) {
        try {
            let params = {
                queryData : {
                    payload : [
                        {
                            attribute_key:"status",
                            attribute_model:"standard",
                            custom_attribute_type:"",
                            filter_operator:"equal_to",
                            query_operator:"and",
                            values : ["resolved"],
                        },
                        {
                            attribute_key:"assignee_id",
                            custom_attribute_type:"",
                            filter_operator:"equal_to",
                            query_operator:undefined,
                            values : [id] ,
                        }
                    ]
                },
                page: 1,
            };
            const { data } = await ConversationApi.filter(params);
            let resolvedAgentConv = data.meta.all_count ;
            return resolvedAgentConv ;
        }
        catch (error) {
            throw error;
        }
    },
  },
};
</script>

<style lang="scss" scoped>
.agent-table-container {
  display: flex;
  flex-direction: column;
  flex: 1;

  .ve-table {
    &::v-deep {
      th.ve-table-header-th {
        font-size: var(--font-size-mini) !important;
        padding: var(--space-small) var(--space-two) !important;
      }

      td.ve-table-body-td {
        padding: var(--space-one) var(--space-two) !important;
      }
    }
  }

  &::v-deep .ve-pagination {
    background-color: transparent;
  }

  &::v-deep .ve-pagination-select {
    display: none;
  }

  .row-user-block {
    align-items: center;
    display: flex;
    text-align: left;

    .user-block {
      display: flex;
      flex-direction: column;
      min-width: 0;
      .title {
        font-size: var(--font-size-small);
        margin: var(--zero);
        line-height: 1;
      }
      .sub-title {
        font-size: var(--font-size-mini);
      }
    }

    /*.user-thumbnail-box {
      margin-right: var(--space-small);
    }*/
  }

  .table-pagination {
    margin-top: var(--space-normal);
    text-align: right;
  }
}

.agents-loader {
  align-items: center;
  display: flex;
  font-size: var(--font-size-default);
  justify-content: center;
  padding: var(--space-large);
}

/*.ve-table-sort-icon:active{
  color: #483a9d !important;
}*/

</style>
