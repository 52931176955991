<template>
    <div class="columns profile--settings">
        <tabs :tabs="settingsTab" :currentTab="currentTab" :wrapper-class="'default-tabs'" :tab-class="'default-tabs__item'"
            :tab-active-class="'default-tabs__item_active'" :line-class="'default-tabs__active-line'"
            @onClick="handleClick" />
        <div class="contentAccount" :style="{ backgroundImage: `url(${tabBackgroundImage})` }">
            <div v-if="currentTab === 'tab1'">
                <form v-if="!uiFlags.isFetchingItem" @submit.prevent="updateAccount">
                    <div class="small-10 columns">
                        <div class="d-flex">
                            <div class="small-3 columns">
                                <label :class="{ error: $v.name.$error }" class="right inline profile_labels">
                                    {{ $t('GENERAL_SETTINGS.FORM.NAME.LABEL') }}:
                                </label>
                            </div>
                            <div class="small-9 columns ml-5">
                                <input v-model="name" type="text"
                                    :placeholder="$t('GENERAL_SETTINGS.FORM.NAME.PLACEHOLDER')" class="profile_inputs"
                                    maxlength="50"
                                />
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="small-3 columns">
                                <label :class="{ error: $v.name.$error }" class="right inline profile_labels">
                                    {{ $t('GENERAL_SETTINGS.FORM.LANGUAGE.LABEL') }}:
                                </label>
                            </div>
                            <div class="small-9 columns ml-5">
                                <multiselect-dropdown
                                        :options="languagesSortedByCode"
                                        :selected-item="locale"
                                        :multiselector-title="$t('GENERAL_SETTINGS.FORM.LANGUAGE.SELECT')"
                                        :multiselector-placeholder="$t('GENERAL_SETTINGS.FORM.LANGUAGE.SELECT')"
                                        :has-thumbnail="false"
                                        :is-lang="true"
                                        @click="selectLangOption"
                                />
                            </div>
                        </div>
                        <div class="d-flex mt-4">
                            <div class="small-3 columns">
                                <label :class="{ error: $v.name.$error }" class="right inline profile_labels">
                                  {{ $t('GENERAL_SETTINGS.FORM.AUTO_RESOLVE_DURATION.NAME') }}:
                                </label>
                            </div>
                            <div class="small-9 columns ml-5">
                                <input v-model="autoResolveDuration" type="number" :placeholder="
                                    $t('GENERAL_SETTINGS.FORM.AUTO_RESOLVE_DURATION.PLACEHOLDER')
                                " @blur="$v.autoResolveDuration.$touch" class="profile_inputs" />
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="small-3 columns"></div>
                            <div class="small-9 columns ml-5">
                                <div class="d-flex  signature_text">
                                    {{ $t('GENERAL_SETTINGS.FORM.AUTO_RESOLVE_DURATION.LABEL') }}
                                </div>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="small-3 columns">
                                <label :class="{ error: $v.name.$error }" class="right inline profile_labels">
                                    {{ $t('GENERAL_SETTINGS.FORM.ACCOUNT_ID.TITLE') }}:
                                </label>
                            </div>
                            <div class="small-9 columns ml-5">
                                <woot-code :script="getAccountId" />
                            </div>
                        </div>
                        <div class="d-flex mb-2">
                            <div class="small-3 columns"></div>
                            <div class="small-9 columns ml-5">
                                <div class="d-flex mt-2 signature_text">
                                    {{ $t('GENERAL_SETTINGS.FORM.ACCOUNT_ID.NOTE') }}
                                </div>
                            </div>
                        </div>
                        <div class="d-flex mt-4">
                            <div class="small-3 columns"></div>
                            <div class="small-9 columns ml-5 d-flex justify-content-end">
                                <woot-submit-button class="button nice btn-primary  ml-5 custum_btn"
                                    :button-text="$t('GENERAL_SETTINGS.SUBMIT')" :loading="isUpdating" />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <woot-loading-state v-if="uiFlags.isFetchingItem" />
    </div>
</template>

<script>
import { required, minValue, maxValue } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import configMixin from 'shared/mixins/configMixin';
import accountMixin from '../../../../mixins/account';
import ChatTypeTabs from "../../../../components/widgets/ChatTypeTabs";
import Tabs from 'vue-tabs-with-active-line';
import Vue from 'vue';
import MultiselectDropdown from 'shared/components/ui/MultiselectDropdown.vue'  ;

const TABS = [{
    title: 'General settings',
    value: 'tab1',
}];


const semver = require('semver');

export default {
    components: { ChatTypeTabs, Tabs, MultiselectDropdown },
    mixins: [accountMixin, alertMixin, configMixin],
    data() {
        return {
            id: '',
            name: '',
            domain: '',
            supportEmail: '',
            features: {},
            autoResolveDuration: null,
            latestConvergenceVersion: null,
            tabs: TABS,
            currentTab: 'tab1',
        };
    },
    validations: {
        name: {
            required,
        },
        locale: {
            required,
        },
        autoResolveDuration: {
            minValue: minValue(1),
            maxValue: maxValue(999),
        },
    },
    computed: {
        ...mapGetters({
            globalConfig: 'globalConfig/get',
            getAccount: 'accounts/getAccount',
            uiFlags: 'accounts/getUIFlags',
        }),
        /* return the current language */
        locale() {
            for (let i = 0; i < this.languagesSortedByCode.length; i++) {
                if (this.languagesSortedByCode[i].iso_639_1_code === this.$root.$i18n.locale) {
                    return {
                        iso_639_1_code: this.$root.$i18n.locale,
                        name: this.languagesSortedByCode[i].name,
                    };
                }
            }
        },
        tabBackgroundImage() {
            switch (this.currentTab) {
                case "tab1":
                    return require('../../../../assets/images/informations_settings.svg');
                }
        },
        hasAnUpdateAvailable() {
            if (!semver.valid(this.latestConvergenceVersion)) {
                return false;
            }

            return semver.lt(
                this.globalConfig.appVersion,
                this.latestConvergenceVersion
            );
        },
        languagesSortedByCode() {
            const enabledLanguages = [...this.enabledLanguages];
            return enabledLanguages.sort((l1, l2) =>
                l1.iso_639_1_code.localeCompare(l2.iso_639_1_code)
            );
        },
        isUpdating() {
            return this.uiFlags.isUpdating;
        },

        featureInboundEmailEnabled() {
            return !!this.features.inbound_emails;
        },

        featureCustomDomainEmailEnabled() {
            return this.featureInboundEmailEnabled && !!this.customEmailDomainEnabled;
        },

        getAccountId() {
            return this.id.toString();
        },
        settingsTab() {
            return [{
              title: this.$t('GENERAL_SETTINGS.FORM.GENERAL_SECTION.TITLE'),
              value: 'tab1',
            }]
        },
    },
    mounted() {
        if (!this.id) {
            this.initializeAccount();
        }
    },
    methods: {
        handleClick(newTab) {
            this.currentTab = newTab;
        },
        async initializeAccount() {
            try {
                await this.$store.dispatch('accounts/get');
                const {
                    name,
                    locale,
                    id,
                    domain,
                    support_email,
                    custom_email_domain_enabled,
                    features,
                    auto_resolve_duration,
                    latest_convergence_version: latestConvergenceVersion,
                } = this.getAccount(this.accountId);

                this.$root.$i18n.locale = locale;
                this.name = name;
                this.locale.iso_639_1_code = locale; // to set tha language selected
                for (let i=0; i<this.languagesSortedByCode; i++) {
                    if (this.languagesSortedByCode[i].iso_639_1_code === this.$root.$i18n.locale) {
                        this.locale.name = this.languagesSortedByCode[i].name ;
                    }
                }
                this.id = id;
                this.domain = domain;
                this.supportEmail = support_email;
                this.customEmailDomainEnabled = custom_email_domain_enabled;
                this.features = features;
                this.autoResolveDuration = auto_resolve_duration;
                this.latestConvergenceVersion = latestConvergenceVersion;
            } catch (error) {
                // Ignore error
            }
        },

        async updateAccount() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.showAlert(this.$t('GENERAL_SETTINGS.FORM.ERROR'));
                return;
            }
            try {
                await this.$store.dispatch('accounts/update', {
                    locale: this.locale.iso_639_1_code,
                    name: this.name,
                    domain: this.domain,
                    support_email: this.supportEmail,
                    auto_resolve_duration: this.autoResolveDuration,
                });
                this.$root.$i18n.locale = this.locale.iso_639_1_code;
                this.showAlert(this.$t('GENERAL_SETTINGS.UPDATE.SUCCESS'));
            } catch (error) {
                this.showAlert(this.$t('GENERAL_SETTINGS.UPDATE.ERROR'));
            }
        },
        /* change the language */
        selectLangOption(language) {
            if (this.locale && this.locale.iso_639_1_code === language.iso_639_1_code) {
                for (let i = 0; i < this.languagesSortedByCode.length; i++) {
                    if (this.languagesSortedByCode[i].iso_639_1_code === this.$root.$i18n.locale) {
                        this.locale.iso_639_1_code = this.$root.$i18n.locale;
                        this.locale.name = this.languagesSortedByCode[i].name;
                        break;
                    }
                }
            }
            else {
                this.locale.iso_639_1_code = language.iso_639_1_code  ;
                this.locale.name = language.name;
            }
        },
    },
};
</script>

<style lang="scss">
@import '~dashboard/assets/scss/variables.scss';
@import '~dashboard/assets/scss/mixins.scss';

.profile--settings {
    padding: 24px;
    overflow: auto;
}

.profile--settings--row {
    //@include border-normal-bottom;
    padding: $space-normal;

    .small-3 {
        padding: $space-normal $space-medium $space-normal 0;
    }

    .small-9 {
        padding: $space-normal;
    }
}

.current-version {
    font-size: var(--font-size-small);
    text-align: center;
    padding: var(--space-normal);
}

.default-tabs {
    position: relative;
    margin: 0 auto;

    &__item {
        display: inline-block;
        margin: 0 5px;
        padding: 10px;
        padding-bottom: 8px;
        font-size: 16px;
        letter-spacing: 0.8px;
        color: gray;
        text-decoration: none;
        border: none;
        background-color: transparent;
        border-bottom: 2px solid transparent;
        cursor: pointer;
        transition: all 0.25s;

        &_active {
            color: black;
        }

        &:hover {
            border-bottom: 2px solid gray;
            color: black;
        }

        &:focus {
            outline: none;
            border-bottom: 2px solid gray;
            color: black;
        }

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &__active-line {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 2px;
        background-color: black;
        transition: transform 0.4s ease, width 0.4s ease;
    }
}

.contentAccount {
    margin-top: 30px;
    font-size: 20px;
    margin-top: 10px !important;
    height: auto !important;
    background-repeat: no-repeat;
    background-position: right 7% top 7%;
    border: 0.5px solid #A49DCF !important;
    border-radius: 8px !important;
    padding: 12rem 12rem 6rem 16rem !important;
    background-color: white;
    margin: 15px;

}
</style>
