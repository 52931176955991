<template>
    <div>
        <div class="multiselect-wrap--small">
            <contact-details-item
                    compact
                    :title="$t('CONVERSATION_SIDEBAR.ASSIGNEE_LABEL')"
            >
                <template v-slot:button>
                    <!--          <woot-button
                                v-if="showSelfAssign"
                                icon="arrow-right"
                                variant="link"
                                size="small"
                                @click="onSelfAssign"
                              >
                                {{ $t('CONVERSATION_SIDEBAR.SELF_ASSIGN') }}
                              </woot-button>-->
                </template>
            </contact-details-item>
            <multiselect-dropdown
                    :options="agentsList"
                    :selected-item="assignedAgent"
                    :multiselector-title="$t('AGENT_MGMT.MULTI_SELECTOR.TITLE.AGENT')"
                    :multiselector-placeholder="$t('AGENT_MGMT.MULTI_SELECTOR.PLACEHOLDER')"
                    :no-search-result="
          $t('AGENT_MGMT.MULTI_SELECTOR.SEARCH.NO_RESULTS.AGENT')
        "
        :input-placeholder="
          $t('AGENT_MGMT.MULTI_SELECTOR.SEARCH.PLACEHOLDER.AGENT')
        "
        @click="onClickAssignAgent"
      />
    </div>
    <div class="multiselect-wrap--small">
      <contact-details-item
        compact
        :title="$t('CONVERSATION_SIDEBAR.TEAM_LABEL')"
      />
      <multiselect-dropdown
        :options="teamsList"
        :selected-item="assignedTeam"
        :multiselector-title="$t('AGENT_MGMT.MULTI_SELECTOR.TITLE.TEAM')"
        :multiselector-placeholder="$t('AGENT_MGMT.MULTI_SELECTOR.PLACEHOLDER')"
        :no-search-result="
          $t('AGENT_MGMT.MULTI_SELECTOR.SEARCH.NO_RESULTS.TEAM')
        "
        :input-placeholder="
          $t('AGENT_MGMT.MULTI_SELECTOR.SEARCH.PLACEHOLDER.TEAM')
        "
        @click="onClickAssignTeam"
      />
    </div>
        <div class="multiselect-wrap--small">
            <contact-details-item
                    compact
                    :title="$t('CONVERSATION.PRIORITY.TITLE')"
            />
            <multiselect-dropdown
                    :options="priorityOptions"
                    :selected-item="assignedPriority"
                    :multiselector-title="$t('CONVERSATION.PRIORITY.CHANGE_PRIORITY.INPUT_PLACEHOLDER')"
                    :multiselector-placeholder="
          $t('CONVERSATION.PRIORITY.CHANGE_PRIORITY.SELECT_PLACEHOLDER')
        "
                    :no-search-result="
          $t('CONVERSATION.PRIORITY.CHANGE_PRIORITY.NO_RESULTS')
        "
                    :input-placeholder="
          $t('CONVERSATION.PRIORITY.CHANGE_PRIORITY.SEARCH')
        "
                    @click="onClickAssignPriority"
            />
        </div>
<!--    <contact-details-item
      compact
      :title="$t('CONVERSATION_SIDEBAR.ACCORDION.CONVERSATION_LABELS')"
    />
    <conversation-labels :conversation-id="conversationId" />-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import ContactDetailsItem from './ContactDetailsItem.vue';
import MultiselectDropdown from 'shared/components/ui/MultiselectDropdown.vue';
import ConversationLabels from './labels/LabelBox.vue';
import agentMixin from 'dashboard/mixins/agentMixin';
import teamMixin from 'dashboard/mixins/conversation/teamMixin';
import Thumbnail from '../../../components/widgets/Thumbnail';
import { CONVERSATION_PRIORITY } from '../../../../shared/constants/messages';
import { CONVERSATION_EVENTS } from '../../../helper/AnalyticsHelper/events';

export default {
  components: {
    Thumbnail,
    ContactDetailsItem,
    MultiselectDropdown,
    ConversationLabels,
  },
  mixins: [agentMixin, alertMixin, teamMixin],
  props: {
    conversationId: {
      type: [Number, String],
      required: true,
    },
    inboxId: {
      type: Number,
      default: undefined,
    },
  },
  data() {
        return {
            priorityOptions: [
                {
                    id: null,
                    name: this.$t('CONVERSATION.PRIORITY.OPTIONS.NONE'),
                    thumbnail: `/assets/images/dashboard/priority/none.svg`,
                },
                {
                    id: CONVERSATION_PRIORITY.URGENT,
                    name: this.$t('CONVERSATION.PRIORITY.OPTIONS.URGENT'),
                    thumbnail: `/assets/images/dashboard/priority/${CONVERSATION_PRIORITY.URGENT}.svg`,
                },
                {
                    id: CONVERSATION_PRIORITY.HIGH,
                    name: this.$t('CONVERSATION.PRIORITY.OPTIONS.HIGH'),
                    thumbnail: `/assets/images/dashboard/priority/${CONVERSATION_PRIORITY.HIGH}.svg`,
                },
                {
                    id: CONVERSATION_PRIORITY.MEDIUM,
                    name: this.$t('CONVERSATION.PRIORITY.OPTIONS.MEDIUM'),
                    thumbnail: `/assets/images/dashboard/priority/${CONVERSATION_PRIORITY.MEDIUM}.svg`,
                },
                {
                    id: CONVERSATION_PRIORITY.LOW,
                    name: this.$t('CONVERSATION.PRIORITY.OPTIONS.LOW'),
                    thumbnail: `/assets/images/dashboard/priority/${CONVERSATION_PRIORITY.LOW}.svg`,
                },
            ],
        };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      currentUser: 'getCurrentUser',
    }),
    assignedAgent: {
      get() {
        return this.currentChat.meta.assignee;
      },
      set(agent) {
        const agentId = agent ? agent.id : 0;
        this.$store.dispatch('setCurrentChatAssignee', agent);
        this.$store
          .dispatch('assignAgent', {
            conversationId: this.currentChat.id,
            agentId,
          })
          .then(() => {
            this.showAlert(this.$t('CONVERSATION.CHANGE_AGENT'));
          });
      },
    },
    assignedTeam: {
      get() {
        return this.currentChat.meta.team;
      },
      set(team) {
        const conversationId = this.currentChat.id;
        const teamId = team ? team.id : 0;
        this.$store.dispatch('setCurrentChatTeam', { team, conversationId });
        this.$store
          .dispatch('assignTeam', { conversationId, teamId })
          .then(() => {
            this.showAlert(this.$t('CONVERSATION.CHANGE_TEAM'));
          });
      },
    },
      assignedPriority: {
          get() {
              const selectedOption = this.priorityOptions.find(
                  opt => opt.id === this.currentChat.priority
              );

              return selectedOption || this.priorityOptions[0];
          },
          set(priorityItem) {
              const conversationId = this.currentChat.id;
              const oldValue = this.currentChat?.priority;
              const priority = priorityItem ? priorityItem.id : null;

              this.$store.dispatch('setCurrentChatPriority', {
                  priority,
                  conversationId,
              });
              this.$store
                  .dispatch('assignPriority', { conversationId, priority })
                  .then(() => {
                      this.$track(CONVERSATION_EVENTS.CHANGE_PRIORITY, {
                          oldValue,
                          newValue: priority,
                          from: 'Conversation Sidebar',
                      });
                      this.showAlert(
                          this.$t('CONVERSATION.PRIORITY.CHANGE_PRIORITY.SUCCESSFUL', {
                              priority: priorityItem.name,
                              conversationId,
                          })
                      );
                  });
          },
      },
    showSelfAssign() {
      if (!this.assignedAgent) {
        return true;
      }
      if (this.assignedAgent.id !== this.currentUser.id) {
        return true;
      }
      return false;
    },
  },
  methods: {
    onSelfAssign() {
      const {
        account_id,
        availability_status,
        available_name,
        email,
        id,
        name,
        role,
        avatar_url,
      } = this.currentUser;
      const selfAssign = {
        account_id,
        availability_status,
        available_name,
        email,
        id,
        name,
        role,
        thumbnail: avatar_url,
      };
      this.assignedAgent = selfAssign;
    },
    onClickAssignAgent(selectedItem) {
      if (this.assignedAgent && this.assignedAgent.id === selectedItem.id) {
        this.assignedAgent = null;
      } else {
        this.assignedAgent = selectedItem;
      }
    },

    onClickAssignTeam(selectedItemTeam) {
      if (this.assignedTeam && this.assignedTeam.id === selectedItemTeam.id) {
        this.assignedTeam = null;
      } else {
        this.assignedTeam = selectedItemTeam;
      }
    },

    onClickAssignPriority(selectedPriorityItem) {
      const isSamePriority =
        this.assignedPriority &&
        this.assignedPriority.id === selectedPriorityItem.id;

      this.assignedPriority = isSamePriority ? null : selectedPriorityItem;
    },
  },
};
</script>

<style scoped lang="scss">
.assigned_agent {
  background: #edecf6;
  border: 1px solid #dad8eb;
  border-radius: 8px;
  height: 30px;
  width: 145px;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  .name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #656877;
    margin-top: 2px;
  }
}
</style>
