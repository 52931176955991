<template>
  <div class="column content-box" style="padding: 0 13rem; background-color: white;">
    <woot-modal-header  class="modal_header" :header-title="pageTitle" />
    <form class="row" @submit.prevent="editCampaign">
      <div class="columns">
          <div class="mb-2 mt-2">
              <input
                      v-model="title"
                      type="text"
                      :class="{ error: $v.title.$error }"
                      :placeholder="$t('CAMPAIGN.ADD.FORM.TITLE.PLACEHOLDER')"
                      @blur="$v.title.$touch"
                      class="w-100 custom_input"
                      maxlength="50"
              />
          </div>

          <div class="mb-2 mt-3">
              <woot-message-editor
                      v-model.trim="message"
                      class="message-editor custom_txtarea"
                      :is-format-mode="true"
                      :class="{ editor_warning: $v.message.$error }"
                      :placeholder="$t('CAMPAIGN.ADD.FORM.MESSAGE.PLACEHOLDER')"
                      @input="$v.message.$touch"
              />
              <span v-if="$v.message.$error" class="editor-warning__message">
                {{ $t('CAMPAIGN.ADD.FORM.MESSAGE.ERROR') }}
              </span>
          </div>

          <div  class="mb-2 mt-3">
              <inbox-drop-down
                      :options="inboxes"
                      :selected-item="selectedInbox"
                      :from-report="true"
                      :multiselector-title="$t('NEW_CONVERSATION.FORM.INBOX.SELECT_INBOX')"
                      :no-search-result="
                      $t('NEW_CONVERSATION.FORM.INBOX.SEARCH.NO_RESULTS')
                    "
                      :input-placeholder="
                      $t('NEW_CONVERSATION.FORM.INBOX.SEARCH.PLACEHOLDER')
                    "
                      @click="onChangeInbox"
              />
              <span v-if="$v.selectedInbox.$error" class="message">
                {{ $t('CAMPAIGN.ADD.FORM.INBOX.ERROR') }}
             </span>
          </div>

          <div class="mb-2 mt-3">
              <inbox-drop-down
                      :options="sendersAndBotList"
                      :selected-item="selectedSender"
                      :from-report="true"
                      :multiselector-title="$t('CAMPAIGN.ADD.FORM.SENT_BY.PLACEHOLDER')"
                      :no-search-result="
                      $t('CAMPAIGN.ADD.FORM.SENT_BY.SEARCH.NO_RESULTS')
                    "
                      :input-placeholder="
                      $t('NEW_CONVERSATION.FORM.INBOX.SEARCH.PLACEHOLDER')
                    "
                      @click="onChangeSender"
              />
              <span v-if="$v.selectedSender.$error" class="message">
                {{ $t('CAMPAIGN.ADD.FORM.SENT_BY.ERROR') }}
              </span>
          </div>

          <div class="mb-2 mt-2">
              <input
                      class="custom_input"
                      v-model="endPoint"
                      type="text"
                      :class="{ error: $v.endPoint.$error }"
                      :placeholder="$t('CAMPAIGN.ADD.FORM.END_POINT.PLACEHOLDER')"
                      @blur="$v.endPoint.$touch"
              />
          </div>

          <div class="mb-2 mt-2">
              <input
                      v-model="timeOnPage"
                      type="text"
                      :class="{ error: $v.timeOnPage.$error }"
                      :placeholder="$t('CAMPAIGN.ADD.FORM.TIME_ON_PAGE.PLACEHOLDER')"
                      @blur="$v.timeOnPage.$touch"
                      class="custom_input"
              />
          </div>

          <div class="mb-2 mt-3 checkbox_style" >
              <input
                      v-model="enabled"
                      type="checkbox"
                      value="enabled"
                      name="enabled"
              />
              {{ $t('CAMPAIGN.ADD.FORM.ENABLED') }}
          </div>

          <div v-if="isOngoingType">
              <input
                      v-model="triggerOnlyDuringBusinessHours"
                      type="checkbox"
                      value="triggerOnlyDuringBusinessHours"
                      name="triggerOnlyDuringBusinessHours"
              />
              {{ $t('CAMPAIGN.ADD.FORM.TRIGGER_ONLY_BUSINESS_HOURS') }}
          </div>

          <div class="d-flex float-right" style="direction: rtl" >
              <woot-button class="mr-2" :is-loading="uiFlags.isCreating">
                  {{ $t('CAMPAIGN.EDIT.UPDATE_BUTTON_TEXT') }}
              </woot-button>
              <woot-button variant="clear" @click.prevent="onClose">
                  {{ $t('CAMPAIGN.ADD.CANCEL_BUTTON_TEXT') }}
              </woot-button>
          </div>

      </div>

    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor';
import alertMixin from 'shared/mixins/alertMixin';
import campaignMixin from 'shared/mixins/campaignMixin';
import { URLPattern } from 'urlpattern-polyfill';
import InboxDropDown from "../../conversation/contact/InboxDropDown.vue";

export default {
  components: {
    InboxDropDown,
    WootMessageEditor,
  },
  mixins: [alertMixin, campaignMixin],
  props: {
    selectedCampaign: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      title: '',
      message: '',
      selectedSender: {
          id: (this.selectedCampaign.sender && this.selectedCampaign.sender.id) || 0,
          name: (this.selectedCampaign.sender && this.selectedCampaign.sender.name) || 'Bot',
      },
      selectedInbox: {
          id: this.selectedCampaign.inbox.id,
          name: this.selectedCampaign.inbox.name,
      },
      endPoint: '',
      timeOnPage: 10,
      triggerOnlyDuringBusinessHours: false,
      show: true,
      enabled: true,
      senderList: [],
    };
  },
  validations: {
    title: {
      required,
    },
    message: {
      required,
    },
    selectedSender: {
      required,
    },
    endPoint: {
      required,
      shouldBeAValidURLPattern(value) {
        try {
          // eslint-disable-next-line
          new URLPattern(value);
          return true;
        } catch (error) {
          return false;
        }
      },
      shouldStartWithHTTP(value) {
        if (value) {
          return value.startsWith('https://') || value.startsWith('http://');
        }
        return false;
      },
    },
    timeOnPage: {
      required,
    },
    selectedInbox: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      uiFlags: 'campaigns/getUIFlags',
      inboxes: 'inboxes/getTwilioInboxes',
    }),
    inboxes() {
      if (this.isOngoingType) {
        return this.$store.getters['inboxes/getWebsiteInboxes'];
      }
      return this.$store.getters['inboxes/getSMSInboxes'];
    },
    pageTitle() {
      return `${this.$t('CAMPAIGN.EDIT.TITLE')} - ${
        this.selectedCampaign.title
      }`;
    },
    sendersAndBotList() {
      return [
        {
          id: 0,
          name: 'Bot',
        },
        ...this.senderList,
      ];
    },
  },
  mounted() {
    this.setFormValues();
  },
  methods: {
    onClose() {
      this.$emit('on-close');
    },

    async loadInboxMembers() {
      try {
        const response = await this.$store.dispatch('inboxMembers/get', {
          inboxId: this.selectedInbox.id,
        });
        const {
          data: { payload: inboxMembers },
        } = response;
        this.senderList = inboxMembers;
      } catch (error) {
        const errorMessage =
          error?.response?.message || this.$t('CAMPAIGN.ADD.API.ERROR_MESSAGE');
        this.showAlert(errorMessage);
      }
    },
    onChangeInbox(option) {
    if (this.selectedInbox && this.selectedInbox.id === option.id) {
         this.selectedInbox = {
              id: '',
              name: this.$t('NEW_CONVERSATION.FORM.INBOX.SELECT_INBOX'),
         };
    }
    else {
        this.selectedInbox = option;
        this.loadInboxMembers();
    }
    },
    setFormValues() {
      const {
        title,
        message,
        enabled,
        trigger_only_during_business_hours: triggerOnlyDuringBusinessHours,
        inbox,
        trigger_rules: { url: endPoint, time_on_page: timeOnPage },
        sender,
      } = this.selectedCampaign;
      this.title = title;
      this.message = message;
      this.endPoint = endPoint;
      this.timeOnPage = timeOnPage;
      this.selectedInbox = inbox;
      this.triggerOnlyDuringBusinessHours = triggerOnlyDuringBusinessHours;
      this.selectedSender = sender || {
                                        id: 0,
                                        name: 'Bot',
                                      } ;
      this.enabled = enabled;
      this.loadInboxMembers();
    },
    async editCampaign() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      try {
        await this.$store.dispatch('campaigns/update', {
          id: this.selectedCampaign.id,
          title: this.title,
          message: this.message,
          inbox_id: this.selectedInbox.id,
          trigger_only_during_business_hours:
            // eslint-disable-next-line prettier/prettier
            this.triggerOnlyDuringBusinessHours,
          sender_id: this.selectedSender.id || '',
          enabled: this.enabled,
          trigger_rules: {
            url: this.endPoint,
            time_on_page: this.timeOnPage,
          },
        });
        this.showAlert(this.$t('CAMPAIGN.EDIT.API.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (error) {
        this.showAlert(this.$t('CAMPAIGN.EDIT.API.ERROR_MESSAGE'));
      }
    },
    onChangeSender(option) {
        if (this.selectedSender && this.selectedSender.id === option.id) {
              this.selectedSender = {
                  id: 0,
                  name: 'Bot',
              };
        }
        else {
              this.selectedSender = option;
        }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .ProseMirror-woot-style {
  height: 8rem;
}
.modal_header{
  margin-top: 3rem !important;
  text-align: center;
}

.custom_input{
  border: 1px solid #737373;
  border-radius: 8px;
  height: 55px;
}

.custom_txtarea{
  border: 1px solid #737373;
  border-radius: 8px;
}


.checkbox_style{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #656877;
}
</style>
