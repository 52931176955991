<template>
  <div class="filters">
    <div class="filter" :class="{ error: v.values.$dirty && v.values.$error }">
      <div class="filter-inputs">
        <multiselect-dropdown
          ref="dropdownComponent0"
          v-if="groupedFilters"
          :options="allAttributes"
          :selected-item="propertyKey"
          :has-thumbnail="false"
          :filter-question="true"
          :close="closeDropdown0"
          @click="onQuestionSelect"
          @close="onChangeStatus0"
        />
<!--        <select
          v-if="groupedFilters"
          v-model="attrKey"
          class="filter__question"
          @change="resetFilter()"
        >
          <optgroup
            v-for="(group, i) in filterGroups"
            :key="i"
            :label="group.name"
          >
            <option
              v-for="attribute in group.attributes"
              :key="attribute.key"
              :value="attribute.key"
            >
              {{ attribute.name }}
            </option>
          </optgroup>
        </select>-->
        <multiselect-dropdown
          v-else
          ref="dropdownComponent1"
          :options="filterAttributes"
          :selected-item="attributeKey"
          :has-thumbnail="false"
          :filter-question="true"
          :close="closeDropdown1"
          @click="onQuestionSelect"
          @close="onChangeStatus1"
        />
<!--        <select
          v-else
          v-model="attributeKey"
          class="filter__question"
          @change="resetFilter()"
        >
          <option
            v-for="attribute in filterAttributes"
            :key="attribute.key"
            :value="attribute.key"
            :disabled="attribute.disabled"
          >
            {{ attribute.name }}
          </option>
        </select>-->
          <multiselect-dropdown
            ref="dropdownComponent2"
            :options="operators"
            :selected-item="filterOperator"
            :has-thumbnail="false"
            :is-operator-filter="true"
            :close="closeDropdown2"
            @click="onOperatorSelect"
            @close="onChangeStatus2"
          />
<!--        <select v-model="filterOperator" class="filter__operator">
          <option
            v-for="(operator, o) in operators"
            :key="o"
            :value="operator.value"
          >
            {{ $t(`FILTER.OPERATOR_LABELS.${operator.value}`) }}
          </option>
        </select>-->

        <div v-if="showUserInput" class="filter__answer--wrap">
          <div
            v-if="inputType === 'multi_select'"
            class="multiselect-wrap--small"
          >
            <multiselect
              ref="myMultiselect5"
              v-model="values"
              track-by="id"
              label="name"
              :placeholder="'Select'"
              :multiple="true"
              selected-label
              deselect-label=""
              :max-height="160"
              :options="dropdownValues"
              :show-labels="false"
              @open="handleOpen"
            />
          </div>
          <div
            v-else-if="inputType === 'search_select'"
            class="multiselect-wrap--small"
          >
              <multiselect-dropdown
                ref="dropdownComponent3"
                :options="dropdownValues"
                :selected-item="answerValue"
                :has-thumbnail="false"
                :is-answer-condition="true"
                :close="closeDropdown3"
                @click="onAnswerSelect"
                @close="onChangeStatus3"
              />
<!--            <multiselect
              v-model="values"
              track-by="id"
              label="name"
              :placeholder="'Select'"
              selected-label
              deselect-label=""
              :max-height="160"
              :options="dropdownValues"
              :show-labels="false"
            />-->
          </div>
          <div v-else-if="inputType === 'date'" class="multiselect-wrap--small">
            <input
              v-model="values"
              type="date"
              :editable="false"
              class="answer--text-input datepicker"
            />
          </div>
          <input
            v-else
            v-model="values"
            type="text"
            class="answer--text-input"
            placeholder="Enter value"
          />
        </div>
        <woot-button
          icon="dismiss"
          variant="clear"
          color-scheme="secondary"
          @click="removeFilter"
        />
      </div>
      <p v-if="v.values.$dirty && v.values.$error" class="filter-error">
        {{ $t('FILTER.EMPTY_VALUE_ERROR') }}
      </p>
    </div>

    <div v-if="showQueryOperator" class="filter__join-operator">
      <hr class="operator__line" />
      <multiselect-dropdown
        ref="dropdownComponent4"
        :options="operatorsOptions"
        :selected-item="queryOperator"
        :has-thumbnail="false"
        :is-query-operator="true"
        :close="closeDropdown4"
        @click="onQuerySelect"
        @close="onChangeStatus4"
      />
<!--      <select v-model="query_operator" class="operator__select">
        <option value="and">
          {{ $t('FILTER.QUERY_DROPDOWN_LABELS.AND') }}
        </option>
        <option value="or">
          {{ $t('FILTER.QUERY_DROPDOWN_LABELS.OR') }}
        </option>
      </select>-->
    </div>
  </div>
</template>

<script>
import MultiselectDropdown from 'shared/components/ui/MultiselectDropdown.vue'  ;
import {
  updateOptionForAutomationAction,
  updateOptionForAutomationEvent
} from "../../../routes/dashboard/settings/automation/constants";
export default {
  components: {
    MultiselectDropdown,
  },
  data() {
    return {
      isQuestionChange: false,
      operatorsOptions: [
        { value: 'and', name: this.$t('FILTER.QUERY_DROPDOWN_LABELS.AND') },
        { value: 'or', name: this.$t('FILTER.QUERY_DROPDOWN_LABELS.OR') }
      ],
      close1: false,
      close2: false,
      close3: false,
      close4: false,
      close0: false,
    }
  },
  props: {
    value: {
      type: Object,
      default: () => null,
    },
    filterAttributes: {
      type: Array,
      default: () => [],
    },
    inputType: {
      type: String,
      default: 'plain_text',
    },
    dataType: {
      type: String,
      default: 'plain_text',
    },
    operators: {
      type: Array,
      default: () => [],
    },
    dropdownValues: {
      type: Array,
      default: () => [],
    },
    showQueryOperator: {
      type: Boolean,
      default: false,
    },
    v: {
      type: Object,
      default: () => null,
    },
    showUserInput: {
      type: Boolean,
      default: true,
    },
    groupedFilters: {
      type: Boolean,
      default: false,
    },
    filterGroups: {
      type: Array,
      default: () => [],
    },
    customAttributeType: {
      type: String,
      default: '',
    },
    updateOptionForAutomationAction: {
      type: Function,
      default: () => {},
    },
    updateOptionForAutomationEvent: {
      type: Function,
      default: () => {},
    },
    index: {
      type: Number,
      required: true,
    }
  },
  mounted() {
    updateOptionForAutomationAction(this.$root.$i18n.locale);
    updateOptionForAutomationEvent(this.$root.$i18n.locale);
    document.addEventListener('mousedown', this.handleClickOutside1);
    document.addEventListener('mousedown', this.handleClickOutside2);
    document.addEventListener('mousedown', this.handleClickOutside3);
    document.addEventListener('mousedown', this.handleClickOutside4);
    document.addEventListener('mousedown', this.handleClickOutside5);
    document.addEventListener('mousedown', this.handleClickOutside0);
    bus.$on('multiselect-open', (source) => {
      if (source !== 'myMultiselect5') {
        this.closeMultiselect();
      }
    });
    bus.$on('multi-open', (sourceIndex) => {
      if (sourceIndex !== this.index) {
        this.onCloseDropdown0();
        this.onCloseDropdown1();
        this.onCloseDropdown2() ;
        this.onCloseDropdown3();
        this.onCloseDropdown4() ;
        this.closeMultiselect();
      }
    });
  },
  beforeDestroy() {
    bus.$off('multiselect-open');
    bus.$off('multi-open') ;
  },
  computed: {
    attributeKey() {
        if (!this.value) {
          return null;
        }
        else {
          let matchingAttribute = this.filterAttributes.find(attr => attr.key === this.value.attribute_key) ;
          let attributeKey = {
            key: this.value.attribute_key,
            name: matchingAttribute.name,
            disabled: this.value.disabled !== undefined ? this.value.disabled : false ,
          }
          return attributeKey;
        }
    },
    attrKey: {
        get() {
          if (!this.value) return null;
          return this.value.attribute_key;
        },
        set(value) {
          const payload = this.value || {};
          this.$emit('input', { ...payload, attribute_key: value });
        },
    },
    propertyKey() {
      if (!this.value) {
        return null;
      }
      else {
        return this.allAttributes.find(attr => attr.key === this.value.attribute_key);
      }
    },
    allAttributes() {
      if (this.groupedFilters) {
        return this.filterGroups.flatMap(group => group.attributes);
      }
    },
    filterOperator() {
        if (!this.value) {
          return null ;
        }
        else {
          let matchingAttribute = this.operators.find(attr => attr.value === this.value.filter_operator) ;
          let filterOperator = {
            value: this.value.filter_operator,
            label: matchingAttribute.label,
          }
          return filterOperator;
        }
    },
    values: {
      get() {
        if (!this.value) return null;
        return this.value.values;
      },
      set(value) {
        const payload = this.value || {};
        this.$emit('input', { ...payload, values: value });
      },
    },
    answerValue() {
      if (this.value.values === '' || this.isQuestionChange) {
        let answerItem = { id: 'select', name: this.$root.$i18n.locale === 'fr' ? 'Sélectionner' : 'Select' } ;
        this.isQuestionChange = false;
        return answerItem;
      }
      else {
        return this.value.values;
      }
    },
    query_operator: {
      get() {
        if (!this.value) return null;
        return this.value.query_operator;
      },
      set(value) {
        const payload = this.value || {};
        this.$emit('input', { ...payload, query_operator: value });
      },
    },
    queryOperator() {
      if (this.value.query_operator === 'and') {
        return this.operatorsOptions[0];
      }
      else if (this.value.query_operator === 'or') {
        return this.operatorsOptions[1];
      }
    },
    custom_attribute_type: {
      get() {
        if (!this.customAttributeType) return '';
        return this.customAttributeType;
      },
      set() {
        const payload = this.value || {};
        this.$emit('input', {
          ...payload,
          custom_attribute_type: this.customAttributeType,
        });
      },
    },
    closeDropdown0() {
      return this.close0;
    },
    closeDropdown1() {
      return this.close1;
    },
    closeDropdown2() {
      return this.close2;
    },
    closeDropdown3() {
      return this.close3;
    },
    closeDropdown4() {
      return this.close4;
    }
  },
  watch: {
    customAttributeType: {
      handler(value) {
        if (
          value === 'conversation_attribute' ||
          value === 'contact_attribute'
        ) {
          this.value.custom_attribute_type = this.customAttributeType;
        } else this.value.custom_attribute_type = '';
      },
      immediate: true,
    },
  },
  methods: {
    removeFilter() {
      this.$emit('removeFilter');
    },
    resetFilter() {
      this.$emit('resetFilter');
    },
    onQuestionSelect(attribute) {
      this.isQuestionChange = true;
      this.value.values = "";
      const payload = this.value || {};
      this.$emit('input', { ...payload, attribute_key: attribute.key });
    },
    onOperatorSelect(operator) {
      const payload = this.value || {};
      this.$emit('input', { ...payload, filter_operator: operator.value });
    },
    onAnswerSelect(answer) {
      const payload = this.value || {};
      this.$emit('input', { ...payload, values: answer });
    },
    onQuerySelect(query) {
      const payload = this.value || {};
      this.$emit('input', { ...payload, query_operator: query.value });
    },
    handleClickOutside0(event) {
      // Check that the reference exists before accessing it
      if (this.$refs.dropdownComponent0 && this.$refs.dropdownComponent0.$refs.dropdownElement) {
        const dropdownElement = this.$refs.dropdownComponent0.$refs.dropdownElement;
        if (!dropdownElement.contains(event.target)) {
          this.onCloseDropdown0();
        }
      }
    },
    onCloseDropdown0() {
      this.close0=true;
    },
    onChangeStatus0(value) {
      if (value) {
        this.close0 = false;
      }
    },
    handleClickOutside1(event) {
      // Check that the reference exists before accessing it
      if (this.$refs.dropdownComponent1 && this.$refs.dropdownComponent1.$refs.dropdownElement) {
        const dropdownElement = this.$refs.dropdownComponent1.$refs.dropdownElement;
        if (!dropdownElement.contains(event.target)) {
          this.onCloseDropdown1();
        }
      }
    },
    onCloseDropdown1() {
      this.close1=true;
    },
    onChangeStatus1(value) {
      if (value) {
        this.close1 = false;
      }
    },
    handleClickOutside2(event) {
      // Check that the reference exists before accessing it
      if (this.$refs.dropdownComponent2 && this.$refs.dropdownComponent2.$refs.dropdownElement) {
        const dropdownElement = this.$refs.dropdownComponent2.$refs.dropdownElement;
        if (!dropdownElement.contains(event.target)) {
          this.onCloseDropdown2();
        }
      }
    },
    onCloseDropdown2() {
      this.close2=true;
    },
    onChangeStatus2(value) {
      if (value) {
        this.close2 = false;
      }
    },
    handleClickOutside3(event) {
      // Check that the reference exists before accessing it
      if (this.$refs.dropdownComponent3 && this.$refs.dropdownComponent3.$refs.dropdownElement) {
        const dropdownElement = this.$refs.dropdownComponent3.$refs.dropdownElement;
        if (!dropdownElement.contains(event.target)) {
          this.onCloseDropdown3();
        }
      }
    },
    onCloseDropdown3() {
      this.close3=true;
    },
    onChangeStatus3(value) {
      if (value) {
        this.close3 = false;
      }
    },
    handleClickOutside4(event) {
      // Check that the reference exists before accessing it
      if (this.$refs.dropdownComponent4 && this.$refs.dropdownComponent4.$refs.dropdownElement) {
        const dropdownElement = this.$refs.dropdownComponent4.$refs.dropdownElement;
        if (!dropdownElement.contains(event.target)) {
          this.onCloseDropdown4();
        }
      }
    },
    onCloseDropdown4() {
      this.close4=true;
    },
    onChangeStatus4(value) {
      if (value) {
        this.close4 = false;
      }
    },
    handleClickOutside5(event) {
      // Check that the reference exists before accessing it
      if (this.$refs.myMultiselect5) {
        // Access the underlying DOM element
        const dropdownElement = this.$refs.myMultiselect5.$el;
        if (!dropdownElement.contains(event.target)) {
          this.onCloseDropdown5();
        }
      }
    },
    onCloseDropdown5() {
      // Access the multiselect component using its ref and call deactivate method
      this.$refs.myMultiselect5.deactivate();
    },
    handleOpen() {
      bus.$emit('multiselect-open', 'myMultiselect5');
      this.$emit('multiOpen');
      bus.$emit('multi-open', this.index);
      this.$emit('multiSelectOpen', this.index);
      this.onCloseDropdown0();
      this.onCloseDropdown1();
      this.onCloseDropdown2() ;
      this.onCloseDropdown4() ;
    },
    closeMultiselect() {
      if (this.$refs.myMultiselect5) {
        this.$refs.myMultiselect5.deactivate();
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.filter {
  background: var(--color-background);
  padding: var(--space-small);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-medium);
}

.filter.error {
  background: var(--r-50);
}

.filter-inputs {
  display: flex;
}

.filter-error {
  color: var(--r-500);
  display: block;
  margin: var(--space-smaller) 0;
}

.filter__question,
.filter__operator {
  margin-bottom: var(--space-zero);
  margin-right: var(--space-smaller);
}

.filter__question {
  max-width: 30%;
}

.filter__operator {
  max-width: 20%;
}

.filter__answer--wrap {
  margin-right: var(--space-smaller);
  flex-grow: 1;

  input {
    margin-bottom: 0;
  }
}
.filter__answer {
  &.answer--text-input {
    margin-bottom: var(--space-zero);
  }
}

.filter__join-operator-wrap {
  position: relative;
  z-index: var(--z-index-twenty);
  margin: var(--space-zero);
}

.filter__join-operator {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: var(--space-one) var(--space-zero);

  .operator__line {
    position: absolute;
    width: 100%;
    border-bottom: 1px solid var(--color-border);
  }

  .operator__select {
    position: relative;
    width: auto;
    margin-bottom: var(--space-zero) !important;
  }
}

.multiselect {
  margin-bottom: var(--space-zero);
}
::v-deep {
  .multiselect__placeholder {
    font-family: "Inter" !important;
    font-style: normal !important;
    font-weight: 500 !important;
    color: #1f2d3d !important;
    text-rendering: optimizeLegibility !important;
  }
}
</style>
