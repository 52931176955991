<template>
  <div class="ongoingOps row" style="margin: 10px 12px">
    <span v-if="count" class="product-family"> {{ name }} </span>
    <span v-else class="product-family-unactive"> {{ name }} </span>
    <div style="display: flex;align-items: center;">
      <span
        v-if="!count"
        class="product-family-unactive"
        style="margin-right: 1rem;"
      >
        0{{ count }}
      </span>
      <span
        v-else-if="count <10"
        class="product-family"
        style="margin-right: 1rem;"
      >
        0{{ count }}
      </span>
      <span
        v-else
        class="product-family"
        style="margin-right: 1rem;"
      >
        {{ count }}
      </span>
      <svg
        v-if="!count"
        width="1"
        height="24"
        viewBox="0 0 1 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="0.5"
          y1="0.5"
          x2="0.499999"
          y2="22.8333"
          stroke="#e1e1e1"
          stroke-linecap="round"
        />
      </svg>
      <svg
        v-else
        width="1"
        height="24"
        viewBox="0 0 1 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="0.5"
          y1="0.5"
          x2="0.499999"
          y2="22.8333"
          stroke="#A8A8A8"
          stroke-linecap="round"
        />
      </svg>
      <div v-if="count" class="check-icon">
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.99967 15.1663C4.04634 15.1663 0.833008 11.953 0.833008 7.99967C0.833008 4.04634 4.04634 0.833008 7.99967 0.833008C11.953 0.833008 15.1663 4.04634 15.1663 7.99967C15.1663 11.953 11.953 15.1663 7.99967 15.1663ZM7.99967 1.83301C4.59967 1.83301 1.83301 4.59967 1.83301 7.99967C1.83301 11.3997 4.59967 14.1663 7.99967 14.1663C11.3997 14.1663 14.1663 11.3997 14.1663 7.99967C14.1663 4.59967 11.3997 1.83301 7.99967 1.83301Z"
            fill="#49CF48"
          />
          <path
            d="M7.05297 10.3867C6.91964 10.3867 6.79297 10.3334 6.69964 10.2401L4.81297 8.3534C4.61964 8.16007 4.61964 7.84007 4.81297 7.64673C5.0063 7.4534 5.3263 7.4534 5.51964 7.64673L7.05297 9.18007L10.4796 5.7534C10.673 5.56007 10.993 5.56007 11.1863 5.7534C11.3796 5.94673 11.3796 6.26673 11.1863 6.46006L7.4063 10.2401C7.31297 10.3334 7.1863 10.3867 7.05297 10.3867Z"
            fill="#49CF48"
          />
        </svg>
      </div>
      <div v-else class="uncheck-icon">
        <svg width="19" height="19" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.833008 7.99967C0.833008 4.05687 4.05687 0.833008 7.99967 0.833008C11.9425 0.833008 15.1663 4.05687 15.1663 7.99967C15.1663 11.9425 11.9425 15.1663 7.99967 15.1663C4.05687 15.1663 0.833008 11.9425 0.833008 7.99967ZM7.99967 1.83301C4.60915 1.83301 1.83301 4.60915 1.83301 7.99967C1.83301 11.3902 4.60915 14.1663 7.99967 14.1663C11.3902 14.1663 14.1663 11.3902 14.1663 7.99967C14.1663 4.60915 11.3902 1.83301 7.99967 1.83301Z" fill="#FDAEBD"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2402 5.75973C10.4354 5.95499 10.4354 6.27157 10.2402 6.46683L6.46683 10.2402C6.27157 10.4354 5.95499 10.4354 5.75973 10.2402C5.56447 10.0449 5.56447 9.72832 5.75973 9.53306L9.53306 5.75973C9.72832 5.56447 10.0449 5.56447 10.2402 5.75973Z" fill="#FDAEBD"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5.75973 5.75973C5.95499 5.56447 6.27157 5.56447 6.46683 5.75973L10.2402 9.53306C10.4354 9.72832 10.4354 10.0449 10.2402 10.2402C10.0449 10.4354 9.72832 10.4354 9.53306 10.2402L5.75973 6.46683C5.56447 6.27157 5.56447 5.95499 5.75973 5.75973Z" fill="#FDAEBD"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AssuranceOngoingOperations',

  props: {
    name: {
      type: String,
      default: '',
    },
    itemKey: {
      type: Number,
      default: 0,
    },
    count: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style scoped lang="scss">
.ongoingOps {
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 2px 16px;
  margin: 10px 12px;
  align-items: center;

  .product-family {
    color: #737373;
    font-weight: 500;
  }

  .product-family-unactive {
    font-weight: 500;
    color: #e1e1e1;
  }

  .check-icon, .uncheck-icon {
    margin-left: 1rem;
    align-items: center;
    display: flex;
  }
}

.mt-2px {
  margin-top: 2px;
}

.typoNumbers {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  margin-top: 3px;
  margin-left: 5px;
}

.greenNumber {
  color: #49cf48;
}

.redNumber {
  color: #fa3559;
}

.orangeNumber {
  color: #f8ab26;
}

.primaryNumber {
  color: #483a9d;
}
</style>
