<template>
  <div class="wizard-body columns content-box small-9">
    <form class="row" @submit.prevent="addAgents()">
      <div class="medium-12 columns">
        <page-header
          :header-title="$t('INBOX_MGMT.ADD.AGENTS.TITLE')"
          :header-content="$t('INBOX_MGMT.ADD.AGENTS.DESC')"
        />
          <div>
              <span class="ps_txt">Here you can add agents to manage your newly created inbox. Only these selected agents will have access to your inbox. Agents which are not part of this inbox will not be able to see or respond to messages in this inbox when they login.</span>
              <div class="d-flex ps_txt ps_section">
                  <svg width="26" height="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18.0199 12.33L16.7999 11.11C16.5099 10.86 16.3399 10.49 16.3299 10.08C16.3099 9.63 16.4899 9.18 16.8199 8.85L18.0199 7.65C19.0599 6.61 19.4499 5.61 19.1199 4.82C18.7999 4.04 17.8099 3.61 16.3499 3.61H5.8999V2.75C5.8999 2.34 5.5599 2 5.1499 2C4.7399 2 4.3999 2.34 4.3999 2.75V21.25C4.3999 21.66 4.7399 22 5.1499 22C5.5599 22 5.8999 21.66 5.8999 21.25V16.37H16.3499C17.7899 16.37 18.7599 15.93 19.0899 15.14C19.4199 14.35 19.0399 13.36 18.0199 12.33Z" fill="#FF0000"/>
                  </svg>
                  PS: As an administrator, if you need access to all inboxes, you should add yourself as agent to all inboxes that you create.
              </div>

          </div>
      </div>
      <div class="medium-7 columns">
        <div class="medium-12 columns">
            <multiselect
                    class="multi_select"
                    v-model="selectedAgents"
                    :options="confirmedListAgents"
                    track-by="id"
                    label="name"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :hide-selected="true"
                    selected-label
                    :placeholder="$t('INBOX_MGMT.ADD.AGENTS.PICK_AGENTS')"
                    @select="$v.selectedAgents.$touch"
            />
            <span v-if="$v.selectedAgents.$error" class="message text-danger">
              {{ $t('INBOX_MGMT.ADD.AGENTS.VALIDATION_ERROR') }}
            </span>
        </div>
        <div class="medium-12 columns inbox_button">
          <woot-submit-button
            :button-text="$t('INBOX_MGMT.AGENTS.BUTTON_TEXT')"
            :loading="isCreating"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
/* eslint no-console: 0 */
import { mapGetters } from 'vuex';

import InboxMembersAPI from '../../../../api/inboxMembers';
import router from '../../../index';
import PageHeader from '../SettingsSubPageHeader';

export default {
  components: {
    PageHeader,
  },

  validations: {
    selectedAgents: {
      isEmpty() {
        return !!this.selectedAgents.length;
      },
    },
  },

  data() {
    return {
      selectedAgents: [],
      isCreating: false,
    };
  },

  computed: {
    ...mapGetters({
      agentList: 'agents/getAgents',
    }),
    /* return confirmed agent list */
    confirmedListAgents() {
       const confirmedAgents = this.agentList.filter(agent => agent.confirmed === true);
       return confirmedAgents;
    },
  },

  mounted() {
    this.$store.dispatch('agents/get');
  },

  methods: {
    async addAgents() {
      this.isCreating = true;
      const inboxId = this.$route.params.inbox_id;
      const selectedAgents = this.selectedAgents.map(x => x.id);

      try {
        await InboxMembersAPI.update({ inboxId, agentList: selectedAgents });
        router.replace({
          name: 'settings_inbox_finish',
          params: {
            page: 'new',
            inbox_id: this.$route.params.inbox_id,
          },
        });
      } catch (error) {
        bus.$emit('newToastMessage', error.message);
      }
      this.isCreating = false;
    },
  },
};
</script>
<style scoped lang="scss">

  .ps_txt {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    color: #737373;
  }

  .inbox_button{
    position: absolute !important;
    right: 4rem !important;
    bottom: 3rem !important;
  }

  .ps_section{
    margin: 3rem auto!important;
    width: 70%;
    svg{
      margin-right: 6px;
      margin-top: -4px;
    }
  }

  .multi_select{
    margin-left: 20.5rem;
  }

</style>
