<template>
  <div class="custom-attribute--selector">
    <div
      v-on-clickaway="closeDropdown"
      class="label-wrap"
      @keyup.esc="closeDropdown"
    >
            <woot-button
                    variant="smooth"
                    class="label--add"
                    icon="add"
                    size="tiny"
                    @click="toggleAttributeDropDown"
            >
                {{ $t('CONTACT_PANEL.ATTRIBUTES.CONVERSATION.ADD_BUTTON') }}
            </woot-button>
    <div class="dropdown-wrap">
        <div
          :class="{ 'dropdown-pane--open': showAttributeDropDown }"
          class="dropdown-pane"
        >
          <custom-attribute-drop-down
            v-if="showAttributeDropDown"
            :attribute-type="attributeType"
            :contact-id="contactId"
            @add-attribute="addAttribute"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomAttributeDropDown from './CustomAttributeDropDown.vue';
import alertMixin from 'shared/mixins/alertMixin';
import attributeMixin from 'dashboard/mixins/attributeMixin';
import { mixin as clickaway } from 'vue-clickaway';
import { BUS_EVENTS } from 'shared/constants/busEvents';
import format from 'date-fns/format';
const DATE_FORMAT = 'yyyy-MM-dd';

export default {
  components: {
    CustomAttributeDropDown,
  },
  mixins: [clickaway, alertMixin, attributeMixin],
  props: {
    attributeType: {
      type: String,
      default: 'conversation_attribute',
    },
    contactId: { type: Number, default: null },
  },
  data() {
    return {
      showAttributeDropDown: false,
    };
  },
  methods: {
    async addAttribute(attribute) {
      try {
        const { attribute_key } = attribute;
        if (this.attributeType === 'conversation_attribute') {
          await this.$store.dispatch('updateCustomAttributes', {
            conversationId: this.conversationId,
            customAttributes: {
              ...this.customAttributes,
              [attribute_key]: null,
            },
          });
        } else {
          /* Add a default value for the added attribute */
          const updatedAttributes = { ...this.customAttributes,
                                      [attribute.attribute_key]: this.defaultValue(attribute.attribute_display_type,
                                                                                   attribute.attribute_key,
                                                                                   attribute.attribute_values) };
          await this.$store.dispatch('contacts/update', {
            id: this.contactId,
            custom_attributes: updatedAttributes,
              /*[attribute_key]: this.defaultValue(attribute_display_type),*/
          });
        }
        bus.$emit(BUS_EVENTS.FOCUS_CUSTOM_ATTRIBUTE, attribute.attribute_key);
        this.showAlert(this.$t('CUSTOM_ATTRIBUTES.FORM.ADD.SUCCESS'));
      } catch (error) {
        const errorMessage =
          error?.response?.message ||
          this.$t('CUSTOM_ATTRIBUTES.FORM.ADD.ERROR');
        this.showAlert(errorMessage);
      } finally {
        this.closeDropdown();
      }
    },
    /* assign initial values to the attribute according to its type */
    defaultValue(attributeType, attributeKey, attributeValue) {
          switch (attributeType) {
              case 'number' :
                  return '0';
              case 'text' :
                  return attributeKey; /* set the attribute's name */
              case 'link' :
                  return 'https://';
              case 'date' :
                  return format(new Date(), DATE_FORMAT); /* set today's date */
              case 'checkbox' :
                  return false;
              case 'list' :
                  return attributeValue[0]; /* set the first item's list */
          }
    },
    toggleAttributeDropDown() {
      this.showAttributeDropDown = !this.showAttributeDropDown;
    },

    closeDropdown() {
      this.showAttributeDropDown = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-attribute--selector {
  width: 100%;
  padding: var(--space-slab) var(--space-normal);
  margin-top: -6px !important;

  .label-wrap {
    line-height: var(--space-medium);
    position: relative;

    .dropdown-wrap {
      display: flex;
      left: -1px;
      margin-right: var(--space-medium);
      position: absolute;
      top: var(--space-medium);
      width: 100%;

      .dropdown-pane {
        //width: 100% !important;
        width: fit-content !important;
        box-sizing: border-box;
        margin-top: 4px;
      }
    }
  }
}

.add_attributes_icon{
  float: right !important;
}

.error {
  color: var(--r-500);
  font-size: var(--font-size-mini);
  font-weight: var(--font-weight-medium);
}
.label--add {
  margin-bottom: var(--space-micro);
  margin-right: var(--space-micro);
  background: #edecf6!important;
  border: 1px dashed #483a9d!important;
  border-radius: 8px!important;
  height: 25px!important;
  width: fit-content !important;
  font-family: Inter;
  font-style: normal!important;
  font-weight: 500!important;
  font-size: 10px!important;
  line-height: 15px!important;
}
</style>
