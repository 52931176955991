var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('woot-dropdown-menu',{style:({'flex-direction':'unset' , 'margin-top' : '7px' , 'margin-bottom' : '7px'})},[_vm._l((_vm.availabilityStatuses),function(status){return _c('woot-dropdown-item',{key:status.value,staticClass:"status-items mr-2",class:{
          'bg-busy': status.value === 'busy',
          'bg-offline': status.value === 'offline',
          'bg-online': status.value === 'online'
                    }},[_c('woot-button',{class:{
          'color-busy': status.value === 'busy',
          'color-offline': status.value === 'offline',
          'color-online': status.value === 'online'
                    },attrs:{"size":"small","color-scheme":status.disabled ? '' : 'secondary',"variant":status.disabled &&  status.value === 'busy' ? 'smooth-busy' : 'clear' &&
          status.disabled &&  status.value === 'offline' ? 'smooth-offline' : 'clear' &&
          status.disabled &&  status.value === 'online' ? 'smooth-online' : 'clear',"class-names":"status-change--dropdown-button"},on:{"click":function($event){return _vm.changeAvailabilityStatus(status.value)}}},[_c('availability-status-badge',{attrs:{"status":status.value}}),_vm._v("\n        "+_vm._s(status.label)+"\n      ")],1)],1)}),_vm._v(" "),_c('woot-dropdown-divider')],2)}
var staticRenderFns = []

export { render, staticRenderFns }