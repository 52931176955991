<template>
  <div class="column">
    <woot-modal-header :header-title="$t('AUTOMATION.EDIT.TITLE')" />
    <div class="row modal-content">
      <div v-if="automation" class="medium-12 columns">
        <div>
            <label :class="{ error: $v.automation.name.$error }"
                   class="w-100"
            >
                <input
                  v-model="automation.name"
                  :label="$t('AUTOMATION.ADD.FORM.NAME.LABEL')"
                  type="text"
                  :class="{ error: $v.automation.name.$error }"
                  :error="
                    $v.automation.name.$error
                      ? $t('AUTOMATION.ADD.FORM.NAME.ERROR')
                      : ''
                  "
                  :placeholder="$t('AUTOMATION.ADD.FORM.NAME.PLACEHOLDER')"
                  @input="$v.automation.name.$touch"
                  maxlength="50"
                />
                <span v-if="$v.automation.name.$error" class="message">
                  {{ $t('AUTOMATION.ADD.FORM.NAME.ERROR') }}
                </span>
            </label>
        </div>
        <div>
            <label class="w-100" :class="{ error: $v.automation.description.$error }">
                <input
                  v-model="automation.description"
                  :label="$t('AUTOMATION.ADD.FORM.DESC.LABEL')"
                  type="text"
                  :class="{ error: $v.automation.description.$error }"
                  :error="
                    $v.automation.description.$error
                      ? $t('AUTOMATION.ADD.FORM.DESC.ERROR')
                      : ''
                  "
                  :placeholder="$t('AUTOMATION.ADD.FORM.DESC.PLACEHOLDER')"
                  @input="$v.automation.description.$touch"
                  maxlength="200"
                />
                <span v-if="$v.automation.description.$error" class="message">
                  {{ $t('AUTOMATION.ADD.FORM.DESC.ERROR') }}
                </span>
            </label>
        </div>
        <div class="event_wrapper">
          <label>
            <multiselect-dropdown
              ref="dropdownComponent"
              :options="automationRuleEvents"
              :selected-item="automation.event_name"
              :has-thumbnail="false"
              :event-name="true"
              :close="closeDropdown"
              @click="onEventSelect"
              @close="onChangeStatus"
            />
<!--            {{ $t('AUTOMATION.ADD.FORM.EVENT.LABEL') }}
            <select v-model="automation.event_name" @change="onEventChange()">
              <option
                v-for="event in automationRuleEvents"
                :key="event.key"
                :value="event.key"
              >
                {{ event.value }}
              </option>
            </select>-->
<!--            <span v-if="$v.automation.event_name.$error" class="message">
              {{ $t('AUTOMATION.ADD.FORM.EVENT.ERROR') }}
            </span>-->
          </label>
        </div>
        <!-- // Conditions Start -->
        <section>
          <label>
            {{ $t('AUTOMATION.ADD.FORM.CONDITIONS.LABEL') }}
          </label>
          <div class="medium-12 columns filters-wrap">
            <filter-input-box
              v-for="(condition, i) in automation.conditions"
              :key="i"
              :index="i"
              v-model="automation.conditions[i]"
              :filter-attributes="getAttributes(automation.event_name.key)"
              :input-type="getInputType(automation.conditions[i].attribute_key)"
              :operators="getOperators(automation.conditions[i].attribute_key)"
              :dropdown-values="
                getConditionDropdownValues(
                  automation.conditions[i].attribute_key, language
                )
              "
              :custom-attribute-type="
                getCustomAttributeType(automation.conditions[i].attribute_key)
              "
              :show-query-operator="i !== automation.conditions.length - 1"
              :v="$v.automation.conditions.$each[i]"
              @resetFilter="resetFilter(i, automation.conditions[i])"
              @removeFilter="removeFilter(i)"
              @multiOpen="onCloseDropdown"
              @multiSelectOpen="onMultiOpen(i)"
            />
            <div class="filter-actions">
              <woot-button
                icon="add"
                color-scheme="primary"
                variant="smooth"
                size="small"
                @click="appendNewCondition"
              >
                {{ $t('AUTOMATION.ADD.CONDITION_BUTTON_LABEL') }}
              </woot-button>
            </div>
          </div>
        </section>
        <!-- // Conditions End -->
        <!-- // Actions Start -->
        <section>
          <label>
            {{ $t('AUTOMATION.ADD.FORM.ACTIONS.LABEL') }}
          </label>
          <div class="medium-12 columns filters-wrap">
            <automation-action-input
              v-for="(action, i) in automation.actions"
              :key="i"
              :index="i"
              v-model="automation.actions[i]"
              :action-types="automationActionTypes"
              :dropdown-values="getActionDropdownValues(action.action_name)"
              :show-action-input="showActionInput(action.action_name)"
              :v="$v.automation.actions.$each[i]"
              :initial-file-name="getFileName(action, automation.files)"
              @resetAction="resetAction(i)"
              @removeAction="removeAction(i)"
              @multiOpen="onCloseDropdown"
              @multiSelectOpen="onMultiOpen(i)"
            />
            <div class="filter-actions">
              <woot-button
                icon="add"
                color-scheme="primary"
                variant="smooth"
                size="small"
                @click="appendNewAction"
              >
                {{ $t('AUTOMATION.ADD.ACTION_BUTTON_LABEL') }}
              </woot-button>
            </div>
          </div>
        </section>
        <!-- // Actions End -->
        <div class="medium-12 columns">
          <div class="modal-footer justify-content-end w-full" style="margin-bottom: -3rem;">
            <woot-button
              class="button"
              variant="clear"
              @click.prevent="onClose"
            >
              {{ $t('AUTOMATION.EDIT.CANCEL_BUTTON_TEXT') }}
            </woot-button>
            <woot-button @click="submitAutomation" :disabled="$v.$invalid">
              {{ $t('AUTOMATION.EDIT.SUBMIT') }}
            </woot-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import automationMethodsMixin from 'dashboard/mixins/automations/methodsMixin';
import automationValidationsMixin from 'dashboard/mixins/automations/validationsMixin';
import filterInputBox from 'dashboard/components/widgets/FilterInput/Index.vue';
import automationActionInput from 'dashboard/components/widgets/AutomationActionInput.vue';
import MultiselectDropdown from 'shared/components/ui/MultiselectDropdown.vue';
import {
  AUTOMATION_RULE_EVENTS,
  AUTOMATION_ACTION_TYPES,
  AUTOMATIONS,
  updateOptionForAutomationAction,
  updateOptionForAutomationEvent,
} from './constants';

export default {
  components: {
    filterInputBox,
    automationActionInput,
    MultiselectDropdown,
  },
  mixins: [alertMixin, automationMethodsMixin, automationValidationsMixin],
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
    selectedResponse: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      automationTypes: JSON.parse(JSON.stringify(AUTOMATIONS)),
      automationRuleEvent: AUTOMATION_RULE_EVENTS[0].key,
      automationRuleEvents: AUTOMATION_RULE_EVENTS,
      automationActionTypes: AUTOMATION_ACTION_TYPES,
      automationMutated: false,
      show: true,
      automation: null,
      showDeleteConfirmationModal: false,
      allCustomAttributes: [],
      mode: 'edit',
      close: false,
    };
  },
  computed: {
    hasAutomationMutated() {
      if (
        this.automation.conditions[0].values ||
        this.automation.actions[0].action_params.length
      )
        return true;
      return false;
    },
    language() {
      return this.$root.$i18n.locale  ;
    },
    closeDropdown() {
      return this.close;
    }
  },
  mounted() {
    this.manifestCustomAttributes();
    this.allCustomAttributes = this.$store.getters['attributes/getAttributes'];
    this.formatAutomation(this.selectedResponse);
    updateOptionForAutomationAction(this.$root.$i18n.locale);
    updateOptionForAutomationEvent(this.$root.$i18n.locale);
    document.addEventListener('mousedown', this.handleClickOutside);
  },
  methods: {
    onEventSelect(eventName) {
      this.automation.event_name = eventName;
      this.onEventChange();
    },
    handleClickOutside(event) {
      // Check that the reference exists before accessing it
      if (this.$refs.dropdownComponent && this.$refs.dropdownComponent.$refs.dropdownElement) {
        const dropdownElement = this.$refs.dropdownComponent.$refs.dropdownElement;
        if (!dropdownElement.contains(event.target)) {
          this.onCloseDropdown();
        }
      }
    },
    onCloseDropdown() {
      this.close=true;
    },
    onChangeStatus(value) {
      if (value) {
        this.close = false;
      }
    },
    onMultiOpen(openIndex) {
      // Close all multiselects except the one with the index open
      bus.$emit('multi-open', openIndex);
    },
  }
};
</script>
<style lang="scss" scoped>
.filters-wrap {
  padding: var(--space-normal);
  border-radius: var(--border-radius-large);
  border: 1px solid var(--color-border);
  background: var(--color-background-light);
  margin-bottom: var(--space-normal);
}

.filter-actions {
  margin-top: var(--space-normal);
}
.event_wrapper {
  label {
    width: 100%;
  }
  select {
    margin: var(--space-zero);
  }
  .info-message {
    font-size: var(--font-size-mini);
    color: var(--s-500);
    text-align: right;
  }
  margin-bottom: var(--space-medium);
}
</style>
