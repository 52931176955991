<template>
  <div
        class="small-12 medium-4 bg-white contact--panel"
    >
        <div class="gen_info pb-3">
            <div class="conversation_act">
                <transition-group>
                    <div
                            v-for="element in contactSidebarItems"
                            :key="element.name"
                            class="list-group-item"
                    >
                        <div v-if="element.name === 'contact_labels'">
                            <accordion-item
                                    :title="$t('CONTACT_PANEL.SIDEBAR_SECTIONS.CONTACT_LABELS')"
                                    :is-open="isContactSidebarItemOpen('is_ct_labels_open')"
                                    @click="value => toggleSidebarUIState('is_ct_labels_open', value)"
                            >
                                <contact-labels :contact-id="contact.id" class="contact-labels" />
                            </accordion-item>
                        </div>
                        <div v-if="element.name === 'contact_attributes'">
                            <accordion-item
                                    :title="$t('CONVERSATION_SIDEBAR.ACCORDION.CONTACT_ATTRIBUTES')"
                                    :is-open="isContactSidebarItemOpen('is_ct_custom_attr_open')"
                                    compact
                                    @click="
                                    value => toggleSidebarUIState('is_ct_custom_attr_open', value)
                                  "
                            >
                                <custom-attributes
                                        :contact-id="contact.id"
                                        attribute-type="contact_attribute"
                                        attribute-class="conversation--attribute"
                                        :custom-attributes="contact.custom_attributes"
                                        class="even"
                                />
                                <custom-attribute-selector
                                        attribute-type="contact_attribute"
                                        :contact-id="contact.id"
                                />
                            </accordion-item>
                        </div>
                        <div v-if="element.name === 'previous_conversation'">
                          <accordion-item
                                :title="
                            $t('CONTACT_PANEL.SIDEBAR_SECTIONS.PREVIOUS_CONVERSATIONS')
                          "
                                :is-open="isContactSidebarItemOpen('is_ct_prev_conv_open')"
                                @click="
                            value => toggleSidebarUIState('is_ct_prev_conv_open', value)
                          "
                              >
                            <contact-conversations
                              v-if="contact.id"
                              :contact-id="contact.id"
                              conversation-id=""
                            />
                          </accordion-item>
                        </div>
                        <div v-if="element.name === 'cin_history'">
                          <accordion-item
                            :title="
                              $t('CONTACT_PANEL.SIDEBAR_SECTIONS.CIN_HISTORY')
                            "
                            :is-open="isContactSidebarItemOpen('is_ct_history_cin_open')"
                            @click="
                              value => toggleSidebarUIState('is_ct_history_cin_open', value)
                            "
                          >
                            <history-cin
                              v-if="contact.id"
                              :contact-id="contact.id"
                              conversation-id=""
                            />
                          </accordion-item>
                        </div>
                    </div>
                </transition-group>
            </div>
        </div>

  </div>
</template>

<script>
import draggable from 'vuedraggable';
import ContactInfo from "../../../../routes/dashboard/conversation/contact/ContactInfo";
import ContactConversations from '../../conversation/ContactConversations.vue'
import CustomAttributes from "../../../../routes/dashboard/conversation/customAttributes/CustomAttributes";
import CustomAttributeSelector from "../../../../routes/dashboard/conversation/customAttributes/CustomAttributeSelector";
import AccordionItem from "../../../../components/Accordion/AccordionItem";
import alertMixin from "../../../../../shared/mixins/alertMixin";
import uiSettingsMixin from "../../../../../dashboard/mixins/uiSettings";
import ContactLabels from "../../../../routes/dashboard/contacts/components/ContactLabels.vue";
import HistoryCin from './HistoryCin.vue';
export default {
    components: {
        ContactLabels,
        ContactInfo,
        draggable,
        AccordionItem,
        ContactConversations,
        CustomAttributes,
        CustomAttributeSelector,
        HistoryCin,
    },
    mixins: [alertMixin, uiSettingsMixin],
    props : {
        contact: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            dragEnabled: true,
            contactSidebarItems: [],
            dragging: false,
        };
    },
    computed : {
        hasContactAttributes() {
            const { custom_attributes: customAttributes } = this.contact;
            return customAttributes && Object.keys(customAttributes).length;
        },
    },
    mounted() {
        this.contactSidebarItems = this.contactSidebarItemsOrder;
    },
    methods: {
        onDragEnd() {
            this.dragging = false;
            this.updateUISettings({
                contact_sidebar_items_order: this.contactSidebarItems,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.gen_info {
  //background: #f0eff7;
  border-radius: 8px;
  padding: 2px;
  margin: 10px;

  .header_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #483a9d;
    margin-bottom: 8px;
    margin-top: 8px;
    margin-left: 1.2rem;
  }

  .thumb_client {
    background-color: #483a9d;
    margin-top: -0.6rem;
    border-top-right-radius: 120px 130px;
    border-top-left-radius: 120px 130px;
    border-bottom-right-radius: 40px 40px;
    border-bottom-left-radius: 40px 40px;

    span {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 9px;
      line-height: 12px;
      color: #ffffff;
      margin-left: 4px;
    }
  }

  .general_infomation {
    .name {
      text-transform: capitalize;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 19px;
      color: #000000;
    }

    .gender {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 24px;
      color: #000000;
    }

    .age {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #000000;
      margin-left: 8px !important;
    }

    .email {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #000000;
    }

    .phonenumber {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #000000;
    }
  }

  .localisation_client {
    display: flex !important;
    margin-right: 6px;
    margin-top: -5px;
  }
}

.btn_grp {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.position_svg {
  margin-left: -10px;
  z-index: 1;
}

.conversation_act {
  //padding: 5px 14px !important;
  margin-bottom: 0.5rem !important;
  margin-top: 1rem !important;
}

.conversation_att {
  //padding: 5px 14px !important;
  //margin-bottom: 2.5rem !important;
  margin-bottom: .5rem!important;
}

.contact--panel {
  height: 100%;
  background: #FFFFFF;
  //border: 0.5px solid #483A9D;
  box-shadow: 0px 3px 12px rgb(0 0 0 / 4%);
  border-radius: 8px 8px 8px 8px;
  //border-left: 1px solid var(--color-border);
  font-size: var(--font-size-small);
  overflow-y: auto;
  overflow: auto;
  position: relative;

  i {
    margin-right: $space-smaller;
  }
}

.list-group {
  .list-group-item {
    background-color: var(--white);
  }
}

::v-deep {
  .contact--profile {
    padding-bottom: var(--space-slab);
    border-bottom: 1px solid var(--color-border);
  }

  .conversation--actions .multiselect-wrap--small {
    .multiselect {
      padding-left: var(--space-medium);
      box-sizing: border-box;
    }

    .multiselect__element {
      span {
        width: 100%;
      }
    }
  }
}

.close-button {
  position: absolute;
  right: $space-two;
  top: $space-slab;
  font-size: $font-size-default;
  color: $color-heading;
  z-index: 9989;
}

.conversation--labels {
  padding: $space-medium;

  .icon {
    margin-right: $space-micro;
    font-size: $font-size-micro;
    color: #fff;
  }

  .label {
    color: #fff;
    padding: 0.2rem;
  }
}

.contact--mute {
  color: $alert-color;
  display: block;
  text-align: left;
}

.contact--actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-info {
  margin-top: var(--space-two);
}

.accordion_title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  color: #656877;
  margin: 15px;
}

.credits_history {
  .title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #483a9d;
    margin-bottom: 8px;
    margin-top: 8px;
    margin-left: 1.2rem;
  }
}

.preferences_banking {
  .title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #483a9d;
    margin-bottom: 8px;
    margin-top: 8px;
    margin-left: 1.2rem;
  }
}

.ongoing_operations {
  .title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #483a9d;
    margin-bottom: 8px;
    margin-top: 8px;
    margin-left: 1.2rem;
  }
}
</style>
